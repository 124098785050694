import { forwardRef, useState } from 'react'
import { SiteConfiguration } from '@aether/models'
import { styled } from '@aether/styles'

import { Link, Media } from '@aether/components'

type Props = {
  heading: string
  stores: SiteConfiguration['newsletter']['stores']
  className?: string
}

const Root = styled('div', {
  display: 'flex',
  flexDirection: 'column-reverse',
  '@lg': {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
})

const Heading = styled('h3', {
  $aetherFont: 'heading04',
  '@xl': {
    $aetherFont: 'heading03',
  },
  display: 'block',
  margin: '0 0 $32 0',
})

const StoresList = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  margin: '$24 0 0 0',
  padding: '0 0 0 $12',
  borderLeft: '1px solid $black',
  justifyContent: 'space-between',
  '@lg': {
    margin: 0,
    padding: '0 $20 0 $20',
  },
  '@xl': {
    padding: '0 $36 0 $20',
  },
})

const MediaWrapper = styled('div', {
  display: 'flex',
  height: '456px',
  width: '100%',
  '@lg': {
    height: 'unset',
  },
  '@xl': {
    justifySelf: 'flex-end',
  },
})

const StyledLink = styled(Link, {
  margin: '0 0 $8 0',
  display: 'flex',
})

export const Stores = forwardRef<HTMLDivElement, Props>(
  ({ stores, heading, className }, ref) => {
    const [storeIndex, setStoreIndex] = useState(0)

    return (
      <Root ref={ref} className={className}>
        <StoresList>
          <Heading>{heading}</Heading>
          <ul>
            {stores.map(({ link: { link, label } }, index) => (
              <li
                key={`${label}-${index}`}
                onFocus={() => setStoreIndex(index)}
                onMouseEnter={() => setStoreIndex(index)}
              >
                <StyledLink
                  {...link}
                  key={label}
                  active={index === storeIndex}
                  appearance="primary"
                >
                  {label}
                </StyledLink>
              </li>
            ))}
          </ul>
        </StoresList>
        <MediaWrapper>
          <Media
            {...stores[storeIndex].image}
            layout="fill"
            sizes={{ lg: '50vw' }}
            withBackground
          />
        </MediaWrapper>
      </Root>
    )
  },
)
