import React, { useEffect, useState, FC } from 'react'

export const Clock: FC = () => {
  const [currentTime, setCurrentTime] = useState('00:00 PST')

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(() => {
        const date = new Date()
        return date.toLocaleString('en-US', {
          timeZone: 'America/Los_Angeles',
          hour: 'numeric',
          minute: 'numeric',
          hour12: false,
          timeZoneName: 'short',
        })
      })
    }, 1000)

    return () => clearInterval(interval)
  })

  return <span>{currentTime}</span>
}
