export const KlaviyoRatingStar = () => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      width="20"
      height="20"
    >
      <path
        width="20"
        height="20"
        fill="#a1a1a1"
        strokeWidth="2"
        stroke="unset"
        strokeLinejoin="round"
        d="M5.068 17.8643C4.91909 17.7487 4.82782 17.5928 4.7942 17.3968C4.76538 17.2008 4.7966 16.967 4.88787 16.6956L6.39377 12.0057L2.54616 9.11027C2.32039 8.94439 2.16428 8.77348 2.07782 8.59755C1.99135 8.42161 1.97694 8.24065 2.03459 8.05467C2.09223 7.8737 2.20511 7.73798 2.37323 7.6475C2.54136 7.55702 2.76472 7.51429 3.04332 7.51932L7.76277 7.54948L9.19661 2.83695C9.28308 2.56048 9.39116 2.35187 9.52085 2.21112C9.65535 2.07037 9.81386 2 9.9964 2C10.1837 2 10.3422 2.07037 10.4719 2.21112C10.6064 2.35187 10.7169 2.56048 10.8034 2.83695L12.2372 7.54948L16.9567 7.51932C17.2353 7.51429 17.4586 7.55702 17.6268 7.6475C17.7949 7.73798 17.9078 7.8737 17.9654 8.05467C18.0231 8.24065 18.0086 8.42161 17.9222 8.59755C17.8357 8.77348 17.6796 8.94439 17.4538 9.11027L13.6062 12.0057L15.1121 16.6956C15.2034 16.967 15.2322 17.2008 15.1986 17.3968C15.1698 17.5928 15.0809 17.7487 14.932 17.8643C14.7831 17.9849 14.615 18.0251 14.4276 17.9849C14.2403 17.9497 14.0361 17.8492 13.8152 17.6833L9.9964 14.7502L6.18481 17.6833C5.96385 17.8492 5.7597 17.9497 5.57237 17.9849C5.38503 18.0251 5.21691 17.9849 5.068 17.8643Z"
      ></path>
    </svg>
  )
}
