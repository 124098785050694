import { PageLink } from '@aether/models'
import { FC } from 'react'
import { Link, LinkComponentProps } from '../Link/Link'
import { Media } from '../Media/Media'
import { MediaProps } from '../Media/models/MediaProps'

type Props = {
  link?: PageLink
  media: MediaProps
  noIndex?: boolean
}

export const MediaLink: FC<Props> = ({ link, media, noIndex = true }) => {
  if (link?.href || link?.action) {
    const linkProps: LinkComponentProps = {
      // by default we set link to display block, can be overwritten by link props
      appearance: 'block',
      ...(noIndex ? { tabIndex: -1 } : {}),
      ...link,
    }

    return (
      <Link {...linkProps}>
        <Media {...media} withBackground />
      </Link>
    )
  }

  return <Media {...media} withBackground />
}
