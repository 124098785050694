import { useEffect, useState } from 'react'

interface UseMutationObserverOptions {
  target: HTMLElement | null
  callback: MutationCallback
  config?: MutationObserverInit
}

const defaultConfig: MutationObserverInit = {
  subtree: true,
  childList: true,
}

export const useMutationObserver = ({
  target,
  callback,
  config = defaultConfig,
}: UseMutationObserverOptions) => {
  const [observer, setObserver] = useState<MutationObserver | null>(null)

  useEffect(() => {
    const obs = new MutationObserver(callback)
    setObserver(obs)
  }, [config, setObserver])

  useEffect(() => {
    if (!observer || !target) return
    observer.observe(target, config)
    return () => {
      if (observer) {
        observer.disconnect()
      }
    }
  }, [observer, target, config])

  return { observer, target }
}
