import { createMediaProjection } from '../projections/createMediaProjection'
import { groq } from 'next-sanity'
import { LocaleId } from '@aether/configs'
import { coalesceLocaleField } from '../helpers/coalesceLocaleField'
import { createRichTextProjection } from '../projections/createRichTextProjection'

export const createSectionSingleImageProjection = (localeId: LocaleId) => {
  const MEDIA_PROJECTION = createMediaProjection(localeId)
  const RICH_TEXT_PROJECTION = createRichTextProjection(localeId)

  return groq`{
        _key,
        _type,
        image${MEDIA_PROJECTION},
        ${coalesceLocaleField(
          'imageCaption',
          localeId,
        )}[]${RICH_TEXT_PROJECTION},
        sectionSpaceSize,
        "shopifyIds": products[] -> {"id": store.gid, "type": "single-product"},
        "productsButtonAppearance": coalesce(productsButtonAppearance, "light")
      }
  `
}
