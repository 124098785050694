export const Play = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_b_5216_17361)">
        <circle cx="16" cy="16" r="16" fill="white" fillOpacity="0.1" />
        <circle cx="16" cy="16" r="15.5" stroke="white" />
      </g>
      <path
        d="M21.7755 15.6625L12.9298 10.0965C12.5413 9.85446 12 10.0965 12 10.504V21.496C12 21.9035 12.5349 22.1455 12.9298 21.9035L21.7755 16.4776C22.1003 16.2802 22.1003 15.8599 21.7755 15.6497V15.6625Z"
        fill="white"
      />
      <defs>
        <filter
          id="filter0_b_5216_17361"
          x="-4"
          y="-4"
          width="40"
          height="40"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="2" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_5216_17361"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_5216_17361"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  )
}
