import { HeaderTab } from '@aether/models'
import { styled } from '@aether/styles'
import { FC } from 'react'
import { Container, Link, Media } from '@aether/components'

const TabContent = styled(Container, {
  width: '100%',
})
const LinkGroups = styled('div', {
  display: 'grid',
  gap: '$24',
  gridTemplateColumns: 'repeat(5, 1fr) minmax(200px, 1fr)',
  '@xxl': {
    gridTemplateColumns: 'repeat(5, 256px) auto',
  },
})
const Col = styled('div', {
  display: 'flex',
  flexDirection: 'column',
})

const ColHeading = styled('h2', {
  borderTop: '1px solid black',
  padding: '$20 0',
  display: 'flex',
  flexDirection: 'column',
  $aetherFont: 'heading07',
  textTransform: 'uppercase',
})
const ColItems = styled('ol', {
  listStyle: 'none',
  display: 'grid',
  paddingBottom: '$64',
})
const ColItem = styled('li', {
  listItemStyle: 'none',
  minHeight: '$28',
  variants: {
    isHighlighted: {
      true: {
        color: '$red',
      },
    },
  },
})

const TabImageWrap = styled('div', {
  marginBottom: '$56',
  maxWidth: '420px',
})

const MediaWrap = styled('div', {
  display: 'flex',
  height: '300px',
})
const TabImageLabel = styled('div', {
  $aetherFont: 'ui03',
  paddingTop: '$16',
})

const ImageWrapper = styled('div', {
  display: 'flex',
  justifyContent: 'flex-end',
})

const StyledLink = styled(Link, {
  width: '100%',
  display: 'block',
  paddingTop: '$8',
  paddingBottom: '$8',
  $aetherFont: 'ui03',
  $focusStyle: 'default',
})

type CommonTabProps = {
  tab: HeaderTab
  isOpen: boolean
}

const TabImage = ({ tab }: { tab: HeaderTab }) => {
  if (!tab.image?.image) {
    return null
  }

  return (
    <TabImageWrap key={`${tab.title}-image`}>
      <MediaWrap>
        <Media
          {...tab.image.image}
          layout="responsive-cover"
          sizes={{ default: '220px', xl: '450px' }}
        />
      </MediaWrap>
      <TabImageLabel>{tab.image.label}</TabImageLabel>
    </TabImageWrap>
  )
}

export const CommonTab: FC<CommonTabProps> = ({ tab, isOpen }) => {
  return (
    <TabContent>
      <LinkGroups>
        {tab.linkGroups &&
          tab.linkGroups.map((linkGroup, linkGroupIndex) => {
            return (
              linkGroup.title && (
                <Col key={`${linkGroup.title} - ${linkGroupIndex}`}>
                  {linkGroup.groupLink && linkGroup.groupLink.href ? (
                    <ColHeading>
                      <Link {...linkGroup.groupLink} tabIndex={isOpen ? 0 : -1}>
                        {linkGroup.title}
                      </Link>
                    </ColHeading>
                  ) : (
                    <ColHeading>{linkGroup.title}</ColHeading>
                  )}
                  <ColItems>
                    {linkGroup.links?.map((link) => {
                      return (
                        <ColItem
                          key={`${
                            link.payload?.label || link.type
                          } - ${linkGroupIndex}`}
                          isHighlighted={link.payload?.isHighlighted}
                        >
                          <StyledLink
                            {...link.payload?.link}
                            tabIndex={isOpen ? 0 : -1}
                          >
                            {link?.payload?.label}
                          </StyledLink>
                        </ColItem>
                      )
                    })}
                  </ColItems>
                </Col>
              )
            )
          })}
        <ImageWrapper style={{ gridColumn: `${tab.linkGroups.length + 1}/-1` }}>
          {tab?.image?.link && (
            <Link {...tab.image.link} tabIndex={isOpen ? 0 : -1}>
              <TabImage tab={tab} />
            </Link>
          )}
          {!tab?.image?.link && <TabImage tab={tab} />}
        </ImageWrapper>
      </LinkGroups>
    </TabContent>
  )
}
