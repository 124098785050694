import { FC } from 'react'
import { ShopifyPriceRange } from '@aether/models'
import { PricePrimitive } from '../PricePrimitive/PricePrimitive'
import { styled } from '@aether/styles'

type PriceRangeProps = {
  range: ShopifyPriceRange
}

const StyledSpan = styled('span', {
  textTransform: 'lowercase',
})

export const PriceRange: FC<PriceRangeProps> = ({ range }) => {
  return range.maxVariantPrice.amount === range.minVariantPrice.amount ? (
    <PricePrimitive price={range.minVariantPrice} />
  ) : (
    <>
      <StyledSpan>from</StyledSpan>&nbsp;
      <PricePrimitive price={range.minVariantPrice} />
    </>
  )
}
