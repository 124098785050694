import { styled } from '@aether/styles'
import { ContainerSpaceType } from '@aether/models'
import { Container } from '../Container/Container'
import { FC, useEffect, useRef, useState } from 'react'
import { useInView } from 'react-intersection-observer'

const StyledContainer = styled(Container, {
  display: 'grid',
  gridTemplateRows: 'auto auto',
  gridTemplateColumns: '1fr',
})

const Placeholder = styled('div', {
  minHeight: '140px',
})

type Props = {
  embedCode?: string
  sectionSpaceSize?: ContainerSpaceType
}

export const KlavyioEmbeddedForm: FC<Props> = ({
  embedCode,
  sectionSpaceSize,
}) => {
  const { ref, inView } = useInView({
    rootMargin: `100% 0px 100% 0px`,
    threshold: 0,
    triggerOnce: true,
  })
  const refEmbedCodeContainer = useRef<HTMLDivElement>(null)
  const [isPlaceholderVisible, setIsPlaceholderVisible] = useState(true)

  useEffect(() => {
    if (inView) {
      const observer = new MutationObserver(() => {
        if (isPlaceholderVisible) {
          setIsPlaceholderVisible(false)
        }
      })

      if (refEmbedCodeContainer.current) {
        observer.observe(refEmbedCodeContainer.current, {
          childList: true,
          subtree: true,
        })
      }

      return () => {
        observer.disconnect()
      }
    }
  }, [inView, isPlaceholderVisible])

  return (
    <StyledContainer spaceSize={sectionSpaceSize} size="L">
      <div>
        <div ref={ref}>
          {isPlaceholderVisible && <Placeholder />}
          {inView && (
            <div
              ref={refEmbedCodeContainer}
              style={{
                visibility: !isPlaceholderVisible ? 'visible' : 'hidden',
              }}
              dangerouslySetInnerHTML={{ __html: embedCode || '' }}
            ></div>
          )}
        </div>
      </div>
    </StyledContainer>
  )
}
