import { ListItem } from './TextResult.styles'
import { CallbackTextResult } from './types'
import { Button } from '@aether/components'
import { styled } from '@aether/styles'
import { useTranslation } from 'react-i18next'

interface CallbackResultProps {
  result: CallbackTextResult
}

export const StyledButton = styled(Button, {
  $aetherFont: 'ui03',
})

export const CallbackResult = ({ result }: CallbackResultProps) => {
  const { t } = useTranslation('search')

  return (
    <ListItem id={result.label} key={result.label}>
      <StyledButton
        appearance="defaultLink"
        onClick={result.onClick}
        ariaLabel={t('suggestedPhraseLabel', { value: result.label }) ?? ''}
      >
        {result.label}
      </StyledButton>
    </ListItem>
  )
}
