import { styled } from '@aether/styles'
import { AnchorResult } from './AnchorResult'
import { CallbackResult } from './CallbackResults'
import { TextResult } from './types'

/* eslint-disable-next-line */
export interface TextResultsProps {
  title: string
  results: TextResult[]
}

const Header = styled('h2', {
  $aetherFont: 'heading07',
  textTransform: 'uppercase',
  letterSpacing: '0.1em',
  lineHeight: '1.33em',
  color: 'black',
  marginTop: 0,
  marginBottom: '1.08em',

  '@lg': {
    marginTop: '2.85em',
    marginBottom: '1.43em',
  },
})

const Wrapper = styled('div', {
  '@lg': {
    '&:first-of-type': {
      [`& ${Header}`]: {
        marginTop: 0,
      },
    },
  },
})

export function TextResults({ title, results }: TextResultsProps) {
  if (results.length === 0) {
    return null
  }

  return (
    <Wrapper>
      <Header>{title}</Header>
      <ul>
        {results.map((result) => {
          switch (result.type) {
            case 'callback':
              return <CallbackResult key={result.label} result={result} />
            case 'anchor':
              return <AnchorResult key={result.label} result={result} />
            default:
              return null
          }
        })}
      </ul>
    </Wrapper>
  )
}

export default TextResults
