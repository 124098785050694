import { createMediaProjection } from './../projections/createMediaProjection'
import { groq } from 'next-sanity'
import { LocaleId } from '@aether/configs'

export const createSectionTripleImageProjection = (localeId: LocaleId) => {
  const MEDIA_PROJECTION = createMediaProjection(localeId)
  return groq`{
        _key,
        _type,
        "spaceSize":sectionSpaceSize,
        images[]{...image${MEDIA_PROJECTION}}
      }
  `
}
