import { FC, PropsWithChildren } from 'react'
import { MediaRatiosType, ratios } from '../ratios'
import { styled } from '@aether/styles'

type MediaPlaceholderProps = {
  ratio: MediaRatiosType | 'none'
}

const Wrap = styled('div', {
  position: 'relative',
})
const InnerWrap = styled('div', {
  height: '100%',
  width: '100%',
  position: 'absolute',
  top: 0,
  left: 0,
})
export const MediaPlaceholder: FC<PropsWithChildren<MediaPlaceholderProps>> = ({
  ratio,
  children,
}) => {
  if (ratio === 'none') return <>{children}</>
  return (
    <Wrap style={{ paddingTop: `${ratios[ratio] * 100}%` }}>
      <InnerWrap>{children}</InnerWrap>
    </Wrap>
  )
}
