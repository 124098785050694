import { LocaleId } from '@aether/configs'
import groq from 'groq'
import { coalesceLocaleField } from '../helpers/coalesceLocaleField'
import { createLinkProjection } from '../projections/createLinkProjection'

export const createStickyCtaModuleProjection = (localeId: LocaleId) => {
  return groq`{
    _key,
    _type,
    ${coalesceLocaleField('title', localeId)},
    ${coalesceLocaleField('ctaText', localeId)},
    ctaLink${createLinkProjection(localeId)}
}`
}
