import { getLocaleRegionIdFromPath } from '@aether/utils'
import { createShopifyClient } from '../createShopifyClient'

import {
  ShopifyMutationCartLinesAddArgs,
  ShopifyMutationCartLinesUpdateArgs,
  ShopifyCountryCode,
  ShopifyResolvedCart,
  createShopifySdk,
  formatShopifyCart,
  ShopifyCartBuyerIdentityInput,
} from '@aether/shopify-sdk'

export type AddLinesInput = {
  merchandiseId: string
  quantity: number
  attributes?: { key: string; value: string }[]
}[]

export type UpdateLinesInput = {
  id: string
  quantity: number
}[]

type CartClientProps = {
  cartId: string | null
  locale?: string
}

type CartClientRes = Promise<ShopifyResolvedCart>

export type CartClient = {
  create: (email?: string) => CartClientRes
  fetch: (cartId: string) => CartClientRes
  updateBuyer: (buyerIdentity: ShopifyCartBuyerIdentityInput) => CartClientRes
  addLines: (lines: ShopifyMutationCartLinesAddArgs['lines']) => CartClientRes
  updateLines: (
    lines: ShopifyMutationCartLinesUpdateArgs['lines'],
  ) => CartClientRes
  removeLines: (lineIds: string[]) => CartClientRes
}
export const createCartClient = ({
  locale,
  cartId,
}: CartClientProps): CartClient => {
  const [countryCode, language] = getLocaleRegionIdFromPath(locale) as [
    ShopifyCountryCode,
    string,
  ]

  const client = createShopifyClient(language)
  const sdk = createShopifySdk(client)

  return {
    create: (email) =>
      sdk
        .cartCreate({ cartInput: { buyerIdentity: { countryCode, email } } })
        .then((res) => formatShopifyCart(res.data.cartCreate?.cart)),

    updateBuyer: (buyerIdentity) => {
      if (!cartId) {
        throw new Error('[createCartClient] addLines called without cartId')
      }
      return sdk
        .cartBuyerIdentityUpdate({
          cartId,
          buyerIdentity,
          countryCode,
        })
        .then((res) =>
          formatShopifyCart(res.data.cartBuyerIdentityUpdate?.cart),
        )
    },
    fetch: (specificCartId) =>
      sdk
        .getCart({
          cartId: specificCartId,
          countryCode,
        })
        .then((res) => formatShopifyCart(res.data.cart)),
    addLines: (lines) => {
      if (!cartId) {
        throw new Error('[createCartClient] addLines called without cartId')
      }
      return sdk
        .cartLinesAdd({ cartId, lines, countryCode })
        .then((res) => formatShopifyCart(res.data.cartLinesAdd?.cart))
    },
    updateLines: (lines) => {
      if (!cartId) {
        throw new Error('[createCartClient] updateLines called without cartId')
      }
      return sdk
        .cartLinesUpdate({ cartId, lines, countryCode })
        .then((res) => formatShopifyCart(res.data.cartLinesUpdate?.cart))
    },
    removeLines: (lineIds) => {
      if (!cartId) {
        throw new Error('[createCartClient] removeLines called without cartId')
      }
      return sdk
        .cartLinesRemove({ cartId, lineIds, countryCode })
        .then((res) => formatShopifyCart(res.data.cartLinesRemove?.cart))
    },
  }
}
