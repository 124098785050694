import { gql } from 'graphql-request'

export const PRODUCT_BASIC_FRAGMENT = gql`
  fragment ProductBasicFragment on Product {
    id
    title
    handle
    colors: metafield(key: "color", namespace: "product") {
      key
      value
    }
    vipProduct: metafield(key: "vip_product", namespace: "product") {
      key
      value
    }
    hideSwatch: metafield(key: "hideswatch", namespace: "product") {
      key
      value
    }
  }
`
