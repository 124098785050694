import { LocaleId } from '@aether/configs'
import { groq } from 'next-sanity'
import { coalesceLocaleField } from '../helpers/coalesceLocaleField'
import { createMediaProjection } from './createMediaProjection'

export const createScatteredGalleryItemProjection = (localeId: LocaleId) => {
  const MEDIA_PROJECTION = createMediaProjection(localeId)
  return groq`{
    "type": itemType,
    "payload":select(
      itemType == "image" => {
        "image": image${MEDIA_PROJECTION},
        itemSpaceSize,
        ${coalesceLocaleField('imageCaption', localeId)},
        horizontalPosition,
        "shopifyIds": products[] -> {"id": store.gid, "type": "single-product"},
        "productsButtonAppearance": coalesce(productsButtonAppearance, "light")
      },
      itemType == "text" =>{
        ${coalesceLocaleField('text', localeId)},
        itemSpaceSize,
        horizontalPosition,
        borderPosition
      },
      itemType == "spacer" =>{
        spacerSize
      }
    )
  }
  `
}
