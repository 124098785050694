import { DEFAULT_LOCALE_ID, LocaleId } from '@aether/configs'
import { groq } from 'next-sanity'
import { createLinkProjection } from './createLinkProjection'
import { createIconImageProjection } from './createIconImageProjection'

export const createRichTextProjection = (
  localeId: LocaleId,
  defaultLocaleId = DEFAULT_LOCALE_ID,
) => {
  const LINK_PROJECTION = createLinkProjection(localeId, defaultLocaleId)

  return groq`{
    ...,
    _type == "richTextImage" => {
        _key,
        _type,
        img${createIconImageProjection(localeId)},
        link${LINK_PROJECTION},
        size,
        position,
    },
    _type == "klavyioEmbeddedForm" => {
        _key,
        _type,
        embedCode,
        sectionSpaceSize
    },
    markDefs[]{
      ...,
      _type == "link" => {
        _key,
        _type,
        ...${LINK_PROJECTION},
      },
    }
  }`
}
