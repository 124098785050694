import { coalesceLocaleField } from '../helpers/coalesceLocaleField'
import { groq } from 'next-sanity'
import { LocaleId } from '@aether/configs'
import { createMediaProjection } from '../projections/createMediaProjection'
import { createLinkProjection } from '../projections/createLinkProjection'

export const createSectionDoubleCardsWithCaptionProjection = (
  localeId: LocaleId,
) => {
  const LINK_PROJECTION = createLinkProjection(localeId)
  const MEDIA_PROJECTION = createMediaProjection(localeId)
  return groq`{
        _key,
        _type,
        sectionColor,
        sectionSpaceSize,
        imagePosition,
        ${coalesceLocaleField('title', localeId)},
        ${coalesceLocaleField('text', localeId)},
        cards[] {
          ${coalesceLocaleField('label', localeId)},
          link${LINK_PROJECTION},
          image${MEDIA_PROJECTION},
      }
  }
  `
}
