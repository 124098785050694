import { ContainerSpaceType } from '@aether/models'
import { PortableTextTypeComponent } from '@portabletext/react'
import { KlavyioEmbeddedForm } from '../KlavyioEmbeddedForm/KlavyioEmbeddedForm'

type Props = {
  _type: string
  embedCode?: string
  sectionSpaceSize?: ContainerSpaceType
}

export const PortableKlavyioEmbeddedForm: PortableTextTypeComponent<Props> = ({
  value,
}) => {
  const { embedCode, sectionSpaceSize } = value || {}

  return (
    <KlavyioEmbeddedForm
      sectionSpaceSize={sectionSpaceSize}
      embedCode={embedCode}
    ></KlavyioEmbeddedForm>
  )
}
