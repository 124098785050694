import {
  createContext,
  ReactElement,
  useContext,
  useEffect,
  useState,
} from 'react'
import {
  Discount,
  DiscountsProviderProps,
  IDiscountsProvider,
  IDiscountsProviderProps,
} from './types'
import { findHighestDiscountPercentage, findTieredDiscounts } from './utils'
import { useRouter } from 'next/router'

export const DiscountsContext = createContext<IDiscountsProvider>({})

export const DiscountsProvider = ({
  allDiscounts,
  children,
}: IDiscountsProviderProps): ReactElement => {
  return (
    <DiscountsContext.Provider value={{ allDiscounts }}>
      {children}
    </DiscountsContext.Provider>
  )
}

export const useDiscounts = ({
  collections,
  userRole = 'customer',
  productId,
}: DiscountsProviderProps): IDiscountsProvider => {
  const router = useRouter()
  const path = router.asPath
  const context = useContext(DiscountsContext)
  const [matchedPercentageDiscount, setMatchedPercentageDiscount] =
    useState<Discount>()
  const [tieredDiscounts, setTieredDiscounts] = useState<Discount[]>()

  const isVipUser = userRole === 'vip'

  const getTieredDiscounts = (
    isVipUser: boolean,
  ):
    | {
        discounts: Discount[]
      }
    | undefined => {
    return findTieredDiscounts(context.allDiscounts, isVipUser)
  }
  const getApplicablePercentageDiscount = (isVipUser: boolean) => {
    if (!collections) return
    return findHighestDiscountPercentage(
      context.allDiscounts,
      collections,
      isVipUser,
      productId,
    )
  }

  useEffect(() => {
    setMatchedPercentageDiscount(getApplicablePercentageDiscount(isVipUser))
  }, [collections, context.allDiscounts, isVipUser, path])

  useEffect(() => {
    const tieredDiscounts = getTieredDiscounts(isVipUser)
    setTieredDiscounts(tieredDiscounts?.discounts)
  }, [context.allDiscounts, isVipUser, path])

  return {
    allDiscounts: context.allDiscounts,
    matchedDiscount: matchedPercentageDiscount,
    tieredDiscounts,
  }
}

export const useIsExcludedFromDiscount = ({
  collections,
  discounts,
}: {
  collections: string[]
  discounts?: Discount[]
}) => {
  if (!discounts) return false

  const excludedCollections = discounts.reduce<string[]>((acc, discount) => {
    if (!discount?.excludedCollections) return acc
    return [...acc, ...discount.excludedCollections]
  }, [])
  if (!excludedCollections.length) return false

  // Find if collections contains any of the excludedCollections
  const productExcludedCollections = collections.filter((collection) =>
    excludedCollections.includes(collection),
  )
  return productExcludedCollections?.length > 0
}
