import React from 'react'

export const CloseCircle = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="12" r="11.5" fill="white" stroke="black" />
      <path
        d="M12.4793 11.9966L17 16.5173L16.5173 17L11.9966 12.4793L7.48266 17L7 16.5173L11.5207 11.9966L7 7.48266L7.48266 7L11.9966 11.5207L16.5173 7L17 7.48266L12.4793 12.0034V11.9966Z"
        fill="black"
      />
    </svg>
  )
}
