import { extractTextContentFromPageBuilder } from '../pageBuilder/extractTextContentFromPageBuilder'
import { algoliaPageMock } from './algoliaPageMock'
import { SanityPageType } from './types'

export const mapSanityPageToAlgoliaPage = (
  sanityPage: SanityPageType,
): typeof algoliaPageMock => {
  return {
    title: sanityPage?.title.en,
    pageBuilder: extractTextContentFromPageBuilder(sanityPage.pageBuilder),
    type: 'page',
    path: sanityPage?.path?.current,
  }
}
