import { AnchorTextResult, TextResults } from '@aether/search/ui-text-results'
import { useHits } from 'react-instantsearch-hooks-web'
import { useEffect } from 'react'

interface PagesHitsProps {
  amountChangeHandler?: (value: number) => void
}

export const PagesHits = ({ amountChangeHandler }: PagesHitsProps) => {
  const { hits } = useHits()

  const results: AnchorTextResult[] = hits.map((hit) => ({
    type: 'anchor',
    label: hit.title as string,
    href: hit.path as string,
  }))

  useEffect(() => {
    if (amountChangeHandler) {
      amountChangeHandler(hits.length)
    }
  }, [amountChangeHandler, hits.length])

  return <TextResults title="Pages" results={results} />
}
