import { coalesceLocaleField } from '../helpers/coalesceLocaleField'
import { groq } from 'next-sanity'
import { LocaleId } from '@aether/configs'
import { createLinkProjection } from '../projections/createLinkProjection'

export const createSectionVideoPlayerProjection = (localeId: LocaleId) => {
  const LINK_PROJECTION = createLinkProjection(localeId)

  return groq`{
        _key,
        _type,
        ${coalesceLocaleField('title', localeId)},
        ${coalesceLocaleField('caption', localeId)},
        ${coalesceLocaleField('alt', localeId)},

        ctaLink${LINK_PROJECTION},
        ${coalesceLocaleField('ctaLabel', localeId)},

        sectionSpaceSize,
        videoPosition,
        videoType,
         videoType != "youtubeVideo" => {
               "video": video.asset -> {
                "playbackId": data.playback_ids[0].id,
                "firstFrame": "https://image.mux.com/" + data.playback_ids[0].id + "/thumbnail.jpg?time=0",
                "width": data.static_renditions.files[-1].width,
                "height": data.static_renditions.files[-1].height,
                },
         },
        videoType == "youtubeVideo" => {
          ${coalesceLocaleField('videoYT', localeId)},
        }
      }
  `
}
