import { trimProductNameGenderPrefix } from './trimProductNameGenderPrefix'
export const formatProductName = (name?: string) => {
  if (!name) {
    return ''
  }

  const lastDashIndex = name.lastIndexOf('-')

  // this method removes any additional information from the product title, e.g. color, size, etc. and returns the root product title
  const trimmedTitle =
    lastDashIndex !== -1
      ? name.slice(0, lastDashIndex).trim()
      : `${name.trim()}`

  return trimProductNameGenderPrefix(trimmedTitle)
}
