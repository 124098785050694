import {
  DEFAULT_LOCALE_ID,
  LocaleId,
  ROUTE_BASE_COLLECTIONS,
  ROUTE_BASE_PRODUCTS,
} from '@aether/configs'
import { groq } from 'next-sanity'
import { coalesceLocaleField } from '../helpers/coalesceLocaleField'

export const createLinkProjection = (
  localeId: LocaleId,
  defaultLocaleId = DEFAULT_LOCALE_ID,
) => {
  return groq`{
    linkType == 'internal' => internal -> {
    "href":  select(
      _type == 'collection' => '${ROUTE_BASE_COLLECTIONS}/',
      _type == 'product' => '${ROUTE_BASE_PRODUCTS}/',
      ''
      ) + coalesce(store.slug.current, path.current, '') + coalesce(^.query, '')
    },
    linkType == 'external' => {
      "href": coalesce(external.${localeId}, external.${defaultLocaleId})
    },
    linkType == 'phone' => {
      "href": "tel:" + coalesce(phone.${localeId}, phone.${defaultLocaleId})
    },
    linkType == 'email' => {
      "href": "mailto:" + coalesce(email.${localeId}, email.${defaultLocaleId})
    },
    linkType == 'file' => {
      "href": file.asset->url + "?dl=",
      "download": true,
    },
    linkType == 'linkAction' => {
      "action": {
        "type": linkAction.linkActionType,
        linkAction.linkActionType == 'productModalLinkAction' => {
          "payload": linkAction.products[] -> {"id": store.gid, "type": "single-product"}
        },
      }
    },
    ${coalesceLocaleField('accessibilityLabel', localeId)},
  }`
}
