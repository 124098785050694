import { FC } from 'react'
import { ImageLoader } from 'next/legacy/image'
import { Image } from './Image'
import { MediaProps } from '../models/MediaProps'

export const MuxImage: FC<MediaProps> = ({
  mediaPayload,
  objectFit,
  priority,
  sizes,
  layout,
}) => {
  if (mediaPayload?.type !== 'mux-image') {
    return null
  }

  const { image } = mediaPayload

  const loader: ImageLoader = ({ width, src }) => {
    const params = new URLSearchParams()

    if (width) {
      params.append('width', `${width}`)
    }

    return `${src}&${params.toString()}`
  }

  return (
    <Image
      alt={image.alt ?? ''}
      objectFit={objectFit}
      priority={priority}
      src={mediaPayload.image.src}
      sizes={sizes}
      layout={layout}
      loader={loader}
      placeholder={'empty'}
    />
  )
}
