import { useState, MouseEventHandler, MouseEvent } from 'react'

export const useStackImagesSlider = (images: unknown[] = []) => {
  const [activeSlideIndex, setActiveSlideIndex] = useState<number>(0)
  const [isAnimating, setIsAnimating] = useState<boolean>(false)

  const setNextSlideIndex: MouseEventHandler<HTMLButtonElement> = (event) => {
    event?.preventDefault()
    if (isAnimating) {
      return
    }
    setActiveSlideIndex((prevCurrentSlide) => {
      return prevCurrentSlide < images.length - 1 ? activeSlideIndex + 1 : 0
    })
  }

  const setPrevSlideIndex: MouseEventHandler<HTMLButtonElement> = (event) => {
    event?.preventDefault()
    if (isAnimating) {
      return
    }
    setActiveSlideIndex((prevCurrentSlide) => {
      return prevCurrentSlide > 0 ? activeSlideIndex - 1 : images.length - 1
    })
  }

  const setSlideIndex = (
    index: number,
    event?: MouseEvent<Element, MouseEvent>,
  ) => {
    event?.preventDefault()

    if (isAnimating) {
      return
    }
    event?.preventDefault()
    setActiveSlideIndex(index)
  }

  const onAnimationEnd = () => setIsAnimating(false)
  const onAnimationStart = () => setIsAnimating(true)

  return {
    setNextSlideIndex,
    setPrevSlideIndex,
    setSlideIndex,
    activeSlideIndex,
    onAnimationEnd,
    onAnimationStart,
  }
}
