import { styled } from '@aether/styles'
import { FC } from 'react'

const Root = styled('span', {
  padding: '7px 10px',
  borderRadius: '$rMax',

  variants: {
    appearance: {
      default: {
        $aetherFont: 'ui03',
        backgroundColor: '$gray_light',
        padding: '7px 14px',
      },
      action: {
        $aetherFont: 'ui03',
        backgroundColor: '$blue',
        color: '$white',
      },
      actionRed: {
        $aetherFont: 'ui03',
        backgroundColor: '$red',
        color: '$white',
      },
      vip: {
        $aetherFont: 'ui03Heading',
        fontWeight: 'bold',
        backgroundColor: '$black',
        color: '$white',
        padding: '6px $12',
      },
    },
  },
})

type Props = {
  text: string
  className?: string
  appearance?: 'default' | 'action' | 'vip' | 'actionRed'
}

export const Badge: FC<Props> = ({ text, className, appearance }) => {
  return (
    <Root className={className} appearance={appearance}>
      {text}
    </Root>
  )
}
