import { formatShopifyImage, formatShopifyImages } from './formatShopifyImage'
import { ShopifyListProductFieldsFragment } from '../dist/generatedSchemaSdk'

export const formatShopifyProduct = (
  product: ShopifyListProductFieldsFragment,
) => ({
  ...product,
  featuredImage: product.featuredImage
    ? formatShopifyImage(product.featuredImage)
    : null,
  images: product.images?.nodes
    ? formatShopifyImages(product.images.nodes)
    : null,
  vipProduct: product.vipProduct?.value === 'true',
})

export type ShopifyResolvedListProduct = ReturnType<typeof formatShopifyProduct>
