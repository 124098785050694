import { LocaleId } from '@aether/configs'
import { groq } from 'next-sanity'
import { coalesceLocaleField } from '../helpers/coalesceLocaleField'
import { createSectionJournalHeroProjection } from '../sections/createSectionJournalHeroProjection'
import { createJournalCategoriesProjection } from './createJournalCategoriesProjection'
import { createJournalPictogramProjection } from './createJournalPictogramProjection'
import { createJournalPostProjection } from './createJournalPostProjection'
import { createSEOProjection } from './createSEOProjection'

export const createJournalLandingPageProjection = (localeId: LocaleId) => {
  const POST_PROJECTION = createJournalPostProjection(localeId)
  const JOURNAL_HERO_PROJECTION = createSectionJournalHeroProjection(
    localeId,
    true,
  )
  const PICTOGRAM_PROJECTION = createJournalPictogramProjection(localeId)
  const CATEGORIES_PROJECTION = createJournalCategoriesProjection(localeId)
  const SEO_PROJECTION = createSEOProjection(localeId)
  /**
   * We are creating here groq that return data structure that is consumed by catch all [[...paths]].
   * If featured landing page post exist, we create sectionJournalHero with data from featured post,
   * if featured post remain empty we get latest post to sectionJorunalHero
   * Then, from Posts we are creating sectionPostPreview's. Post that are used to sectionJournalHero is not in sectionPostPreview.
   * This is done by if (groq - select) -> if defined(featuredPost) we get 0...50 posts without featuredPost (&& ^.post->path.current != path.current)
   * If not, we get 1...50 posts beacuse first is used by sectionJorunalHero
   */
  return groq`{
        "type":_type,
        "hideHeader": false,
        "hideFooter": false,
        "headerAppearance": 'journal',
        "isHeaderStatic": true,
        "seo": seo${SEO_PROJECTION},
        "pageBuilder": [
            {
              "_type": "sectionJournalHeader",
              "_key": "sectionJournalHeader" + _id,
              "categories": ${CATEGORIES_PROJECTION},
              "pictogram": *[ !(_id in path('drafts.**')) && _type == "journalLandingPage"][0] {
                pictogram -> ${PICTOGRAM_PROJECTION}
              }.pictogram,
              "caption": *[ !(_id in path('drafts.**')) && _type == "journalConfiguration" ][0] {
                 ${coalesceLocaleField('headerCaption', localeId)},
               }.headerCaption
           },
           ...*[ !(_id in path('drafts.**')) && _type == "journalLandingPage"] {
              "_type": "sectionJournalHero",
              "_key": "sectionJournalHero" + _id,
              ...select(
                defined(post) => post -> {
               ${JOURNAL_HERO_PROJECTION}
                },
                defined(*[ !(_id in path('drafts.**')) && _type == "journalPost" && defined(path.current) && defined(background) ][0]) =>
                *[ !(_id in path('drafts.**')) && _type == "journalPost"  && defined(path.current) && defined(background)] | order(date desc)[0]{
                  ${JOURNAL_HERO_PROJECTION}
                }
              )
           },
           ...*[ !(_id in path('drafts.**')) && _type == "journalLandingPage"] [0] {
            "posts":
                select(
                  defined(post) => *[_type == "journalPost" && defined(path.current) && ^.post->path.current != path.current] | order(date desc)[0...50]{
                    "_type": "sectionPostPreview",
                    "_key": "sectionPostPreview" + _id,
                    "post": ${POST_PROJECTION},
                    "pageContext":"journal",
                  },
                  *[_type == "journalPost" && defined(path.current)] | order(date desc)[1...50]{
                      "_type": "sectionPostPreview",
                      "_key": "sectionPostPreview" + _id,
                      "post": ${POST_PROJECTION},
                      "pageContext":"journal",
                  }
                )
             }.posts
        ]
    }`
}
