import { FC, useEffect } from 'react'
import { styled } from '@aether/styles'
import { useTranslation } from 'react-i18next'
import { ProductColorType, SiteConfiguration } from '@aether/models'
import { useDiscounts } from '@aether/discounts/utils-context'
import { useCartContext } from '../useCartContext'

import { SubTotalSummary } from './SubTotalSummary'
import { DiscountIndicator } from './DiscountIndicator'
import {
  TieredDiscount,
  TieredDiscountIndicator,
} from './TieredDiscountIndicator'
import { StyledClickable, Link, CartLine, Loader } from '@aether/components'
import { useCheckout } from '../useCheckout'
import { cartAnalytics } from '../helpers/cartAnalytics'
import { useCustomerContext } from '@aether/account/utils-customer-context'

const Root = styled('div', {
  display: 'grid',
  placeItems: 'center',
  paddingBottom: '$64',
})

const CartLayout = styled('div', {
  width: '100%',
  height: '100%',
  display: 'grid',
  gridColumnGap: '$64',
  alignItems: 'start',
  alignContent: 'start',
  gridTemplateColumns: 'repeat(12, 1fr)',
})

const CartLinesWrap = styled('div', {
  display: 'grid',
  padding: '$20 0 $36 0',
  borderTop: '1px solid $black',
  gridColumn: 'span 7',
  borderBottom: '1px solid $black',
  variants: {
    isCartEmpty: {
      true: {
        gridColumn: 'span 13',
      },
    },
  },
})
const CartSummaryWrap = styled('div', {
  borderTop: '1px solid $black',
  padding: '$20 0 0 0',
  display: 'grid',
  alignContent: 'start',
  gridColumn: '8 / 13',
})

const TitleWrap = styled('div', {
  display: 'grid',
  gridTemplateColumns: 'max-content max-content',
  gap: '20px',
  paddingBottom: '$36',
  alignItems: 'center',
})

const CartTitle = styled('h1', {
  $aetherFont: 'heading03',
  textAlign: 'center',
})

const SummaryTitle = styled('h1', {
  $aetherFont: 'heading03',
  paddingBottom: '$36',
  textAlign: 'left',
})

const LineAmountCoutner = styled('span', {
  $aetherFont: 'ui05',
  border: '1px solid $black',
  borderRadius: '$r1',
  padding: '4px 8px',
})

const LineItemsList = styled('ul', {
  display: 'grid',
  gridRowGap: '$36',
})

const EmptyCartLinksList = styled('ul', {
  display: 'grid',
  gridRowGap: '$16',
  width: '100%',
  justifyItems: 'center',
})

const EmptyCartLink = styled(Link, {
  width: '100%',
})

const StyledDiscountIndicator = styled(DiscountIndicator, {
  paddingBottom: '$36',
})

const StyledTieredDiscountIndicator = styled(TieredDiscountIndicator, {
  paddingBottom: '$36',
})

const StyledSubTotalSummary = styled(SubTotalSummary, {
  paddingBottom: '$16',
})

const EmptyCartLinkWrap = styled('li', {
  width: '100%',
  maxWidth: '400px',
})

export const FullCart: FC<{
  cartConfig: SiteConfiguration['cart']
  colorsMap: ProductColorType[]
}> = ({ cartConfig, colorsMap }) => {
  const { cart, quantity, removeCartLines, updateCartLines, setCartModalOpen } =
    useCartContext()

  const { t } = useTranslation('cart')
  const { handleCheckout, isCheckoutLoading } = useCheckout()
  const { userRole } = useCustomerContext()
  const isVip = userRole === 'vip'
  const cartReady = Boolean(cart)
  const emptyCartLinks = cartConfig?.emptyCartLinks || []

  const discountTiersLabel = cartConfig?.discountLabel
  const discountTiers = cartConfig?.discounts
  const nonDiscountedProductTypes = cartConfig?.nonDiscountedProductTypes
  const nonDiscountedVendors = cartConfig?.nonDiscountedVendors

  const isCartEmpty = quantity === 0
  const enablePromotion = cartConfig?.enablePromotion

  const { tieredDiscounts } = useDiscounts({
    collections: [],
    userRole,
  })
  const hasTieredDiscount = tieredDiscounts && tieredDiscounts?.length > 0

  useEffect(() => {
    if (!cart) {
      return
    }
    cartAnalytics.viewCart(cart)
  }, [cart])

  return (
    <Root>
      <CartLayout>
        <CartLinesWrap isCartEmpty={isCartEmpty}>
          <TitleWrap>
            <CartTitle>{t('cart')}</CartTitle>
            {cartReady && (
              <LineAmountCoutner>
                {t('quantity', { value: quantity })}
              </LineAmountCoutner>
            )}
          </TitleWrap>
          {(() => {
            if (cartReady && !isCartEmpty) {
              return (
                <LineItemsList>
                  {cart?.lines?.map((line) => (
                    <li key={line.id}>
                      <CartLine
                        line={line}
                        colorsMap={colorsMap}
                        removeCartLines={removeCartLines}
                        updateCartLines={updateCartLines}
                        setCartModalOpen={setCartModalOpen}
                        isCartDiscountApplied={
                          enablePromotion || hasTieredDiscount
                        }
                        nonDiscountedProductTypes={nonDiscountedProductTypes}
                        nonDiscountedVendors={nonDiscountedVendors}
                      />
                    </li>
                  ))}
                </LineItemsList>
              )
            }

            if (cartReady && isCartEmpty) {
              return (
                <EmptyCartLinksList>
                  {emptyCartLinks.map(({ label, link }) => {
                    return (
                      <EmptyCartLinkWrap key={label}>
                        <EmptyCartLink {...link} appearance={'badgeBlack'}>
                          {label}
                        </EmptyCartLink>
                      </EmptyCartLinkWrap>
                    )
                  })}
                </EmptyCartLinksList>
              )
            }
            return null
          })()}
        </CartLinesWrap>
        {!isCartEmpty ? (
          <CartSummaryWrap>
            <SummaryTitle as={'h2'}>{t('orderSummary')}</SummaryTitle>

            {/**
             * ideally the Tiered discounts indicator below should be unified
             * as per https://github.com/commerce-ui/aether/issues/228
             * */}

            {enablePromotion && !isVip && (
              <StyledDiscountIndicator
                cart={cart}
                discountLabel={discountTiersLabel}
                discounts={discountTiers}
              />
            )}
            {hasTieredDiscount && (
              <StyledTieredDiscountIndicator
                cart={cart}
                discounts={tieredDiscounts as TieredDiscount[]}
              />
            )}

            <StyledSubTotalSummary cart={cart} />
            <StyledClickable
              appearance={'badgeBlack'}
              onClick={handleCheckout}
              disabled={isCheckoutLoading}
            >
              {isCheckoutLoading ? <Loader /> : t('checkout')}
            </StyledClickable>
          </CartSummaryWrap>
        ) : null}
      </CartLayout>
    </Root>
  )
}
