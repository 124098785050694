import identity from 'lodash.identity'

import { MyPageBuilderSection } from './types'
import { extractTextFromSection } from './extractTextFromSection'

export const extractTextContentFromPageBuilder = (
  pageBuilderSections?: MyPageBuilderSection[],
) => {
  if (!pageBuilderSections) {
    return ''
  }

  return pageBuilderSections
    .map((section) => extractTextFromSection(section))
    .filter(identity)
    .join('\n\n')
}
