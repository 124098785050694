import React, { FC } from 'react'

type Props = {
  className?: string
  size?: 'default' | 'small'
}

export const ArrowRight: FC<Props> = ({ className, size = 'default' }) => {
  const [width, height] = (() => {
    switch (size) {
      default:
        return [20, 14]
    }
  })()

  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 20 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.4 0.15C13.2 -0.05 12.89 -0.05 12.69 0.15C12.49 0.35 12.49 0.66 12.69 0.86L17.9 6.07H0.5C0.22 6.07 0 6.29 0 6.57C0 6.85 0.22 7.07 0.5 7.07H17.82L12.61 12.28C12.41 12.48 12.41 12.79 12.61 12.99C12.71 13.09 12.84 13.14 12.96 13.14C13.08 13.14 13.22 13.09 13.31 12.99L19.76 6.54L13.4 0.15Z"
        fill="black"
      />
    </svg>
  )
}
