import { useRef } from 'react'

export const usePrevious = <TValue>(
  value: TValue,
  isEqualFunc?: (prev: TValue, next: TValue) => boolean,
) => {
  const ref = useRef<{ value: TValue; prev: TValue | null }>({
    value: value,
    prev: null,
  })

  const current = ref.current.value

  if (isEqualFunc ? !isEqualFunc(current, value) : value !== current) {
    ref.current = {
      value: value,
      prev: current,
    }
  }

  return ref.current.prev
}
