import { useCustomerContext } from '@aether/account/utils-customer-context'
import { useRouter } from 'next/router'
import { useCartContext } from './useCartContext'
import { getMultipass } from '@aether/services/api-service'
import { useState } from 'react'
import { cartAnalytics } from './helpers/cartAnalytics'

type ReturnType = {
  handleCheckout: () => void
  isCheckoutLoading: boolean
}

export const useCheckout = (): ReturnType => {
  const { locale } = useRouter()
  const { isLoggedIn, isVerified } = useCustomerContext()
  const { cart, setCartModalOpen, isReturnsMode, handleReturnsCheckout } =
    useCartContext()
  const [isCheckoutLoading, setIsCheckoutLoading] = useState(false)
  const checkoutUrl = cart?.checkoutUrl
  const isLoggedInUser = isLoggedIn && isVerified

  const handleCheckout = async () => {
    setIsCheckoutLoading(true)

    if (isReturnsMode) {
      await handleReturnsCheckout()
      setCartModalOpen(false)
      return
    }

    if (isLoggedInUser && locale && checkoutUrl) {
      const { url } = await getMultipass({ locale, returnTo: checkoutUrl })
      cartAnalytics.checkout(cart, 'multipass')
      window.location.href = url
      return
    }

    if (!isLoggedInUser && checkoutUrl) {
      cartAnalytics.checkout(cart)
      window.location.href = checkoutUrl
      return
    }
    setIsCheckoutLoading(false)

    throw new Error('[handleCheckout] missing cart checkoutUrl')
  }

  return {
    handleCheckout,
    isCheckoutLoading,
  }
}
