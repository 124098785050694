import { ShopifyProductVariant } from '@aether/models'
import { FC } from 'react'
import { PriceCompare } from '../components/PriceCompare/PriceCompare'
import { Discount } from '@aether/discounts/utils-context'
import { calculatePrice } from './calculatePrice'
import { calculateCompareAtPrice } from './calculateCompareAtPrice'
import { PromoPriceWrapperProps } from '../PromoPriceWrapper/PromoPriceWrapper'

export const ProductVariantPrice: FC<{
  productVariant: ShopifyProductVariant
  discount?: Discount
  promoPriceColor?: PromoPriceWrapperProps
}> = ({ productVariant, discount, promoPriceColor }) => {
  const price = calculatePrice(productVariant, discount?.percentage)
  const compareAtPrice = calculateCompareAtPrice(
    productVariant,
    discount?.percentage,
  )

  return (
    <PriceCompare
      price={price}
      compareAtPrice={compareAtPrice}
      promoPriceColor={promoPriceColor}
    />
  )
}
