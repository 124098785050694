import { Link, Media, ProductSearchResultPrice } from '@aether/components'
import { ROUTE_BASE_PRODUCTS } from '@aether/configs'
import { ProductHit } from '@aether/models'
import { styled } from '@aether/styles'
import { useRouter } from 'next/router'
import { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import {
  useHits,
  useSearchBox,
  InstantSearch,
  useConfigure,
} from 'react-instantsearch-hooks-web'
import { searchClient } from './searchClient'
import useFilters from './useFilters'
import { trimProductNameGenderPrefix } from '@aether/utils'

import { PlainSearchParameters } from 'algoliasearch-helper'

const Root = styled('div', {
  padding: '$48 0',
  $containerSpace: 'small',
  '@md': {
    $containerSpace: 'medium',
  },
  '@xl': {
    $containerSpace: 'medium',
  },
  '@xxl': {
    $containerSpace: 'xlarge',
  },
})
const TopWrap = styled('div', {
  textAlign: 'center',
  display: 'grid',
  gridGap: '$24',
})
const SearchValue = styled('p', {
  $aetherFont: 'heading02',
  textTransform: 'capitalize',
})
const Counter = styled('p', {
  $aetherFont: 'ui03',
})
const ResultsWrap = styled('div', {
  display: 'grid',
  marginTop: '$56',
  gridTemplateColumns: '1fr',
  gridGap: '$96',
  '@lg': {
    marginTop: '$96',
    gridTemplateColumns: '1fr 1fr',
  },
})
const ProductImageWrap = styled('div', {
  width: '100%',
  paddingBottom: '130%',
  height: 0,
  display: 'block',
  position: 'relative',
  top: 0,
  left: 0,
})

const StyledLink = styled(Link, {
  display: 'grid',
  gridGap: '$12',
  alignContent: 'start',
})
const Title = styled('h5', {
  $aetherFont: 'heading06',
  textTransform: 'none',
})
const MediaWrap = styled('div', {
  width: '100%',
  height: '100%',
  display: 'block',
  position: 'absolute',
})
const NoResults = styled('div', {
  textAlign: 'center',
  $aetherFont: 'ui01',
})
const PriceWrap = styled('div', {
  $aetherFont: 'ui03',
})

interface SearchParametersWithFilters extends PlainSearchParameters {
  filters?: string
}

export const Content: FC = () => {
  const router = useRouter()
  const query = router?.query || {}
  const { t } = useTranslation('search')
  const { refine, query: algoliaQuery } = useSearchBox()
  const { hits, results } = useHits<ProductHit>()
  const valueFromQuery = Array.isArray(query.v) ? query.v[0] : query.v || ''
  const { filters } = useFilters()

  useConfigure({ filters: filters || '' } as SearchParametersWithFilters)

  useEffect(() => {
    if (valueFromQuery !== algoliaQuery) {
      refine(valueFromQuery)
    }
  }, [valueFromQuery])

  if (!valueFromQuery) {
    return (
      <Root>
        <NoResults>{t('noResults')}</NoResults>
      </Root>
    )
  }

  return (
    <Root>
      <TopWrap>
        <SearchValue>{valueFromQuery}</SearchValue>
        <Counter>
          {results?.nbHits
            ? t('showing', { value: undefined })
            : t('noResults')}
        </Counter>
      </TopWrap>
      <ResultsWrap>
        {hits.length > 0 &&
          hits.map(
            (hit) =>
              hit.image && (
                <StyledLink
                  appearance="grid"
                  key={hit.title}
                  href={`${ROUTE_BASE_PRODUCTS}/${hit.handle}`}
                >
                  <ProductImageWrap>
                    <MediaWrap>
                      <Media
                        // HACK: since algolia does not return the image properties we need to hardcode them here
                        mediaPayload={{
                          image: {
                            src: `${hit.image}`,
                            width: 969,
                            height: 1000,
                          },

                          type: 'shopify-image',
                        }}
                        layout="fill"
                        hardcropRatio="portrait1"
                        sizes={{ lg: '50vw' }}
                      />
                    </MediaWrap>
                  </ProductImageWrap>
                  <Title>{trimProductNameGenderPrefix(hit.title)}</Title>
                  <PriceWrap>
                    <ProductSearchResultPrice hit={hit} />
                  </PriceWrap>
                </StyledLink>
              ),
          )}
      </ResultsWrap>
    </Root>
  )
}

export const SearchPage: FC = () => {
  return (
    <InstantSearch
      searchClient={searchClient}
      indexName={process.env.NEXT_PUBLIC_ALGOLIA_INDEX_NAME || ''}
    >
      <Content />
    </InstantSearch>
  )
}
