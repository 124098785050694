import {
  createShopifySdk,
  formatShopifyCollection,
  ShopifyProductCollectionSortKeys,
  ShopifyProductFilter,
} from '@aether/shopify-sdk'
import { createShopifyClient } from './createShopifyClient'
import { getLocaleRegionIdFromPath } from '@aether/utils'
import { creatVipProductFilters } from './helpers/formatCollectionFiltersQuery'

export const COLLECTION_INITIAL_PRODUCTS_COUNT = 16

export const getSDKShopifyCollection = async ({
  locale,
  handle,
  afterCursor = undefined,
  count = COLLECTION_INITIAL_PRODUCTS_COUNT,
  filters: collectionFilters = [],
  isVip = false,
  sortKey,
  reverse,
}: {
  afterCursor?: string
  locale: string
  filters?: ShopifyProductFilter[]
  handle: string
  count?: number
  isVip?: boolean
  sortKey?: ShopifyProductCollectionSortKeys
  reverse?: boolean
}) => {
  const [, localeId] = getLocaleRegionIdFromPath(locale)

  const vipProductsFilters = creatVipProductFilters(isVip)
  const filters = [...collectionFilters, ...vipProductsFilters]

  const shopifyClient = createShopifyClient(localeId)

  return await createShopifySdk(shopifyClient)
    .getCollection({
      filters,
      sortKey,
      reverse,
      count,
      handle,
      afterCursor: afterCursor || null,
    })
    .then((res) =>
      res.data.collection ? formatShopifyCollection(res.data.collection) : null,
    )
}
