import { styled } from '@aether/styles'
import { FC } from 'react'
import {
  ContainerSpaceType,
  SanityImageMediaPayload,
  ShopifyImageMediaPayload,
} from '@aether/models'

const IndicatorWrap = styled('ol', {
  gridRow: 1,
  backgroundColor: '$gray_light_second',
  width: '85vw',
  display: 'flex',
  variants: {
    isVsisible: {
      true: {
        opacity: 1,
      },
    },
    spacing: {
      small: {
        width: '85vw',
        $lin: {
          marginLeft: 'CONTAINER_SPACE_M',
        },
      },
      medium: {
        width: '85vw',
        $lin: {
          marginLeft: 'CONTAINER_SPACE_M',
        },
      },
      none: {
        width: '100%',
      },
      large: {
        width: '85vw',
        $lin: {
          marginLeft: 'CONTAINER_SPACE_M',
        },
      },
      xlarge: {
        width: '85vw',
        $lin: {
          marginLeft: 'CONTAINER_SPACE_M',
        },
      },
    },
    size: {
      S: {
        height: '1px',
      },
      M: {
        height: '2px',
      },
    },
  },
})

const IndicatorItem = styled('li', {
  backgroundColor: '$black',
  variants: {
    isActive: {
      true: {
        opacity: 1,
        transition: 'all 250ms linear',
      },
      false: {
        opacity: 0,
        transition: 'all 250ms linear',
      },
    },
    size: {
      S: {
        height: '1px',
      },
      M: {
        height: '2px',
      },
    },
  },
})

type Props = {
  images: (ShopifyImageMediaPayload | SanityImageMediaPayload)[]
  currentSlide: number
  spacing?: ContainerSpaceType
  size?: 'S' | 'M'
  className?: string
}

export const ProgressIndicator: FC<Props> = ({
  images,
  currentSlide,
  spacing = 'none',
  size = 'S',
  className,
}) => {
  return (
    <IndicatorWrap
      className={className}
      isVsisible={images.length > 1}
      spacing={spacing}
      size={size}
    >
      {images.map((img, index) => (
        <IndicatorItem
          size={size}
          key={`image-${index}`}
          isActive={index === currentSlide}
          css={{
            width: `${(100 / images.length) * 100}%`,
          }}
        />
      ))}
    </IndicatorWrap>
  )
}
