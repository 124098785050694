import { createSectionPageTitleProjection } from './../sections/createSectionPageTitleProjection'
import { groq } from 'next-sanity'
import { LocaleId } from '@aether/configs'
import { createSectionCollectionItemsProjection } from '../sections/createSectionCollectionItemsProjection'
import { createSectionPortraitPictureWithCaptionProjection } from '../sections/createSectionPortraitPictureWithCaptionProjection'
import { createSectionHeroProjection } from '../sections/createSectionHeroProjection'
import { createSectionLandscapePictureWithCaptionProjection } from '../sections/createSectionLandscapePictureWithCaptionProjection'
import { createSectionPictureWithCaptionProjection } from '../sections/createSectionPictureWithCaptionProjection'
import { createSectionTripleCardsProjection } from '../sections/createSectionTripleCardsProjection'
import { createSectionDoubleCardsWithCaptionProjection } from '../sections/createSectionDoubleCardsWithCaptionProjection'
import { createSectionPostPreviewProjection } from '../sections/createSectionPostPreviewProjection'
import { createSectionHalfImageProjection } from '../sections/createSectionHalfImageProjection'
import { createSectionDoubleImageProjection } from '../sections/createSectionDoubleImageProjection'
import { createSectionShopTheProductsProjection } from '../sections/createSectionShopTheProductsProjection'
/**
 * Adding this section causes an issue with groq request - occasionally the request returns an empty page build collection.
 * Currently this section is only used and hardcoded into the Journal Post Page projection so it is safe to keep it out of page builder,
 * TODO: We should find the way to make it possible to add this section in the page builder without caou
 */
// import { createSectionCategoryPostsPreviewProjection } from '../sections/createSectionCategoryPostsPreviewProjection'
import { createSectionJournalHeaderProjection } from '../sections/createSectionJournalHeaderProjection'
import { createSectionScatteredGalleryProjection } from '../sections/createSectionScatteredGalleryProjection'
import { createSectionProgressIndicatorProjection } from '../sections/createSectionProgressIndicatorProjection'
import { createSectionQuoteProjection } from '../sections/createSectionQuoteProjection'
import { createSectionTripleImageProjection } from '../sections/createSectionTripleImageProjection'
import { createSectionAccordionGroupsProjection } from '../sections/createSectionAccordionGroupsProjection'
import { createSectionRichTextProjection } from '../sections/createSectionRichTextProjection'
import { createSectionSingleImageProjection } from '../sections/createSectionSingleImageProjection'
import { createSectionAboutProjection } from '../sections/createSectionAboutProjection'
import { createSectionBoldTextProjection } from '../sections/createSectionBoldTextProjection'
import { createSectionSingleImageWithDoubleTextProjection } from '../sections/createSectionSingleImageWithDoubleTextProjection'
import { createSectionSinglePromoProjection } from '../sections/createSectionSinglePromoProjection'
import { createSectionImagesWithDescriptionProjection } from '../sections/createSectionImagesWithDescriptionProjection'
import { createSectionDoublePromoProjection } from '../sections/createSectionDoublePromoProjection'
import { createSectionHeroLandingProjection } from '../sections/createSectionHeroLandingProjection'
import { createSectionTechnicalSpecificationsProjection } from '../sections/createSectionTechnicalSpecificationsProjection'
import { createSectionDoubleMediaProjection } from '../sections/createSectionDoubleMediaProjection'
import { createSectionStackImagesProjection } from '../sections/createSectionStackImagesProjection'
import { createSectionProductGuaranteesProjection } from '../sections/createSectionProductGuaranteesProjection'
import { createSectionVideoPlayerProjection } from '../sections/createSectionVideoPlayerProjection'
import { createSectionKlaviyoFormEmbedProjection } from '../sections/createSectionKlaviyoFormEmbedProjection'
import { createSectionCatalogProjection } from '../sections/createSectionCatalogProjection'
import { createSectionShopifyRelatedProductsProjection } from '../sections/createSectionShopifyRelatedProductsProjection'
import { createSectionShopifyComplimentaryProductsProjection } from '../sections/createSectionShopifyComplimentaryProductsProjection'
import { createSectionCollaborationsCardsProjection } from '../sections/createSectionCollaborationsCardsProjection'

export const createPageBuilderProjection = (localeId: LocaleId) => groq`{
  _type == 'sectionCollectionItems' =>  ${createSectionCollectionItemsProjection(
    localeId,
  )},
  _type == 'sectionPortraitPictureWithCaption' =>  ${createSectionPortraitPictureWithCaptionProjection(
    localeId,
  )},
  _type == 'sectionTripleCards' =>  ${createSectionTripleCardsProjection(
    localeId,
  )},
  _type == 'sectionDoubleCardsWithCaption' =>  ${createSectionDoubleCardsWithCaptionProjection(
    localeId,
  )},
  _type == 'sectionHero' =>  ${createSectionHeroProjection(localeId)},
  _type == 'sectionLandscapePictureWithCaption' =>  ${createSectionLandscapePictureWithCaptionProjection(
    localeId,
  )},
  _type == 'sectionPictureWithCaption' =>  ${createSectionPictureWithCaptionProjection(
    localeId,
  )},
  _type == 'sectionPostPreview' =>  ${createSectionPostPreviewProjection(
    localeId,
  )},
  _type == 'sectionSingleImage' =>  ${createSectionSingleImageProjection(
    localeId,
  )},
  _type == 'sectionHalfImage' =>  ${createSectionHalfImageProjection(localeId)},
  _type == 'sectionDoubleImage' =>  ${createSectionDoubleImageProjection(
    localeId,
  )},
  _type == 'sectionJournalHeader' =>  ${createSectionJournalHeaderProjection(
    localeId,
  )},
  _type == 'sectionShopTheProducts' =>  ${createSectionShopTheProductsProjection(
    localeId,
  )},
  _type == 'sectionScatteredGallery' =>  ${createSectionScatteredGalleryProjection(
    localeId,
  )},
  _type == 'sectionProgressIndicator' =>  ${createSectionProgressIndicatorProjection(
    localeId,
  )},
  _type == 'sectionQuote' =>  ${createSectionQuoteProjection(localeId)},
  _type == 'sectionTripleImage' =>  ${createSectionTripleImageProjection(
    localeId,
  )},
  _type == 'sectionSingleImageWithDoubleText' =>  ${createSectionSingleImageWithDoubleTextProjection(
    localeId,
  )},
  _type == 'sectionAccordionGroups' =>  ${createSectionAccordionGroupsProjection(
    localeId,
  )},
  _type == 'sectionRichText' =>  ${createSectionRichTextProjection(localeId)},
  _type == 'sectionAbout' =>  ${createSectionAboutProjection(localeId)},
  _type == 'sectionBoldText' =>  ${createSectionBoldTextProjection(localeId)},
  _type == 'sectionSinglePromo' =>  ${createSectionSinglePromoProjection(
    localeId,
  )},
  _type == 'sectionPageTitle' =>  ${createSectionPageTitleProjection(localeId)},
  _type == 'sectionImagesWithDescription' =>  ${createSectionImagesWithDescriptionProjection(
    localeId,
  )},
  _type == 'sectionDoublePromo' =>  ${createSectionDoublePromoProjection(
    localeId,
  )},
  _type == 'sectionHeroLanding' =>  ${createSectionHeroLandingProjection(
    localeId,
  )},
  _type == 'sectionTechnicalSpecifications' =>  ${createSectionTechnicalSpecificationsProjection(
    localeId,
  )},
  _type == 'sectionDoubleMedia' =>  ${createSectionDoubleMediaProjection(
    localeId,
  )},
  _type == 'sectionStackImages' =>  ${createSectionStackImagesProjection(
    localeId,
  )},
  _type == 'sectionProductGuarantees' =>  ${createSectionProductGuaranteesProjection(
    localeId,
  )},
  _type == 'sectionVideoPlayer' =>  ${createSectionVideoPlayerProjection(
    localeId,
  )},
  _type == 'sectionKlaviyoFormEmbed' =>  ${createSectionKlaviyoFormEmbedProjection(
    localeId,
  )},
  _type == 'sectionCatalog' =>  ${createSectionCatalogProjection(localeId)},
  _type == 'sectionShopifyRelatedProducts' =>  ${createSectionShopifyRelatedProductsProjection(
    localeId,
  )},
  _type == 'sectionShopifyComplimentaryProducts' =>  ${createSectionShopifyComplimentaryProductsProjection(
    localeId,
  )},
  _type == 'sectionCollaborationsCards' =>  ${createSectionCollaborationsCardsProjection(
    localeId,
  )},
}`
