import {
  useState,
  createContext,
  FC,
  useEffect,
  useContext,
  PropsWithChildren,
} from 'react'
import Cookies from 'js-cookie'
import { useRouter } from 'next/router'

import {
  CustomerResponseType,
  createCustomerClient,
} from '@aether/account/data-access'
import { accountAnalytics } from '@aether/account/utils-analytics'

// includes redirect to account page
// TODO: consider moving to envs
const SHOPIFY_ACCOUNT_LOGOUT_URL =
  'https://secure.aetherapparel.com/account/logout?return_url=%2Faccount'

export type UserRoleType = 'vip' | 'customer'

export type CustomerContextType = {
  isLoggedIn: boolean
  isVerified: boolean
  customer: CustomerResponseType | null
  accessToken: string | null
  setToken: (token: string) => void
  removeToken: () => void
  updateCustomer: (customer?: CustomerResponseType) => void
  userRole?: UserRoleType
}

export const CustomerContext = createContext<CustomerContextType>({
  isVerified: false,
  isLoggedIn: false,
  customer: null,
  accessToken: null,
  setToken: () => null,
  removeToken: () => null,
  updateCustomer: () => null,
  userRole: undefined,
})

export const COOKIES_ACCESS_TOKEN_ID = `AETHER_CUSTOMER_ACCESS_TOKEN`
export const COOKIES_CUSTOMER_ROLE = `AETHER_CUSTOMER_ROLE`

const createCustomerAccessTokenCookieService = () => {
  return {
    get: () => Cookies.get(COOKIES_ACCESS_TOKEN_ID),
    set: (token: string) =>
      Cookies.set(COOKIES_ACCESS_TOKEN_ID, token, { expires: 5 }),
    remove: () => Cookies.remove(COOKIES_ACCESS_TOKEN_ID),
  }
}

const createCustomerRoleCookieService = () => {
  return {
    get: () => Cookies.get(COOKIES_CUSTOMER_ROLE),
    set: (role: 'vip' | 'customer') =>
      Cookies.set(COOKIES_CUSTOMER_ROLE, String(role), { expires: 5 }),
    remove: () => Cookies.remove(COOKIES_CUSTOMER_ROLE),
  }
}

export const CustomerProvider: FC<PropsWithChildren<{ debug?: boolean }>> = ({
  children,
}) => {
  const { locale } = useRouter()
  const [customer, setCustomer] = useState<CustomerResponseType | null>(null)
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false)
  const [isVerified, setIsVerified] = useState<boolean>(false)
  const [accessToken, setAccessToken] = useState<string | null>(null)
  const [userRole, setUserRole] = useState<UserRoleType | undefined>(undefined)

  const cookiesTokenService = createCustomerAccessTokenCookieService()
  const customerRoleService = createCustomerRoleCookieService()
  const customerClient = createCustomerClient({ locale })

  const getUserRole = (_customer: CustomerResponseType): UserRoleType => {
    if (_customer?.vipCustomer?.value === 'true') {
      return 'vip'
    }

    return 'customer'
  }

  const getCustomer = async (
    customerAccessToken: string,
  ): Promise<CustomerResponseType | null> => {
    const response = await customerClient.get({ customerAccessToken })
    if (response?.customer) {
      return response.customer
    }

    return null
  }

  const setToken = (customerAccessToken: string) => {
    setAccessToken(customerAccessToken)
    cookiesTokenService.set(customerAccessToken)
  }

  const removeToken = async () => {
    if (accessToken) {
      await customerClient.deleteToken({ customerAccessToken: accessToken })
      setAccessToken(null)
      cookiesTokenService.remove()
    }
    // IMPORTANT:  we need to additionally logout user from the checkout
    window.location.href = SHOPIFY_ACCOUNT_LOGOUT_URL
  }

  const updateCustomer = async (updatedCustomer?: CustomerResponseType) => {
    if (updatedCustomer) {
      setCustomer(updatedCustomer)
      return
    }
    if (accessToken) {
      const _customer = await getCustomer(accessToken)
      setCustomer(_customer)
    }
  }

  const loginUser = (_customer: CustomerResponseType) => {
    const role = getUserRole(_customer)
    customerRoleService.set(role)
    setUserRole(role)
    setIsLoggedIn(true)
    accountAnalytics.logIn(role)
  }

  const logoutUser = () => {
    setUserRole(undefined)
    setIsLoggedIn(false)
    customerRoleService.remove()
    accountAnalytics.logOut()
  }

  const verifyUserToken = async (token?: string | null) => {
    if (!token) {
      logoutUser()
      setIsVerified(true)
      return
    }

    const _customer = await getCustomer(token)
    if (_customer) {
      setToken(token)
      setCustomer(_customer)
      loginUser(_customer)
    } else {
      logoutUser()
      setCustomer(null)
    }
    setIsVerified(true)
  }

  // recover login state form cookies on mount
  useEffect(() => {
    const customerAccessToken = cookiesTokenService.get()
    verifyUserToken(customerAccessToken)
  }, [])

  // update login state on token change
  useEffect(() => {
    if (isVerified) {
      verifyUserToken(accessToken)
    }
  }, [accessToken, isVerified])

  return (
    <CustomerContext.Provider
      value={{
        customer,
        accessToken,
        setToken,
        removeToken,
        updateCustomer,
        isLoggedIn,
        isVerified,
        userRole,
      }}
    >
      {children}
    </CustomerContext.Provider>
  )
}

export const useCustomerContext = (): CustomerContextType => {
  return useContext(CustomerContext)
}
