import { coalesceLocaleField } from '../helpers/coalesceLocaleField'
import { groq } from 'next-sanity'
import { LocaleId } from '@aether/configs'
import { createMediaProjection } from '../projections/createMediaProjection'

export const createSectionJournalHeroProjection = (
  localeId: LocaleId,
  postPage: boolean,
) => {
  const MEDIA_PROJECTION = createMediaProjection(localeId)

  return groq`
  ${coalesceLocaleField('title', localeId)},
  background${MEDIA_PROJECTION},
  heroTitlePosition,
  heroTextTheme,
  "ctaLink": ${
    postPage
      ? `{
    "href":path.current
  }`
      : 'null'
  }
  `
}
