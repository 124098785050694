import { groq } from 'next-sanity'
import { DEFAULT_LOCALE_ID, LocaleId } from '@aether/configs'

export const createSectionShopifyRelatedProductsProjection = (
  localeId: LocaleId,
) => {
  return groq`{
        _key,
        _type,
        "title": coalesce(title.${localeId}, title.${DEFAULT_LOCALE_ID}, "You May Also Like"),
        "pageConext":"default",
      } 
  `
}
