import { ImageProps } from 'next/image'
import { forwardRef } from 'react'
import { styled } from '@aether/styles'
import { JournalPictogram } from '@aether/models'
import { Media } from '../Media/Media'
import { Image } from '../Media/components/Image'
import mountainsPictogramImage from './mountainsPictogram.png'

const PictogramWrap = styled('div', {
  gridArea: 'pictogram',
  alignSelf: 'center',
  justifySelf: 'center',
  variants: {
    size: {
      S: {
        width: '64px',
        height: '35px',
      },
      L: {
        width: '92px',
        height: '50px',
      },
    },
  },
})

const defaultImage = mountainsPictogramImage as unknown as Omit<
  ImageProps,
  'sizes' | 'layout' | 'objectPosition' | 'objectFit'
>

export const Pictogram = forwardRef<
  HTMLDivElement,
  {
    pictogram?: JournalPictogram
    size?: 'S' | 'L'
    className?: string
  }
>(({ pictogram, size = 'L', className }, ref) => {
  return (
    <PictogramWrap size={size} ref={ref} className={className}>
      {pictogram ? (
        <Media
          {...pictogram.image}
          sizes={{ lg: '92px', default: '64px' }}
          objectFit={'contain'}
          layout="responsive-cover"
        />
      ) : (
        <Image
          {...defaultImage}
          sizes={{ lg: '92px', default: '64px' }}
          objectFit={'contain'}
          layout="responsive-cover"
          alt="Mountains pictogram"
        />
      )}
    </PictogramWrap>
  )
})
