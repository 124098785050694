import { ShopifyPrice } from '@aether/models'
import { getLocaleRegionIdFromPath } from './getLocaleRegionIdFromPath'

export const getI18nPrice = (
  price: ShopifyPrice,
  locale?: string,
  options?: Intl.NumberFormatOptions,
): string => {
  const [regionId, localeId] = getLocaleRegionIdFromPath(locale)

  return new Intl.NumberFormat(`${localeId}-${regionId}`, {
    style: 'currency',
    currency: price.currencyCode,
    ...(options || {}),
  }).format(Number(price.amount))
}
