import { styled } from '@aether/styles'
import { FC, PropsWithChildren } from 'react'

import { AccordionHeaderDefault } from './AccordionHeaderDefault'
import { useHeightAnimation } from '../helpers/useHeightAnimation'

const Button = styled('button', {
  width: '100%',
  background: 'none',
  margin: '$0',
  padding: '$0',
  color: 'unset',
  '-webkit-tap-highlight-color': 'transparent',
  $focusStyle: 'negativeOffset',
})

export type AccordionHeaderType = 'default' | 'uppercase'

export type AccordionItemProps = {
  itemId?: number | string
  isActive?: boolean
  className?: string
  onRequestOpen?: (index: number | string) => void
  onRequestClose?: (index: number | string) => void
  label?: string
  headerType?: AccordionHeaderType
  backgroundColor?: string
  headerComponent?: FC<{ isActive: boolean }>
}

export const AccordionItem: FC<PropsWithChildren<AccordionItemProps>> = ({
  itemId,
  isActive = false,
  headerType = 'default',
  onRequestClose,
  onRequestOpen,
  label,
  className,
  children,
  headerComponent: HeaderComponent,
}) => {
  const handleClick = () => {
    if (onRequestClose && onRequestOpen && itemId !== undefined) {
      isActive ? onRequestClose(itemId) : onRequestOpen(itemId)
    }
  }

  const Header = (() => {
    switch (headerType) {
      case 'default':
      default:
        return AccordionHeaderDefault
    }
  })()

  const [bodyRef, styles] = useHeightAnimation<HTMLDivElement>({
    isActive,
  })

  const accordionId = `accordion-${itemId}`
  const sectionId = `sect-${itemId}`

  return (
    <>
      <Button
        onClick={handleClick}
        aria-expanded={isActive}
        aria-controls={sectionId}
        id={accordionId}
      >
        {HeaderComponent ? (
          <HeaderComponent isActive={isActive} />
        ) : (
          <Header isActive={isActive}>{label || ''}</Header>
        )}
      </Button>

      <div
        className={className}
        role="region"
        ref={bodyRef}
        style={styles}
        id={sectionId}
        aria-labelledby={accordionId}
      >
        {children}
      </div>
    </>
  )
}
