import { groq } from 'next-sanity'
import { createMediaProjection } from '../projections/createMediaProjection'
import { createRichTextProjection } from '../projections/createRichTextProjection'
import { coalesceLocaleField } from '../helpers/coalesceLocaleField'
import { LocaleId } from '@aether/configs'

export const createSectionKlaviyoFormEmbedProjection = (localeId: LocaleId) => {
  const MEDIA_PROJECTION = createMediaProjection(localeId)
  const RICH_TEXT_PROJECTION = createRichTextProjection(localeId)

  return groq`{
        _key,
        _type,
        sectionSpaceSize,
        title,
        media${MEDIA_PROJECTION},
        ${coalesceLocaleField('content', localeId)}[]${RICH_TEXT_PROJECTION},
      }
  `
}
