import { fetchWithRetry, getLocaleRegionIdFromPath } from '@aether/utils'
import { gql } from 'graphql-request'
import { createShopifyClient } from '..'

type CollectionQueryRes = {
  collection: {
    id: string
    handle: string
    title: string
    description: string
    vipCollection?: { key: 'vip_collection'; value: string }
  } | null
  errors?: { message: string }[]
}

const COLLECTION_QUERY = gql`
  query ($id: ID!, $regionId: CountryCode!) @inContext(country: $regionId) {
    collection(id: $id) {
      id
      handle
      title
      description
      vipCollection: metafield(key: "vip_collection", namespace: "collection") {
        key
        value
      }
    }
  }
`

export const getShopifyCollectionDataById = async ({
  locale,
  id,
  isVip = false,
}: {
  locale: string
  id: string
  isVip: boolean
}): Promise<CollectionQueryRes['collection'] | null> => {
  const [regionId, localeId] = getLocaleRegionIdFromPath(locale)
  const shopifyClient = createShopifyClient(localeId)

  const collectionFetcher = async () =>
    await shopifyClient.request<CollectionQueryRes>(COLLECTION_QUERY, {
      id,
      regionId,
    })

  const collectionRes = await fetchWithRetry(collectionFetcher, 5)

  if (!isVip && collectionRes.collection?.vipCollection?.value === 'true')
    return null

  return collectionRes.collection
}
