import React, { FC, PropsWithChildren } from 'react'
import { SectionShopifyId } from '@aether/models'
import { Link } from '../Link/Link'
import { styled } from '@aether/styles'
import { useTranslation } from 'react-i18next'

const Wrap = styled('div', {
  position: 'relative',
  width: '100%',
  height: '100%',
})

const ButtonWrap = styled('div', {
  position: 'absolute',
  bottom: '$16',
  right: '$16',
})

export const ProductsModalMediaWrap: FC<
  PropsWithChildren<{
    shopifyIds?: SectionShopifyId[]
    appearance?: 'light' | 'dark'
  }>
> = ({ children, shopifyIds, appearance = 'light' }) => {
  const { t } = useTranslation('journal')
  if (!shopifyIds?.length) return <>{children}</>

  return (
    <Wrap>
      {children}
      <ButtonWrap>
        <Link
          action={{ type: 'productModalLinkAction', payload: shopifyIds }}
          appearance={
            appearance === 'light' ? 'badgeOutlineWhite' : 'badgeOutline'
          }
        >
          {t('shopStoryButton')}
        </Link>
      </ButtonWrap>
    </Wrap>
  )
}
