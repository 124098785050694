import React, { FC } from 'react'

type Props = {
  className?: string
  size?: 'default' | 'small'
}

export const SmallArrowLeft: FC<Props> = ({ className, size = 'default' }) => {
  const [width, height] = (() => {
    switch (size) {
      default:
        return [20, 14]
    }
  })()

  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 8 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.15 0.67897C7.241 0.67897 7.332 0.713972 7.395 0.783972C7.535 0.923971 7.535 1.14097 7.395 1.28097L1.648 7.02797L7.318 12.705C7.458 12.838 7.458 13.062 7.318 13.195C7.178 13.328 6.961 13.335 6.828 13.195L0.660999 7.02797L6.905 0.783972C6.975 0.713972 7.066 0.67897 7.15 0.67897Z"
        fill="black"
      />
    </svg>
  )
}
