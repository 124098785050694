import { LocaleId } from '@aether/configs'
import { groq } from 'next-sanity'
import { coalesceLocaleField } from '../helpers/coalesceLocaleField'
import { createIconImageProjection } from './createIconImageProjection'

export const createColorsMapProjection = (localeId: LocaleId) => {
  return groq`
      *[_type == "productColor"] {
          shopifyId,
          ${coalesceLocaleField('label', localeId)},
          withStroke,
          "image": Image${createIconImageProjection(localeId)}
      }`
}
