const HEADING_FONT = {
  fontFamily: 'var(--font-maison-neue-extended-demi), sans-serif',
  fontWeight: 500,
  lineHeight: 1.1,
}
const MAIN_FONT = {
  fontFamily: 'var(--font-maison-neue-book), sans-serif',
  fontWeight: 400,
  letterSpacing: '0.1em',
}

const MONO_FONT = {
  fontFamily: 'var(--font-maison-neue-mono-regular), sans-serif',
  fontWeight: 400,
}

export const fonts = {
  heading01: {
    ...HEADING_FONT,
    $lin: {
      fontSize: [36, 64],
    },
  },
  heading02: {
    ...HEADING_FONT,
    $lin: {
      fontSize: [36, 48],
    },
  },
  heading03: {
    ...HEADING_FONT,
    $lin: {
      fontSize: [32, 36],
    },
  },
  heading04: {
    ...HEADING_FONT,
    fontSize: 28,
  },
  heading05: {
    ...HEADING_FONT,
    fontSize: 22,
  },
  heading06: {
    ...HEADING_FONT,
    fontSize: 18,
  },
  heading06Caps: {
    ...HEADING_FONT,
    $lin: {
      fontSize: [18, 18],
    },
    textTransform: 'uppercase',
    lineHeight: 1.2,
  },
  heading07: {
    ...HEADING_FONT,
    fontSize: 14,
    letterSpacing: '0.1em',
    lineHeight: 1,
  },
  heading08: {
    ...HEADING_FONT,
    fontSize: 13,
    lineHeight: 1.8,
  },
  ui00: {
    ...MAIN_FONT,
    fontSize: 16,
    // lineHeight: '13px',
    textTransform: 'uppercase',
  },
  ui01: {
    ...MAIN_FONT,
    fontSize: 13,
    lineHeight: '13px',
    textTransform: 'uppercase',
  },
  ui02: {
    ...MAIN_FONT,
    fontSize: 12,
    lineHeight: '12px',
    textTransform: 'uppercase',
  },
  ui03: {
    ...MAIN_FONT,
    fontSize: 11,
    lineHeight: '11px',
    textTransform: 'uppercase',
  },
  ui03Heading: {
    ...HEADING_FONT,
    fontSize: 11,
    lineHeight: 1.1,
    textTransform: 'uppercase',
    letterSpacing: '2px',
  },
  ui04: {
    ...MAIN_FONT,
    fontSize: 10,
    lineHeight: '10px',
    textTransform: 'uppercase',
  },
  ui05: {
    ...MONO_FONT,
    $lin: {
      fontSize: [13, 12],
    },
    lineHeight: 1.3,
    textTransform: 'uppercase',
  },
  ui06: {
    ...MONO_FONT,
    fontSize: 10,
    lineHeight: '13px',
    letterSpacing: '0.05em',
    textTransform: 'uppercase',
  },
  ui07: {
    ...MONO_FONT,
    fontSize: 10,
    lineHeight: '13px',
    letterSpacing: '0.05em',
    textTransform: 'uppercase',
  },
  body01: {
    fontSize: 16,
    lineHeight: 1.8,
    ...MAIN_FONT,
  },
  body02: {
    ...MAIN_FONT,
    fontSize: 13,
    lineHeight: 1.8,
    '@md': {
      fontSize: 14,
    },
  },
  body03: {
    ...MAIN_FONT,
    fontSize: 12,
    lineHeight: 1.8,
  },
  body04: {
    ...MAIN_FONT,
    fontSize: 13,
    lineHeight: 1.8,
  },
}

export type FontType = keyof typeof fonts

export const getFontStyles = (fontType: FontType) => fonts[fontType]
