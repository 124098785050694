import { LocaleId } from '@aether/configs'
import { groq } from 'next-sanity'
import { coalesceLocaleField } from '../helpers/coalesceLocaleField'
import { createLinkProjection } from './createLinkProjection'

export const createProductGuaranteesProjection = (localeId: LocaleId) => {
  const LINK_PROJECTION = createLinkProjection(localeId)
  return groq`{
    terms[] {
      ${coalesceLocaleField('title', localeId)},
      ${coalesceLocaleField('description', localeId)},
      ${coalesceLocaleField('linkLabel', localeId)},
      link${LINK_PROJECTION}
     }
    }`
}
