import { styled } from '@aether/styles'
import React from 'react'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { type useShowOnScroll } from './useShowOnScroll'

type Props = {
  children?: React.ReactNode
  /**
   * Whether the button should be visible or not
   *
   * @see {@link useShowOnScroll} for automatic visibility while scrolling based on elements
   */
  visible?: boolean
}

const Sticky = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'fixed',
  margin: '0 auto',
  pointerEvents: 'none',
  width: 'fit-content',
  right: 0,
  left: 0,
  bottom: '$24',
  zIndex: '$stickyButton',
  transform: 'translateY(80px)',
  transition: 'transform 500ms ease-in-out',
  variants: {
    visible: {
      true: {
        transform: 'translateY(0)',
      },
    },
  },
})
const Wrapper = styled('div', {
  display: 'grid',
  color: 'white',
  pointerEvents: 'all',
  opacity: '0.85',
  width: 'fit-content',
  transition: 'opacity 0.3s ease-in-out',
  '&:hover': {
    opacity: '1',
  },
})
export const StickyWrapper = ({ children, visible }: Props) => {
  return (
    <Sticky visible={visible}>
      <Wrapper>{children}</Wrapper>
    </Sticky>
  )
}
