import { groq } from 'next-sanity'
import { LocaleId } from '@aether/configs'
import { createPageBuilderProjection } from './createPageBuilderProjection'
import { createIsHeaderStaticProjection } from './createIsHeaderStaticProjection'

export const createDefaultPreviewPageProjection = (localeId: LocaleId) => {
  const PAGE_BUILDER_PROJECTION = createPageBuilderProjection(localeId)
  const IS_HEADER_STATIC_PROJECTION = createIsHeaderStaticProjection()

  return groq`{
    hideHeader,
    hideFooter,
    title,
    ${IS_HEADER_STATIC_PROJECTION},
    pageBuilder[]${PAGE_BUILDER_PROJECTION},
  }`
}
