import {
  ShopifyProductBasicRes,
  ShopifyProductVariantRes,
  ShopifyProductVariant,
} from '@aether/models'
import { getLocaleRegionIdFromPath } from '@aether/utils'
import { gql } from 'graphql-request'
import { createShopifyClient } from '..'
import { PRODUCT_BASIC_FRAGMENT } from './fragments/productBasicFragment'
import { PRODUCT_VARIANT_FRAGMENT } from './fragments/productVariantFragment'
import { formatVariant } from './helpers/structureProductExtendedData'
import { isVipProduct } from './helpers/isVipProduct'
import { isVipVariant } from './helpers/isVipVariant'

const QUERY = gql`
  ${PRODUCT_BASIC_FRAGMENT}
  ${PRODUCT_VARIANT_FRAGMENT}

  query ($ids: [ID!]!, $regionId: CountryCode!) @inContext(country: $regionId) {
    nodes(ids: $ids) {
      ...ProductBasicFragment
      ...ProductVariantFragment
    }
  }
`

const getBaseProductsAndVariants = async (
  locale: string,
  ids: string[],
): Promise<Array<ShopifyProductBasicRes | ShopifyProductVariantRes>> => {
  const [regionId, localeId] = getLocaleRegionIdFromPath(locale)
  const shopifyClient = createShopifyClient(localeId)

  return await shopifyClient
    .request<{
      nodes: Array<ShopifyProductBasicRes | ShopifyProductVariantRes>
    }>(QUERY, {
      ids,
      regionId,
    })
    .then((res) => {
      return res.nodes
    })
}

const isShopifyProductRes = (
  item: Record<string, any>,
): item is ShopifyProductBasicRes => {
  if (!item) return false
  const SHOPIFY_PRODUCT_PREFIX = 'gid://shopify/Product/'
  return item.id.startsWith(SHOPIFY_PRODUCT_PREFIX)
}

const isShopifyProductVariantRes = (
  item: Record<string, any>,
): item is ShopifyProductVariantRes => {
  if (!item) return false
  const SHOPIFY_PRODUCT_VARIANT_PREFIX = 'gid://shopify/ProductVariant/'
  return item.id.startsWith(SHOPIFY_PRODUCT_VARIANT_PREFIX)
}

export const getShopifyBaseProductsAndVariants = async (
  locale: string,
  ids: string[],
  isVip = false,
): Promise<{
  products: ShopifyProductBasicRes[]
  variants: Record<string, ShopifyProductVariant>
}> => {
  if (ids.length === 0) {
    return {
      products: [],
      variants: {},
    }
  }
  const baseProductsAndVariantsRes = await getBaseProductsAndVariants(
    locale,
    ids,
  )
  const products = baseProductsAndVariantsRes
    .filter(isShopifyProductRes)
    .filter((product) => (isVip ? product : !isVipProduct(product)))
  const variantsNodes = baseProductsAndVariantsRes
    .filter(isShopifyProductVariantRes)
    .filter((product) => (isVip ? product : !isVipVariant(product)))

  const variants = variantsNodes.map(formatVariant).reduce((acc, val) => {
    return {
      ...acc,
      [val.id]: val,
    }
  }, {})

  return {
    products,
    variants,
  }
}
