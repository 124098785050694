import type { PageBuilderSection } from '@aether/models'
import identity from 'lodash.identity'
import { toPlainText } from '../toPlainText'

const disallowedFieldTypes = ['image', 'media']

export const extractTextFromSection = (section: PageBuilderSection): string => {
  if (disallowedFieldTypes.includes(section._type)) {
    return ''
  }

  if (['localeString', 'localeText'].includes(section._type)) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return section.en
  }

  if (['localeRichText'].includes(section._type)) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return toPlainText(section.en)
  }

  return Object.entries(section)
    .reduce((accumulator: string[], [_key, value]) => {
      if (typeof value === 'object') {
        return [...accumulator, extractTextFromSection(value)]
      }
      return accumulator
    }, [])
    .filter(identity)
    .join('\n\n')
}
