import { FC } from 'react'
import { HowToMeasureType, MediaFieldType } from '@aether/models'
import { styled } from '@aether/styles'
import { Media } from '../Media/Media'

export type HowToMeasureProps = {
  howToMeasure: HowToMeasureType[] | undefined
  image: MediaFieldType | undefined
  title: string | undefined
}

const StyledSizeGuideTitle = styled('h2', {
  $aetherFont: 'heading05',
  margin: '$36 0',
})

const StyledWrapper = styled('div', {
  display: 'grid',
  gap: '$56',

  '@md': {
    gridTemplateColumns: '1fr 2fr',
    gap: '$64',
  },
})

const StyledCards = styled('div', {
  display: 'grid',
  gap: '$24',

  '@md': {
    gridRow: '1/2',
  },
})

const StyledTitleWrap = styled('div', {
  display: 'grid',
  gridTemplateColumns: 'min-content max-content',
  gap: '$8',
  marginBottom: '$12',
})

const StyledTitle = styled('h3', {
  $aetherFont: 'heading07',
  display: 'flex',
  alignItems: 'center',
})

const StyledCounter = styled('div', {
  $aetherFont: 'ui06',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '$28',
  height: '$20',
  color: '$white',
  backgroundColor: '$black',
  borderRadius: '10px',
})

const StyledDescription = styled('p', {
  $aetherFont: 'body03',
})

export const HowToMeasure: FC<HowToMeasureProps> = ({
  howToMeasure,
  image,
  title,
}) => {
  return (
    <>
      <StyledSizeGuideTitle>{title}</StyledSizeGuideTitle>
      <StyledWrapper>
        <Media {...image} sizes={{ md: '450px' }} />
        <StyledCards>
          {howToMeasure &&
            howToMeasure.map((item, index) => (
              <div key={`${index} - ${item.description}`}>
                <StyledTitleWrap>
                  <StyledCounter>{index + 1}</StyledCounter>
                  <StyledTitle>{item.title}</StyledTitle>
                </StyledTitleWrap>

                <StyledDescription>{item.description}</StyledDescription>
              </div>
            ))}
        </StyledCards>
      </StyledWrapper>
    </>
  )
}
