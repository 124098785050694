import { groq } from 'next-sanity'
import { DEFAULT_LOCALE_ID, LocaleId } from '@aether/configs'

export const createSectionShopifyComplimentaryProductsProjection = (
  localeId: LocaleId,
) => {
  return groq`{
        _key,
        _type,
        "title": coalesce(title.${localeId}, title.${DEFAULT_LOCALE_ID}, "Pairs Well With"),
        "pageConext":"default",
      }
  `
}
