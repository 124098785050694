import { coalesceLocaleField } from '../helpers/coalesceLocaleField'
import { createMediaProjection } from '../projections/createMediaProjection'
import { groq } from 'next-sanity'
import { LocaleId } from '@aether/configs'

export const createSectionSingleImageWithDoubleTextProjection = (
  localeId: LocaleId,
) => {
  const MEDIA_PROJECTION = createMediaProjection(localeId)

  return groq`{
        _key,
        _type,
        ${coalesceLocaleField('bigText', localeId)},
        ${coalesceLocaleField('smallText', localeId)},
        media${MEDIA_PROJECTION},
        backgroundColor,
        textPosition,
      }
  `
}
