import { getLocaleRegionIdFromPath } from '@aether/utils'
import { groq } from 'next-sanity'
import { getClient } from '../getClient'
import { PageResponse } from '@aether/models'
import { PageBuilderSection } from '@aether/models'
import { createMediaProjection } from '../projections/createMediaProjection'
import { coalesceLocaleField } from '../helpers/coalesceLocaleField'
import { createCollectionNavigationProjection } from '../projections/createCollectionNavigationProjection'
import { createRichTextProjection } from '../projections/createRichTextProjection'
import { createSanityColorProjection } from '../projections/createSanityColorProjecton'

export const getFilteredCollectionPageBySlug = async (
  slug: string,
  locale: string,
  preview = false,
): Promise<Omit<PageResponse<PageBuilderSection[]>, 'siteConfiguration'>> => {
  const [, localeId] = getLocaleRegionIdFromPath(locale)
  const MEDIA_PROJECTION = createMediaProjection(localeId)
  const SEO_PROJECTION = groq`{
    "ogImage": select(defined(background.image) => background${MEDIA_PROJECTION}, null),
    "ogAltImage": select(defined(background.image) && defined(background.alt) => {
      ${coalesceLocaleField('alt', localeId)},
    }.alt, null)
  }
 `
  const COLLECTION_NAVIGATION_PROJECTION =
    createCollectionNavigationProjection(localeId)

  const BLOCK_CONTENT_PROJECTION = createRichTextProjection(localeId)
  const SANITY_COLOR_PROJECTION = createSanityColorProjection()

  const query = groq`
   *[
      !(_id in path('drafts.**')) &&
        _type == "collection"  &&
        store.slug.current == "${slug}"
    ][0]{
      "seo": ${SEO_PROJECTION},
      "hideHeader": false,
      "hideFooter": false,
      "pageBuilder": [
        {
          "_type": "sectionCollectionHero",
          "_key": "sectionCollectionHero" + _id,
          background${MEDIA_PROJECTION},
          theme,
          ${coalesceLocaleField('title', localeId)},
          splitHeaderTheme,
          ${coalesceLocaleField(
            'splitHeaderText',
            localeId,
          )}[]${BLOCK_CONTENT_PROJECTION},
        splitHeaderBackgroundColor${SANITY_COLOR_PROJECTION},
        },
        {
          "_type": "sectionCollectionFiltering",
          "_key": "sectionCollectionFiltering" + _id,
          "context": "filterPage",
          "navigationLinks": navigationLinks -> ${COLLECTION_NAVIGATION_PROJECTION},
        },
      ],
    }
  `
  return await getClient(preview).fetch(query)
}
