export const ROUTE_BASE_PRODUCTS = '/products'
export const ROUTE_BASE_COLLECTIONS = '/collections'
export const ROUTE_BASE_JOURNAL = '/journal'
export const ROUTE_BASE_JOURNAL_CATEGORIES = '/journal/categories'
export const ROUTE_FILTERED_COLLECTIONS = '/f'
export const ROUTE_BASE_ACCOUNT_HOME = '/account'
export const ROUTE_BASE_ACCOUNT_LOGIN = '/account/login'
export const ROUTE_BASE_ACCOUNT_FORGOT_PASSWORD = '/account/forgot'
export const ROUTE_BASE_ACCOUNT_RESET_PASSWORD = '/account/reset'
export const ROUTE_BASE_ACCOUNT_ACTIVATE = '/account/activate'
export const ROUTE_BASE_SEARCH = '/search'
export const ROUTE_BASE_STORES = '/stores'
export const ROUTE_BASE_CART = '/cart'
export const ROUTE_PRIVACY_POLICY = '/privacy'
