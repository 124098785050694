import { createPageBuilderProjection } from './createPageBuilderProjection'
import { groq } from 'next-sanity'
import { LocaleId } from '@aether/configs'
import { coalesceLocaleField } from '../helpers/coalesceLocaleField'
import { createIsHeaderStaticProjection } from './createIsHeaderStaticProjection'
import { createSEOProjection } from './createSEOProjection'

export const createUtilityPageProjection = (localeId: LocaleId) => {
  const PAGE_BUILDER_PROJECTION = createPageBuilderProjection(localeId)
  const IS_HEADER_STATIC_PROJECTION = createIsHeaderStaticProjection()
  const SEO_PROJECTION = createSEOProjection(localeId)

  return groq`{
      _id,
      "type": _type,
      "hideHeader": false,
      "hideFooter": false,
      "seo": seo${SEO_PROJECTION},
      ${IS_HEADER_STATIC_PROJECTION},
      ${coalesceLocaleField('title', localeId)},
      "sidebar":{
        "links" : *[ !(_id in path('drafts.**')) && _type == "utilityPage" && hidden!=true]|order(orderRank) []{
        "path" : path.current,
        ${coalesceLocaleField('title', localeId)},
       },
    },
       "pageBuilder": [
        {
            "_type": "sectionPageTitle",
            "_key": "sectionPageTitle" + _id,
            ${coalesceLocaleField('title', localeId)},
        },
      ...pageBuilder[]${PAGE_BUILDER_PROJECTION}
      ],
    }
  `
}
