import { coalesceLocaleField } from '../helpers/coalesceLocaleField'
import { groq } from 'next-sanity'
import { LocaleId } from '@aether/configs'
import { createMediaProjection } from './createMediaProjection'

export const createCategoriesPostsPreviewsProjection = (localeId: LocaleId) => {
  const MEDIA_PROJECTION = createMediaProjection(localeId)

  return groq`
  *[ !(_id in path('drafts.**')) && _type == "journalCategory"]|order(orderRank) {
     ${coalesceLocaleField('title', localeId)},
     "path": path.current,
     "postsCount": count(*[ !(_id in path('drafts.**')) && _type == "journalPost" && ^._id in categories[]._ref]),
     "posts": *[ !(_id in path('drafts.**')) && _type == "journalPost" && ^._id in categories[]._ref ][0..2] | order(date desc) {
      ${coalesceLocaleField('title', localeId)},
       "image": background${MEDIA_PROJECTION},
       "path": path.current
     }
  }`
}
