import { styled } from '@aether/styles'
import React from 'react'

const Wrapper = styled('button', {
  height: 44,
  width: 44,
  padding: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  background: 'transparent',
  $focusStyle: 'default',
})

const QuantityOval = styled('span', {
  margin: 0,
  padding: 0,
  height: '$24',
  width: '$24',
  border: '1px solid $black',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '$rMax',
  $aetherFont: 'ui05',
  color: '$black',
  variants: {
    black: {
      true: {
        background: '$black',
        color: '$white',
      },
    },
  },
})

export const Quantity: React.FC<{
  onClick: () => void
  quantity: number
}> = ({ onClick, quantity }) => {
  return (
    <Wrapper
      aria-label={`Open cart with ${quantity} items`}
      onClick={(e) => {
        e.preventDefault()
        onClick()
      }}
    >
      <QuantityOval black={quantity > 0}>{quantity}</QuantityOval>
    </Wrapper>
  )
}
