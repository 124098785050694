import { DEFAULT_LOCALE_ID, LocaleId, ShopifyInstanceId } from '@aether/configs'
import { GraphQLClient } from 'graphql-request'
import {
  shopifyConfig,
  SHOPIFY_STOREFRONT_API_VERSION,
} from '@aether/shopify-sdk'

export function createShopifyClient(
  language: LocaleId = DEFAULT_LOCALE_ID,
  region?: ShopifyInstanceId,
) {
  let ACCESS_TOKEN, URL

  switch (region) {
    default: {
      ACCESS_TOKEN = shopifyConfig.accessToken
      URL = `${shopifyConfig.domain}/api/${SHOPIFY_STOREFRONT_API_VERSION}/graphql.json`
      break
    }
  }
  if (!(URL && ACCESS_TOKEN)) {
    const message =
      '[createShopifyClient] Please setup env variables for the project'
    throw new Error(message)
  }

  return new GraphQLClient(URL, {
    headers: {
      'Content-Type': 'application/json',
      'X-Shopify-Storefront-Access-Token': ACCESS_TOKEN,
      'Accept-Language': language,
    },
  })
}
