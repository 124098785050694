export const MediumCheckIcon = () => (
  <svg
    width="15"
    height="15"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.00433 0C3.59113 0 0 3.59113 0 8.00433C0 12.4175 3.59113 16.0087 8.00433 16.0087C12.4175 16.0087 16.0087 12.4175 16.0087 8.00433C16.0087 3.59113 12.4089 0 8.00433 0ZM11.5349 5.9881L7.45051 11.5608L4.00649 8.67063C3.82477 8.51487 3.79881 8.24662 3.95457 8.0649C4.11033 7.88318 4.37858 7.85722 4.5603 8.01298L7.30341 10.3061L10.8426 5.47756C10.9811 5.28718 11.258 5.24392 11.4483 5.38237C11.6387 5.52082 11.682 5.79773 11.5435 5.9881H11.5349Z"
      fill="black"
    />
  </svg>
)
