export const focus = {
  default: {
    '&:focus-visible': {
      outline: '$blue solid 3px',
      outlineOffset: '2px',
    },
  },
  negativeOffset: {
    '&:focus-visible': {
      outline: '$blue solid 3px',
      outlineOffset: '-3px',
    },
  },
}

export type FocusType = keyof typeof focus

export const getFocusStyles = (focusType: FocusType) => focus[focusType]
