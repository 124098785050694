import { FC, useEffect, useRef } from 'react'
import {
  SanityImageMediaPayload,
  ShopifyImageMediaPayload,
} from '@aether/models'
import { styled } from '@aether/styles'
import { Media } from '../Media/Media'
import { InView } from 'react-intersection-observer'

type FullWidthImageSliderProps = {
  images: (SanityImageMediaPayload | ShopifyImageMediaPayload)[]
  onSlideChange: (index: number) => void
  spacing?: 'none' | 'medium'
  priority?: boolean
  /**
   * @default true
   * @description
   * If `false`, the slider will not be scrollable.
   *
   * This prop was added to fix the issue https://commerceui.atlassian.net/browse/AETR-594.
   * Each product item is rendering a slider element that his its own composition layer.
   * On iOS this is causing a memory issue and the page is crashing after enough product items are rendered.
   * To prevent this, we are adding this prop to disable the scroll on the slider until its needed ex.
   * until it's visible on the screen.
   */
  isEnabled?: boolean
  onClick?: () => void
}

const Slider = styled('ul', {
  width: '100vw',
  overflowX: 'hidden',
  overflowY: 'auto',
  display: 'flex',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  variants: {
    spacing: {
      medium: {
        $lin: {
          scrollPaddingLeft: 'CONTAINER_SPACE_M',
        },
      },
      none: {},
    },
    isEnabled: {
      true: {
        overflowX: 'scroll',
        scrollSnapType: 'x mandatory',
        scrollBehavior: 'smooth',
      },
    },
  },
})

const Slide = styled('li', {
  flexFlow: 'column',
  minWidth: 'auto',
  marginRight: '$12',
  scrollSnapAlign: 'start',
  variants: {
    spacing: {
      medium: {
        flex: '0 0 85%',
      },
      none: {
        flex: '0 0 100%',
      },
    },
  },
})

const Spacer = styled('li', {
  variants: {
    spacing: {
      medium: {
        $lin: {
          paddingLeft: 'CONTAINER_SPACE_M',
        },
      },
      none: {},
    },
  },
})

export const FullWidthImageSlider: FC<FullWidthImageSliderProps> = ({
  images,
  onSlideChange,
  spacing = 'none',
  priority = false,
  isEnabled = true,
  onClick,
}) => {
  const sliderRef = useRef<HTMLUListElement | null>(null)
  const sliderEl = sliderRef.current

  useEffect(() => {
    onSlideChange(0)
    if (sliderEl) {
      sliderEl.scroll({
        top: 0,
        left: 0,
      })
    }
  }, [JSON.stringify(images)])

  return (
    <Slider
      spacing={spacing}
      isEnabled={isEnabled}
      ref={sliderRef}
      onClick={onClick}
    >
      <Spacer spacing={spacing} />
      {images.map((img, index) => (
        <Slide key={`image-${index}`} spacing={spacing}>
          <InView
            as="div"
            threshold={0.5}
            onChange={(inView) => {
              inView && onSlideChange(index)
            }}
          >
            <Media
              mediaPayload={img}
              hardcropRatio="portrait1"
              priority={priority && index === 0}
              sizes={{ lg: '0px', default: '100vw' }}
              withBackground
            />
          </InView>
        </Slide>
      ))}
      <Spacer spacing={spacing} />
    </Slider>
  )
}
