import { groq } from 'next-sanity'
import { LocaleId } from '@aether/configs'
import { createScatteredGalleryItemProjection } from '../projections/createScatteredGalleryItemProjection'

export const createSectionScatteredGalleryProjection = (localeId: LocaleId) => {
  const SCATTERED_GALLERY_ITEM_PROJECTION =
    createScatteredGalleryItemProjection(localeId)
  return groq`{
        _key,
        _type,
        sectionSpaceSize,
        leftColumn{
          items[] ${SCATTERED_GALLERY_ITEM_PROJECTION},
          verticalPosition
        },
        rightColumn{
          items[] ${SCATTERED_GALLERY_ITEM_PROJECTION},
          verticalPosition
        },
  }
  `
}
