import { getLocaleRegionIdFromPath } from '@aether/utils'
import { createShopifyClient } from './createShopifyClient'
import { gql } from 'graphql-request'
import { ShopifyCollectionRes } from '@aether/models'

const QUERY = gql`
  fragment CollectionFragment on Collection {
    id
    title
    handle
    breadcrumbTitle: metafield(
      key: "breadcrumb_title"
      namespace: "collection"
    ) {
      key
      value
    }
  }

  query ($ids: [ID!]!, $regionId: CountryCode!) @inContext(country: $regionId) {
    nodes(ids: $ids) {
      ...CollectionFragment
    }
  }
`
export const getShopifyCollectionsData = async ({
  locale,
  ids,
}: {
  locale: string
  ids: string[]
}) => {
  const [regionId, localeId] = getLocaleRegionIdFromPath(locale)
  const shopifyClient = createShopifyClient(localeId)

  return await shopifyClient
    .request<{ nodes?: ShopifyCollectionRes[] }>(QUERY, {
      ids,
      regionId,
    })
    .then((res) => {
      return res.nodes
    })
}
