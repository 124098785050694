import { coalesceLocaleField } from '../helpers/coalesceLocaleField'
import { groq } from 'next-sanity'
import { LocaleId } from '@aether/configs'
import { createMediaProjection } from '../projections/createMediaProjection'
import { createLinkProjection } from '../projections/createLinkProjection'

export const createSectionImagesWithDescriptionProjection = (
  localeId: LocaleId,
) => {
  const MEDIA_PROJECTION = createMediaProjection(localeId)
  const LINK_PROJECTION = createLinkProjection(localeId)
  return groq`{
        _key,
        _type,
        ${coalesceLocaleField('title', localeId)},
        sectionSpaceSize,
        borderPosition,
        sectionColor,
        cards[] {
          ${coalesceLocaleField('title', localeId)},
          ${coalesceLocaleField('text', localeId)},
          image${MEDIA_PROJECTION},
          ${coalesceLocaleField('ctaLabel', localeId)},
          ctaLink${LINK_PROJECTION},
      }
      }
  `
}
