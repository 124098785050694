import { groq } from 'next-sanity'

import { LocaleId } from '@aether/configs'
import { coalesceLocaleField } from '../helpers/coalesceLocaleField'

export const createJournalCategoriesProjection = (
  localeId: LocaleId,
) => groq`*[ !(_id in path('drafts.**')) && _type == "journalCategory"]|order(orderRank) {
     ${coalesceLocaleField('title', localeId)},
     "path": path.current,
  }`
