import React from 'react'

type QuestionMarkProps = {
  fill?: string
}

export const QuestionMark: React.FC<QuestionMarkProps> = ({
  fill = 'black',
}) => {
  return (
    <svg
      width=""
      height="9"
      viewBox="0 0 4 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.37 6.1H1.56V5.46C1.56 4.86 1.87 4.42 2.54 4.03C3.29 3.6 3.59 3.23 3.59 2.67C3.59 2.05 3.07 1.63 2.41 1.63C1.39 1.63 0.9 2.21 0.83 3.13L0.01 3.09C0.04 1.8 0.95 0.85 2.41 0.85C3.48 0.85 4.44 1.59 4.44 2.68C4.44 3.54 3.95 4.07 3 4.61C2.52 4.89 2.37 5.11 2.37 5.53V6.1ZM2.57 8H1.37V6.83H2.57V8Z"
        fill={fill}
      />
    </svg>
  )
}
