import { ShopifyPrice, ShopifyProductVariant } from '@aether/models'

export function calculateCompareAtPrice(
  productVariant: ShopifyProductVariant,
  discount?: number,
): ShopifyPrice {
  const { price, compareAtPrice } = productVariant

  if (discount) {
    if (compareAtPrice) {
      return compareAtPrice
    } else {
      return price
    }
  }

  if (compareAtPrice) {
    return compareAtPrice
  }

  return price
}
