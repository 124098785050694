import { coalesceLocaleField } from '../helpers/coalesceLocaleField'
import { groq } from 'next-sanity'
import { LocaleId } from '@aether/configs'
import { createLinkProjection } from '../projections/createLinkProjection'
import { createMediaProjection } from '../projections/createMediaProjection'
import { createSanityColorProjection } from '../projections/createSanityColorProjecton'
import { createRichTextProjection } from '../projections/createRichTextProjection'

export const createSectionDoubleMediaProjection = (localeId: LocaleId) => {
  const LINK_PROJECTION = createLinkProjection(localeId)
  const MEDIA_PROJECTION = createMediaProjection(localeId)
  const SANITY_COLOR_PROJECTION = createSanityColorProjection()
  const RICH_TEXT_PROJECTION = createRichTextProjection(localeId)

  return groq`{
    _key,
    _type,
    sectionSpaceSize,
    sections[]{
      border,
      appearance,
      ${coalesceLocaleField('title', localeId)},
      ${coalesceLocaleField('caption', localeId)}[]${RICH_TEXT_PROJECTION},
      link {
        ${coalesceLocaleField('ctaLabel', localeId)},
        ctaAppearance,
        ctaLink${LINK_PROJECTION},
      },
      "background": select(
      backgroundType == 'color' => {
          "type": "color",
          "payload": color${SANITY_COLOR_PROJECTION}
      },
      backgroundType == 'media' => {
          "type": "media",
          "payload": media${MEDIA_PROJECTION}
      },
      {
        "type": "unknown",
        "payload": null
      }
    )
    }

  }`
}
