import {
  ShopifyMoneyFieldsFragment,
  ShopifyResolvedCartLine,
} from '@aether/shopify-sdk'
import { FC } from 'react'

import { PriceCompare } from '../components/PriceCompare/PriceCompare'

const getCartLineCompareAtPrice = (
  line: ShopifyResolvedCartLine,
): ShopifyMoneyFieldsFragment | undefined => {
  const {
    merchandise: { compareAtPrice },
    quantity,
    cost: { totalAmount, subtotalAmount },
  } = line

  // show discounted price if the discount is applied
  if (subtotalAmount && subtotalAmount.amount !== totalAmount.amount) {
    return subtotalAmount
  }

  // show compareAt price if set on variant
  if (compareAtPrice) {
    return {
      // we have only compareAt price per variant, so we multiply by quantity
      amount: (parseFloat(compareAtPrice.amount) * quantity).toString(),
      currencyCode: compareAtPrice.currencyCode,
    }
  }

  return undefined
}

// TODO: to be modified once Shopify API version is updated > 2022-04
export const ProductCartLinePrice: FC<{
  line: ShopifyResolvedCartLine
}> = ({ line }) => {
  const {
    cost: { totalAmount },
  } = line

  const priceToCompare = getCartLineCompareAtPrice(line)

  return <PriceCompare price={totalAmount} compareAtPrice={priceToCompare} />
}
