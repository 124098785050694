import { ShopifyProduct } from '@aether/models'

export type ProductsMetafield =
  | 'colorGroup'
  | 'techFeatures'
  | 'temperature'
  | 'typeOfProduct'
  | 'activity'
  | 'sizeGuide'
  | 'secondaryDescription'
  | 'complementaryProducts'
  | 'pdpType'
  | 'performanceWindproof'
  | 'performanceWaterproof'
  | 'performanceInsulation'
  | 'performancePowderRating'
  | 'performanceMotoRating'
  | 'performanceMovementRating'
  | 'performanceTemperatureRange'

export const getProductMetafieldValues = (
  metafield: ProductsMetafield,
  product: ShopifyProduct | null,
): string[] => {
  if (!product) {
    return []
  }
  const metafieldValue = product[metafield]?.value
  if (!metafieldValue) {
    return []
  }
  return metafieldValue.includes('[')
    ? JSON.parse(metafieldValue)
    : [metafieldValue]
}
