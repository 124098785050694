import { Action, SectionShopifyId, ShopifyProduct } from '@aether/models'
import { forwardRef, PropsWithChildren, useState } from 'react'
import { captureException } from '@sentry/nextjs'
import { useChatAppContext } from '@aether/chat-app'
import { ProductsModal } from './components/ProductsModal'
import { getShopifySingleProductsData } from '@aether/services/shopify-service'
import { useRouter } from 'next/router'
import { DEFAULT_LOCALE_ID } from '@aether/configs'

type ActionProps = {
  onClick?: () => void
}

type LinkActionProps2 = PropsWithChildren<{
  action: Action
  renderButton: (actionProps: ActionProps) => JSX.Element
}>

const getShopifyData = async (locale: string, products: SectionShopifyId[]) => {
  const productIds = (products || []).map((product) => product?.id)
  const shopifyData = await getShopifySingleProductsData(
    locale,
    productIds,
    false,
  )

  const allProducts = shopifyData?.products || {}
  return productIds.map((id) => allProducts[id]).filter((p) => Boolean(p))
}

export const LinkAction = forwardRef<any, LinkActionProps2>(
  ({ action, renderButton }) => {
    const router = useRouter()
    const { openChatApp } = useChatAppContext()

    const [shopifyProducts, setShopifyProducts] = useState<ShopifyProduct[]>([])
    const [isProductsModalOpen, setIsProductsModalOpen] = useState(false)

    switch (action.type) {
      case 'productModalLinkAction': {
        const actionProps = {
          onClick: async () => {
            if (!shopifyProducts?.length) {
              const shopifyData = await getShopifyData(
                router.locale || DEFAULT_LOCALE_ID,
                action?.payload,
              )

              setShopifyProducts(shopifyData)
            }

            setIsProductsModalOpen(true)
          },
        }

        return (
          <>
            {renderButton(actionProps)}
            <ProductsModal
              isModalOpen={isProductsModalOpen}
              handleCloseModal={() => setIsProductsModalOpen(false)}
              products={shopifyProducts}
            />
          </>
        )
      }
      case 'productSectionLinkAction': {
        const actionProps = {
          onClick: () => {
            const sectionShopTheProducts = document.getElementById(
              'sectionShopTheProducts',
            )
            sectionShopTheProducts?.scrollIntoView()
          },
        }
        return <>{renderButton(actionProps)}</>
      }
      case 'sectionTechnicalSpecificationsLinkAction': {
        const actionProps = {
          onClick: () => {
            const sectionTechnicalSpecifications = document.getElementById(
              'sectionTechnicalSpecifications',
            )
            sectionTechnicalSpecifications?.scrollIntoView({
              behavior: 'smooth',
            })
          },
        }
        return <>{renderButton(actionProps)}</>
      }
      case 'chatAppLinkAction': {
        const actionProps = {
          onClick: () => openChatApp(),
        }
        return <>{renderButton(actionProps)}</>
      }
      default: {
        captureException(
          new Error(
            `[LinkAction] There is no defined actionType${
              (action as Action)?.type && `: ${(action as Action).type}`
            }`,
          ),
        )
        const actionProps = {}
        return <>{renderButton(actionProps)}</>
      }
    }
  },
)
