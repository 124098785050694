import React, { ComponentPropsWithRef } from 'react'

import { styled } from '@aether/styles'
import {
  ModalContentPosition,
  ModalContentSize,
  ModalContentVariant,
  ModalTransition,
} from './Modal'

const ContentStyled = styled('div', {
  position: 'relative',
  border: 'none',
  maxWidth: '100%',
  variants: {
    position: {
      center: {
        margin: 'auto',
      },
      bottom: {
        margin: 'auto auto 0',
      },
      right: {
        margin: 'auto 0 auto auto',
      },
      left: {
        margin: 'auto auto auto 0',
      },
      top: {
        margin: '0 auto auto auto',
      },
      top100: {
        margin: '100px auto auto auto',
      },
    },
    size: {
      intrinsic: {
        overflowY: 'auto',
      },
      stretch: {
        width: '100%',
        height: '100%',
        overflowY: 'auto',
      },
      'stretch-x': {
        width: '100%',
      },
      'stretch-y': {
        height: '100%',
        overflowX: 'hidden',
        overflowY: 'auto',
      },
    },
    variant: {
      default: {
        background: 'white',
      },
      'white-rounded': {
        borderRadius: '$r2',
        background: 'white',
      },
      rounded: {
        borderRadius: '$r2',
      },
    },
    transition: {
      'slide-from-right': {
        [`&.base`]: {
          transform: 'translateX(100%)',
        },
        [`&.after-open`]: {
          transform: 'none',
        },
        [`&.before-close`]: {
          transform: 'translateX(100%)',
        },
      },
      'slide-from-top': {
        [`&.base`]: {
          transform: 'translateY(-100%)',
        },
        [`&.after-open`]: {
          transform: 'none',
        },
        [`&.before-close`]: {
          transform: 'translateY(-100%)',
        },
      },
      fade: {
        [`&.base`]: {
          opacity: 0,
        },
        [`&.after-open`]: {
          opacity: 1,
        },
        [`&.before-close`]: {
          opacity: 0,
        },
      },
    },
  },
})

type ContentProps = ComponentPropsWithRef<'div'> & {
  onRequestClose?: (event: React.MouseEvent | React.KeyboardEvent) => void
  position?: ModalContentPosition
  variant?: ModalContentVariant
  size?: ModalContentSize
  transition: ModalTransition
  openTime: number
  closeTime: number
}

export const Content = React.forwardRef<HTMLDivElement, ContentProps>(
  (
    {
      children,
      openTime,
      closeTime,
      transition,
      variant,
      position,
      size,
      onRequestClose,
      ...restProps
    },
    ref,
  ) => {
    const stylingProps = {
      variant,
      position,
      size,
      transition,
    }
    return (
      <ContentStyled
        {...restProps}
        ref={ref}
        css={{
          '&.base': {
            transition: `all ${openTime}ms cubic-bezier(.2,.4,.2,1)`,
          },
          '&.before-close': {
            transition: `all ${closeTime}ms cubic-bezier(.2,.4,.2,1)`,
          },
        }}
        {...stylingProps}
      >
        {children}
      </ContentStyled>
    )
  },
)

Content.displayName = 'Content'
