import { coalesceLocaleField } from '../helpers/coalesceLocaleField'
import { createLinkProjection } from '../projections/createLinkProjection'
import { groq } from 'next-sanity'
import { LocaleId } from '@aether/configs'

export const createSectionBoldTextProjection = (localeId: LocaleId) => {
  const LINK_PROJECTION = createLinkProjection(localeId)

  return groq`{
        _key,
        _type,
        ${coalesceLocaleField('text', localeId)},
        ctaLink${LINK_PROJECTION},
        ${coalesceLocaleField('ctaLabel', localeId)},
        secondaryCtaLink${LINK_PROJECTION},
        ${coalesceLocaleField('secondaryCtaLabel', localeId)},
      }
  `
}
