import { coalesceLocaleField } from '../helpers/coalesceLocaleField'
import { groq } from 'next-sanity'
import { LocaleId } from '@aether/configs'

export const createSectionJournalHeaderProjection = (localeId: LocaleId) => {
  return groq`{
        _key,
        _type,
        ${coalesceLocaleField('title', localeId)},
      }
  `
}
