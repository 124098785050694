import { sleep } from './sleep'

type WithRetry = <T>(
  /** Function to retry */
  fn: () => Promise<T>,
  /** Number of retries */
  retriesLeft: number,
  attemptNumber?: number,
  errorsStack?: Array<Error>,
) => Promise<T>

export const fetchWithRetry: WithRetry = async (
  fn,
  retriesLeft,
  attemptNumber = 1,
  errors,
) => {
  const onError = (err: Error) => {
    if (retriesLeft === 0) throw new Error(JSON.stringify(err))
    return sleep(attemptNumber * 1000).then(() =>
      fetchWithRetry(fn, retriesLeft - 1, attemptNumber + 1, errors),
    )
  }

  return await fn().catch(onError)
}
