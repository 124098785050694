import { useEffect, useState } from 'react'
import { ParsedUrlQuery } from 'querystring'

import { useRouter } from 'next/router'
import { createReturnsCart } from '@aether/services/api-service'
import { ShopifyResolvedCart } from '@aether/shopify-sdk'

// models
/**
 * When a customer is dropped onto your store from the app, there will be multiple parameters in the link address.
 * You will then need to URI Decode these parameters and use them for your on-store experience. The parameters are as follows:
 * https://docs.loopreturns.com/reference/getting-started-with-cart-api
 */
type LoopQuery = Partial<{
  //a UUID that is unique per order lookup
  loop_return_id: string
  // the currency the original order was made in
  loop_currency: string
  // total credit after tax in cents
  loop_total: number
  // total credit without tax
  loop_base: number
  // amount of bonus credit
  loop_credit: number
  // the domain the user came from
  loop_domain: string
  // Loop's subdomain for you
  loop_subdomain: string
  // redirect link back to the app
  loop_redirect_url: string
  // name of customer on the original order
  loop_customer_name: string
}>

export type ReturnsState = Partial<{
  id: string
  currency: string
  total: number
  base: number
  credit: number
  domainFrom: string
  subdomain: string
  redirectUrl: string
  customerName: string
}>

const formatTotalPrice = (loopTotal?: unknown): number | undefined => {
  const convertedTotal = Number(loopTotal)

  // TODO: improve valid number check
  if (!loopTotal || Number.isNaN(convertedTotal)) {
    return undefined
  }

  return Number(convertedTotal) / 100
}

const formatUrl = (url?: unknown): string | undefined => {
  if (!url || typeof url !== 'string') {
    return undefined
  }

  const pattern = /^((http|https|ftp):\/\/)/

  if (!pattern.test(url)) {
    return 'https://' + url
  }

  return url
}

const formatReturnsState = ({
  loop_return_id,
  loop_credit,
  loop_currency,
  loop_customer_name,
  loop_redirect_url,
  loop_subdomain,
  loop_domain,
  loop_total,
  loop_base,
}: LoopQuery): ReturnsState => ({
  id: loop_return_id,
  currency: loop_currency,
  total: formatTotalPrice(loop_total),
  base: loop_base,
  credit: loop_credit,
  domainFrom: loop_domain,
  subdomain: loop_subdomain,
  redirectUrl: formatUrl(loop_redirect_url),
  customerName: loop_customer_name,
})

// utilities
const LOOP_TOTAL_QUERY_PARAM = 'loop_total'
const LOOP_QUERY_PARAM_PREFIX = 'loop'

const isLoopQuery = (query: ParsedUrlQuery): boolean => {
  return Object.keys(query).includes(LOOP_TOTAL_QUERY_PARAM)
}

const getLoopQueryParams = (query: ParsedUrlQuery): LoopQuery => {
  return Object.entries(query)
    .filter(([key]) => key.startsWith(LOOP_QUERY_PARAM_PREFIX))
    .reduce((acc, [key, value]) => {
      return {
        ...acc,
        [key]: value,
      }
    }, {})
}

const getNonLoopQuery = (query: ParsedUrlQuery): ParsedUrlQuery => {
  return Object.entries(query)
    .filter(([key]) => !key.startsWith(LOOP_QUERY_PARAM_PREFIX))
    .reduce((acc, [key, value]) => {
      return {
        ...acc,
        [key]: value,
      }
    }, {})
}

const buildReturnsCart = (cart?: ShopifyResolvedCart | null): number[] => {
  const SHOPIFY_PRODUCT_VARIANT_PREFIX = 'gid://shopify/ProductVariant/'

  return (
    cart?.lines?.reduce((acc: number[], { quantity, merchandise }) => {
      const variantId = merchandise.id
        ? Number(merchandise.id.replace(SHOPIFY_PRODUCT_VARIANT_PREFIX, ''))
        : null

      if (variantId) {
        const variantsIdsCollection = Array(quantity).fill(variantId)
        return [...acc, ...variantsIdsCollection]
      }

      return acc
    }, []) || []
  )
}

// storage
const createReturnsSessionStorageService = () => {
  const STORAGE_PARAMS_KEY = `loopOnstoreParams`
  return {
    getParams: (): LoopQuery => {
      const dataFromLs = sessionStorage.getItem(STORAGE_PARAMS_KEY)
      return dataFromLs ? JSON.parse(dataFromLs) : null
    },
    setParams: (query: LoopQuery) =>
      sessionStorage.setItem(STORAGE_PARAMS_KEY, JSON.stringify(query)),
    removeParams: () => sessionStorage.removeItem(STORAGE_PARAMS_KEY),
  }
}

const SessionStorage = createReturnsSessionStorageService()

export type ReturnType = {
  handleReturnsCheckout: () => Promise<void>
  isReturnsMode: boolean
  returnsState: ReturnsState | null
}

export type PropsType = {
  cart: ShopifyResolvedCart | null
  destroyCart: () => void
}

/**
 * useReturns covers simplified flow from Loop documentation. For more details see:
 * https://help.loopreturns.com/article/147-shop-now-headless-on-store-setup
 * Test URL:
 * http://localhost:4200?loop_total=1299&loop_base=799&loop_credit=500&loop_subdomain=example&loop_redirect_uri=example.loopreturns.com%2F%23%2Fcredit&loop_customer_name=Jane%20Doe&loop_return_id=e1d10005-ec40-47da-b47b-424c4b19f11c
 */
export const useReturns = ({ cart, destroyCart }: PropsType): ReturnType => {
  const [isInitialised, setIsInitialised] = useState<boolean>(false)
  const { query, replace, pathname, isReady } = useRouter()
  const [isReturnsMode, setIsReturnsMode] = useState<boolean>(false)
  const [returnsState, setReturnsState] = useState<ReturnsState | null>(null)

  const saveParams = async () => {
    /**
     * We save params to localStorage
     */
    const returnsParams = getLoopQueryParams(query)
    SessionStorage.setParams(returnsParams)
    const returnsState = formatReturnsState(query)
    setReturnsState(returnsState)
    /**
     * We clean url from loop params
     */
    const nonLoopQuery = getNonLoopQuery(query)
    await replace({ pathname, query: nonLoopQuery }, undefined, {
      shallow: true,
    })
  }

  const initReturns = async () => {
    /**
     * On page load, we look for loop parameters and use that to determine whether it's a normal user or if the user is shopping with return credit.
     */
    if (isLoopQuery(query)) {
      await saveParams()
      destroyCart()
      return setIsReturnsMode(true)
    }

    /**
     * We look for your stored data. This will ensure that the customer doesn't get lost if they refresh the page.
     */
    const restoredParams = SessionStorage.getParams()
    if (SessionStorage.getParams()) {
      const restoredState = formatReturnsState(restoredParams)
      setReturnsState(restoredState)
      return setIsReturnsMode(true)
    }
  }

  const handleReturnsCheckout = async () => {
    const returnsCart = buildReturnsCart(cart)
    const { token } = await createReturnsCart(returnsCart)

    if (!token) {
      throw new Error('Error creating returns cart')
    }

    if (token) {
      SessionStorage.removeParams()
      setIsReturnsMode(false)
      setReturnsState(null)
      await destroyCart()
      window.location.href = `${process.env['NEXT_PUBLIC_LOOP_DOMAIN']}/#/cart/v2/${token}`
      return
    }
  }

  useEffect(() => {
    if (isReady && !isInitialised && cart) {
      setIsInitialised(true)
      initReturns()
    }
  }, [isReady, isInitialised, cart])

  return {
    handleReturnsCheckout,
    isReturnsMode,
    returnsState,
  }
}
