import currency from 'currency.js'

export function applyDiscountToValue(
  discount: number,
  value: string | number,
): string {
  if (discount) {
    const percentage = currency(Number(discount)).divide(100)
    const theAmountToBeSubtracted = currency(value).multiply(percentage)

    return currency(value, { precision: 2 })
      .subtract(theAmountToBeSubtracted)
      .toString()
  }

  return currency(value).toString()
}
