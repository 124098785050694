import { FC } from 'react'
import { ProductColorType, ShopifyProductsData } from '@aether/models'
import { styled } from '@aether/styles'
import { DesktopItem } from './DesktopItem'
import { MobileItem } from './MobileItem'

type ProductItemProps = {
  productId: string
  shopifyData?: ShopifyProductsData
  colorsMap?: ProductColorType[]
}

const StyledDesktopItem = styled(DesktopItem, {
  display: 'none',
  '@md': {
    display: 'block',
  },
})

const StyledMobileItem = styled(MobileItem, {
  display: 'block',
  '@md': {
    display: 'none',
  },
})

export const ProductItem: FC<ProductItemProps> = (props) => {
  return (
    <>
      <StyledMobileItem {...props} />
      <StyledDesktopItem {...props} />
    </>
  )
}
