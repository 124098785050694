import { SiteConfiguration } from '@aether/models'
import { styled } from '@aether/styles'
import { FC } from 'react'
import { AccordionItem, AccordionItemsList, Link } from '@aether/components'

type Props = {
  linkGroups?: SiteConfiguration['footer']['linkGroups']
  className?: string
}

const LinkWrap = styled('li', {
  $aetherFont: 'ui03',
  variants: {
    isLastInGroup: {
      true: {
        padding: '0 0 $24 0',
      },
      false: {
        padding: '0 0 $8 0',
      },
    },
  },
})

const StyledLink = styled(Link, {
  $aetherFont: 'ui03',
})

const Body = styled('ul', {
  paddingBottom: '$48',
})

export const MobileNavigation: FC<Props> = ({ linkGroups = [], className }) => {
  return (
    <div className={className}>
      <AccordionItemsList
        allowMultipleActiveItems={false}
        accordionId="mobileNav"
      >
        {(handleRequestOpen, handleRequestClose, isActive, accordionId) => {
          return linkGroups.map(({ links, title }, itemIndex) => {
            const itemId = `${accordionId}-${itemIndex}`
            return (
              <AccordionItem
                key={itemId}
                headerType="default"
                isActive={isActive(itemId)}
                label={title}
                itemId={itemId}
                onRequestOpen={handleRequestOpen}
                onRequestClose={handleRequestClose}
              >
                <Body>
                  {links?.map(({ type, payload }, index) => {
                    if (type === 'link') {
                      const isLastInGroup = links[index + 1]?.type === 'divider'
                      const link = payload?.link || {}
                      const label = payload?.label || ''
                      return (
                        <LinkWrap
                          isLastInGroup={isLastInGroup}
                          key={`${label}-${index}`}
                        >
                          <StyledLink {...link} key={label}>
                            {label}
                          </StyledLink>
                        </LinkWrap>
                      )
                    }
                    return null
                  })}
                </Body>
              </AccordionItem>
            )
          })
        }}
      </AccordionItemsList>
    </div>
  )
}
