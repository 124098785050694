import { coalesceLocaleField } from '../helpers/coalesceLocaleField'
import { groq } from 'next-sanity'
import { LocaleId } from '@aether/configs'
import { createRichTextProjection } from '../projections/createRichTextProjection'

export const createSectionAccordionGroupsProjection = (localeId: LocaleId) => {
  const RICH_TEXT_PROJECTION = createRichTextProjection(localeId)
  return groq`{
        _key,
        _type,
        groups[]{
          ${coalesceLocaleField('title', localeId)},
          _key,
          accordions[]{
            ${coalesceLocaleField('accordionHeader', localeId)},
            ${coalesceLocaleField(
              'accordionContent',
              localeId,
            )}[]${RICH_TEXT_PROJECTION},
          }
        }
      }
  `
}
