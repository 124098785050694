export const UnMute = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_b_1207_3576)">
        <circle cx="16" cy="16" r="16" fill="white" fillOpacity="0.1" />
        <circle cx="16" cy="16" r="15.5" stroke="white" />
      </g>
      <path
        d="M24.3678 12.1286C24.1773 11.9571 23.8724 11.9571 23.7009 12.1667L20.919 15.2345L18.137 12.1667C17.9655 11.9762 17.6607 11.9571 17.4701 12.1286C17.2796 12.3001 17.2605 12.605 17.432 12.7955L20.2711 15.9204L17.432 19.0454C17.2605 19.2359 17.2605 19.5408 17.4701 19.7123C17.5654 19.7885 17.6797 19.8266 17.794 19.8266C17.9274 19.8266 18.0608 19.7695 18.137 19.6742L20.919 16.6064L23.7009 19.6742C23.7962 19.7695 23.9296 19.8266 24.0439 19.8266C24.1582 19.8266 24.2726 19.7885 24.3678 19.7123C24.5584 19.5408 24.5774 19.2359 24.406 19.0454L21.5668 15.9204L24.406 12.7955C24.5774 12.605 24.5774 12.3001 24.3678 12.1286Z"
        fill="white"
      />
      <path
        d="M8 13.1004V19.2169H11.2774L15.5837 22.056V10.2422L11.1631 13.0813H8.01905L8 13.1004Z"
        fill="white"
      />
      <defs>
        <filter
          id="filter0_b_1207_3576"
          x="-4"
          y="-4"
          width="40"
          height="40"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="2" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_1207_3576"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_1207_3576"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  )
}
