import { DiscountIndicator } from './DiscountIndicator'
import { ShopifyResolvedCart } from '@aether/shopify-sdk'
import { useTranslation } from 'react-i18next'

export interface TieredDiscount {
  title?: string
  tiers: Array<{
    discount: number
    amount: number
  }>
  type: 'tiered'
}

interface TieredDiscountIndicatorProps {
  discounts: TieredDiscount[]
  cart: ShopifyResolvedCart | null
  className?: string
}

export const TieredDiscountIndicator = ({
  discounts,
  cart,
  className,
}: TieredDiscountIndicatorProps) => {
  const { t } = useTranslation('cart')

  if (!cart) return null

  const hasVipTieredDiscount = discounts && discounts.length > 0
  const theDiscountToDisplay = discounts?.[0]

  const VIPDiscountTiers = hasVipTieredDiscount
    ? theDiscountToDisplay?.tiers.map((tier) => ({
        tierThreshold: tier.amount,
        awayFromLabel: t('tieredDiscount.awayFrom', {
          value: tier.discount,
        }),
        unlockedDiscountLabel: t('tieredDiscount.unlockedDiscount', {
          value: tier.discount,
        }),
      }))
    : []

  return hasVipTieredDiscount ? (
    <DiscountIndicator
      cart={cart}
      discountLabel={theDiscountToDisplay?.title}
      discounts={VIPDiscountTiers}
      className={className}
    />
  ) : null
}
