import React, { FC } from 'react'

type Props = {
  className?: string
  size?: 'default' | 'small'
}

export const SmallArrowRight: FC<Props> = ({ className, size = 'default' }) => {
  const [width, height] = (() => {
    switch (size) {
      default:
        return [20, 14]
    }
  })()

  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 8 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.85 13.321C0.759 13.321 0.668 13.286 0.605 13.216C0.465 13.076 0.465 12.859 0.605 12.719L6.352 6.97203L0.682 1.29503C0.542 1.16203 0.542 0.938029 0.682 0.805029C0.822 0.672029 1.039 0.665029 1.172 0.805029L7.339 6.97203L1.095 13.216C1.025 13.286 0.934 13.321 0.85 13.321Z"
        fill="black"
      />
    </svg>
  )
}
