import { createMediaProjection } from '../projections/createMediaProjection'
import { groq } from 'next-sanity'
import { LocaleId } from '@aether/configs'

export const createSectionDoubleImageProjection = (localeId: LocaleId) => {
  const MEDIA_PROJECTION = createMediaProjection(localeId)
  return groq`{
        _key,
        _type,
        leftImage${MEDIA_PROJECTION},
        rightImage${MEDIA_PROJECTION},
        sectionSpaceSize,
        imagesOrientation,
        "leftMediaShopifyIds": leftMediaProducts[] -> {"id": store.gid, "type": "single-product"},
        "leftProductsButtonAppearance": coalesce(productsButtonAppearance, "light"),
        "rightMediaShopifyIds": rightMediaProducts[] -> {"id": store.gid, "type": "single-product"},
        "rightProductsButtonAppearance": coalesce(productsButtonAppearance, "light")
      }
  `
}
