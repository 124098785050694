import { FC, useState } from 'react'
import { ShopifyProductsData, ProductColorType } from '@aether/models'
import { styled } from '@aether/styles'
import { FullWidthImageSlider } from '../FullWidthImageSlider/FullWidthImageSlider'
import { ProgressIndicator } from '../FullWidthImageSlider/ProgressIndicator'

import { ROUTE_BASE_PRODUCTS } from '@aether/configs'
import { Container } from '../Container/Container'
import { ProductColorPicker } from '../ProductColorPicker/ProductColorPicker'
import { Link } from '../Link/Link'
import { ProductPrice } from '../Price/ProductPrice/ProductPrice'
import { Badge } from '../Badge/Badge'
import { useTranslation } from 'react-i18next'
import { useDiscounts } from '@aether/discounts/utils-context'
import { useCustomerContext } from '@aether/account/utils-customer-context'
import { InView } from 'react-intersection-observer'
import { formatProductName } from '@aether/utils'

type MobileItemProps = {
  productId: string
  shopifyData?: ShopifyProductsData
  colorsMap?: ProductColorType[]
  className?: string
}

const Card = styled('article', {
  position: 'relative',
  display: 'grid',
  width: '100%',
})

const StyledBadge = styled(Badge, {
  position: 'absolute',
  top: '$24',
  marginLeft: '$24',
  zIndex: '$content',
  $lin: {
    left: 'CONTAINER_SPACE_M',
  },
})

const MediaWrap = styled('div', {
  gridRow: 1,
  gridColumn: 1,
  display: 'grid',
  gap: '$20',
})

const BottomWrap = styled(Container, {
  gridRow: 2,
  gridColumn: 1,
  rowGap: '$12',
  display: 'grid',
  gridAutoFlow: 'row',
  width: '100%',
})

const FeaturesContainer = styled('div', {
  gridRow: 3,
  gridColumn: 1,
  display: 'flex',
  gap: '$16',
})

const FeatureTextWrap = styled('div', {
  $aetherFont: 'ui01',
  lineHeight: '15px',
})

const Title = styled('h5', {
  $aetherFont: 'heading05',
  gridRow: 2,
  gridColumn: 1,
})

const StyledProductColorPicker = styled(ProductColorPicker, {
  gridRow: 4,
  gridColumn: 1 / 3,
})

const StyledLink = styled(Link, {
  $focusStyle: 'default',
})

const StyledProgressIndicator = styled(ProgressIndicator, {
  gridRow: 2,
})

const BottomBadgesWrapper = styled('div', {
  display: 'flex',
  flexWrap: 'wrap',
  gap: '$12',
  gridRow: 5,
  gridColumn: '1 / 3',
})

export const MobileItem: FC<MobileItemProps> = ({
  productId: initialProductId,
  shopifyData,
  colorsMap,
  className,
}) => {
  const { t } = useTranslation('product')

  const [productId, setProductId] = useState(initialProductId)
  const product = shopifyData?.products[productId]
  const collections =
    product?.collections?.edges.map((edge) => edge.node.id) || []
  const plpMainFeature = product?.plpMainFeature?.value || ''
  const products = shopifyData?.products || {}
  const productTitle = formatProductName(product?.title || '')
  const [currentSlide, setCurrentSlide] = useState<number>(0)

  const { userRole } = useCustomerContext()
  const isVip = userRole === 'vip'
  const { matchedDiscount } = useDiscounts({ collections, userRole, productId })

  const isVipDiscounted = isVip && product && matchedDiscount

  /**
   * Display max 4 images
   */
  const images = product?.images.slice(0, 4) || []

  const badge = product?.badge?.value
  const vipProduct = product?.vipProduct
  const greyBadgeValue = product?.greyBadge?.value
  const isSoldOut = !product?.availableForSale

  return product ? (
    <InView as="div">
      {({ inView, ref }) => {
        return (
          <div className={className} ref={ref}>
            <StyledLink
              appearance="block"
              href={`${ROUTE_BASE_PRODUCTS}/${product.handle}`}
            >
              <Card>
                {badge && <StyledBadge text={badge} appearance="action" />}
                <MediaWrap>
                  <FullWidthImageSlider
                    images={images}
                    onSlideChange={setCurrentSlide}
                    spacing="medium"
                    isEnabled={inView}
                  />
                  <StyledProgressIndicator
                    currentSlide={currentSlide}
                    images={images}
                    spacing="medium"
                    size="M"
                  />
                </MediaWrap>
                <BottomWrap spaceSize="medium">
                  <Title>{productTitle}</Title>
                  <FeaturesContainer>
                    <FeatureTextWrap>
                      {isSoldOut ? (
                        t('soldOut')
                      ) : (
                        <ProductPrice
                          product={product}
                          discount={matchedDiscount}
                        />
                      )}
                    </FeatureTextWrap>
                    {plpMainFeature && (
                      <FeatureTextWrap as="span">
                        {plpMainFeature}
                      </FeatureTextWrap>
                    )}
                  </FeaturesContainer>
                  <StyledProductColorPicker
                    handleColorChange={(product, e) => {
                      e.preventDefault()
                      setProductId(product.id)
                    }}
                    colorsMap={colorsMap || []}
                    productId={productId}
                    products={products}
                    titlePosition="right"
                  />
                  {(vipProduct || greyBadgeValue || isVipDiscounted) && (
                    <BottomBadgesWrapper>
                      {vipProduct && isVip && (
                        <Badge text={t('vipBadge')} appearance="vip" />
                      )}
                      {greyBadgeValue && (
                        <Badge text={greyBadgeValue} appearance="default" />
                      )}
                      {isVipDiscounted && isVip && (
                        <Badge text={t('aetherVipSale')} appearance="vip" />
                      )}
                    </BottomBadgesWrapper>
                  )}
                </BottomWrap>
              </Card>
            </StyledLink>
          </div>
        )
      }}
    </InView>
  ) : null
}
