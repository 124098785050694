import { SiteConfiguration } from '@aether/models'
import { styled } from '@aether/styles'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Container, KlavyioEmbeddedForm } from '@aether/components'
import { BrandNameIcon } from './BrandNameIcon'
import { DesktopNavigation } from './DesktopNavigation'
import { AnimatedLogo } from './AnimatedLogo'
import { MobileNavigation } from './MobileNavigation'
import { useInView } from 'react-intersection-observer'
import { Link } from '@aether/components'
import { Stores } from './Stores'
// import { Credits } from './Credits'

type Props = {
  footer?: SiteConfiguration['footer']
  newsletter?: SiteConfiguration['newsletter']
}

const Root = styled('footer', {
  display: 'block',
})

const StyledContainer = styled(Container, {
  display: 'flex',
  flexDirection: 'column',
  paddingBottom: '$sectionSpaceMobile',
  paddingTop: '$sectionSpaceMobile',
  '@lg': {
    paddingBottom: '$sectionSpaceDesktop',
    paddingTop: '$sectionSpaceDesktop',
  },
})

const Nav = styled('div', {
  width: '100%',
  paddingBottom: '$72',
  '@lg': {
    paddingBottom: '$96',
  },
})

const StyledBrandNameIcon = styled(BrandNameIcon, {
  paddingBottom: '$96',
  width: '100%',
})

const BottomSection = styled('div', {
  display: 'flex',
  flexDirection: 'column-reverse',
  alignItems: 'center',
  textAlign: 'center',
  '&>:first-child': {
    marginTop: '$48',
  },
  '&>:last-child': {
    marginBottom: '$32',
  },

  '@lg': {
    paddingTop: '$36',
    borderTop: '1px solid $black',
  },

  '@xl': {
    textAlign: 'unset',
    flexDirection: 'row',
    justifyContent: 'space-between',
    '&>:first-child': {
      margin: 0,
    },
    '&>:last-child': {
      margin: 0,
    },
  },
})

const BottomText = styled('blockquote', {
  $aetherFont: 'ui05',
})

const BottomLink = styled(Link, {
  $aetherFont: 'ui05',
  textDecoration: 'underline',
})

const MobileNavWrap = styled('div', {
  display: 'block',
  '@lg': {
    display: 'none',
  },
})

const DesktopNavWrap = styled('div', {
  display: 'none',
  '@lg': {
    display: 'flex',
  },
})

const MobileAnimatedLogo = styled(AnimatedLogo, {
  display: 'block',

  '@lg': {
    display: 'none',
  },
})

const BottomTextWrap = styled('div', {
  display: 'grid',
  gridAutoFlow: 'row',
  alignItems: 'center',
  gap: '$24',
  '@lg': {
    gridAutoFlow: 'column',
  },
})

const NewsletterContainer = styled(Container, {
  display: 'grid',
  gridAutoFlow: 'row',
  paddingTop: '$sectionSpaceMobile',
  paddingBottom: '$sectionSpaceMobile',
  backgroundColor: '$gray_light',
  '@lg': {
    gap: '$20',
    gridTemplateColumns: 'minmax(300px, 40%) 2fr',
    paddingTop: '$sectionSpaceDesktop',
    paddingBottom: '$sectionSpaceDesktop',
  },
  '@xl': {
    gap: '$144',
    gridTemplateColumns: '440px minmax(auto, 900px)',
  },
})

export const Footer: FC<Props> = ({ footer, newsletter }) => {
  const { t } = useTranslation('footer')
  const linkGroups = footer?.linkGroups || []
  const bottomText = footer?.bottomText || ''
  const footerLinks = footer?.footerLinks || []
  const [logoRef, logoInView] = useInView({
    triggerOnce: true,
  })
  const [mobileLogoRef, mobileLogoInView] = useInView({
    triggerOnce: true,
  })

  return (
    <Root>
      {newsletter && newsletter.klaviyoEmbedCode && (
        <NewsletterContainer>
          <KlavyioEmbeddedForm
            embedCode={newsletter.klaviyoEmbedCode}
            sectionSpaceSize={'none'}
          />
          <Stores
            heading={newsletter.storesHeading}
            stores={newsletter.stores}
          />
        </NewsletterContainer>
      )}
      <StyledContainer>
        <StyledBrandNameIcon />
        <Nav>
          <MobileNavWrap>
            <MobileNavigation linkGroups={linkGroups} />
          </MobileNavWrap>
          <DesktopNavWrap>
            <DesktopNavigation linkGroups={linkGroups} />
            <AnimatedLogo ref={logoRef} animate={logoInView} />
          </DesktopNavWrap>
        </Nav>
        <BottomSection>
          <BottomText>{bottomText}</BottomText>
          <BottomTextWrap>
            <BottomText>
              &#169; {new Date().getFullYear()} {t('all rights reserved')}
            </BottomText>
            {footerLinks.map((link) => (
              <BottomLink {...link.link} key={link.label}>
                {link.label}
              </BottomLink>
            ))}
            {/*<Credits />*/}
          </BottomTextWrap>

          <MobileAnimatedLogo ref={mobileLogoRef} animate={mobileLogoInView} />
        </BottomSection>
      </StyledContainer>
    </Root>
  )
}
