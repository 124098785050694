import { FC, Fragment, useState } from 'react'
import { SizeGuideTableType } from '@aether/models'
import { styled } from '@aether/styles'
import { useTranslation } from 'react-i18next'
import { RadioSwitch } from '../RadioSwitch/RadioSwitch'

export type SizeChartProps = {
  table?: SizeGuideTableType
  title: string | undefined
}

const StyledSwitchWrap = styled('div', {
  display: 'flex',
  flexDirection: 'row-reverse',
  marginBottom: '$24',
})

const StyledSizeGuideTitle = styled('h2', {
  $aetherFont: 'heading05',
  marginBottom: '$32',
})

const StyledTableWrap = styled('div', {
  width: '100%',

  overflowX: 'scroll',
  scrollBehavior: 'smooth',
  '&::-webkit-scrollbar': {
    display: 'none',
  },

  '@md': {
    width: 'unset',
    paddingRight: '0',
  },
})

const StyledTable = styled('table', {
  width: '100%',
  flex: '100%',

  '@md': {
    flex: 'unset',
    marginRight: '$0',
  },
})

const StyledHeadRow = styled('tr', {
  borderBottom: '1px solid black',
})

const StyledBodyRow = styled('tr', {
  borderBottom: '1px solid black',
  paddingRight: '100px',

  '&:hover': {
    backgroundColor: '$gray_light',
  },
})

const StyledHeadCell = styled('th', {
  $aetherFont: 'ui01',
  minWidth: '$96',
  padding: '0 $12 $24 $12',
  textAlign: 'left',

  '&:last-child': {
    paddingRight: '$36',
  },
})

const StyledCell = styled('td', {
  $aetherFont: 'ui01',
  minWidth: '90px',
  padding: '$24 $12 $24 $12',
  borderBottom: '1px solid black',
})

export const SizeChart: FC<SizeChartProps> = ({ table, title }) => {
  const headRow = table?.rows[0].cells
  const contentRows = table?.rows?.slice(1)
  const { t } = useTranslation('sizeGuide')

  const [unit, setUnit] = useState<'in' | 'cm'>('in')

  const handleOptionChange = (newValue: string) => {
    if (newValue === 'in' || newValue === 'cm') {
      setUnit(newValue)
    }
  }

  const hasUnitCells = (): boolean => {
    if (!table) return false

    return !!table.rows.filter((row) =>
      row.cells?.some((cell) => cell.cellType === 'unit'),
    ).length
  }

  return (
    <>
      <StyledSwitchWrap>
        {hasUnitCells() && (
          <RadioSwitch
            items={[
              { label: t('cmUnit'), value: 'cm' },
              { label: t('inchUnit'), value: 'in' },
            ]}
            onChange={handleOptionChange}
            value={unit}
            type="fill"
          />
        )}
      </StyledSwitchWrap>

      <StyledSizeGuideTitle>{title}</StyledSizeGuideTitle>

      <StyledTableWrap>
        <StyledTable>
          <thead>
            <StyledHeadRow>
              {headRow &&
                headRow.map((cell, index) => (
                  <StyledHeadCell key={index}>{cell.title}</StyledHeadCell>
                ))}
            </StyledHeadRow>
          </thead>
          <tbody>
            {contentRows &&
              contentRows.map((row, index) => (
                <StyledBodyRow key={index}>
                  {row.cells?.map((cell, index) => (
                    <Fragment key={`${cell.title}-${index}`}>
                      {cell.title && cell.cellType === 'title' && (
                        <StyledCell>{cell.title}</StyledCell>
                      )}
                      {cell.inchUnit &&
                        cell.cmUnit &&
                        cell.cellType === 'unit' && (
                          <StyledCell>
                            {unit === 'in' ? cell.inchUnit : cell.cmUnit}
                          </StyledCell>
                        )}
                    </Fragment>
                  ))}
                </StyledBodyRow>
              ))}
          </tbody>
        </StyledTable>
      </StyledTableWrap>
    </>
  )
}
