import {
  GTMAddToCart,
  GTMBeginCheckout,
  GTMRemoveFromCart,
  GTMViewCart,
} from '@aether/services/gtm-service'
import { addBreadcrumb } from '@sentry/nextjs'
import {
  ShopifyCartLineInput,
  ShopifyMutationCartLinesUpdateArgs,
  ShopifyResolvedCart,
  ShopifyResolvedCartLine,
} from '@aether/shopify-sdk'

export const cartAnalytics = {
  viewCart: (cart: ShopifyResolvedCart) => {
    if (!cart) {
      return
    }
    GTMViewCart(
      cart.cost.subtotalAmount.amount,
      cart.cost.subtotalAmount.currencyCode,
      cart.lines || [],
    )
    addBreadcrumb({
      category: 'cart',
      message: 'View cart',
      level: 'info',
      data: {
        cartId: cart.id,
        variants: cart.lines?.map((i) => ({
          quantity: i.quantity,
          variantId: i.merchandise.id,
        })),
      },
    })
  },
  checkout: (cart: ShopifyResolvedCart, type?: 'multipass') => {
    if (cart?.lines) {
      GTMBeginCheckout(
        cart.cost.subtotalAmount.amount,
        cart.cost.subtotalAmount.currencyCode,
        cart.lines,
      )
    }

    addBreadcrumb({
      category: 'cart',
      message: (() => {
        switch (type) {
          case 'multipass':
            return 'Checkout with Multipass'
          default:
            return 'Checkout (default)'
        }
      })(),
      level: 'info',
      data: {
        cartId: cart?.id,
        variants: cart?.lines?.map((i) => ({
          quantity: i.quantity,
          variantId: i.merchandise.id,
        })),
      },
    })
  },
  updateCartLine: (
    lines: ShopifyMutationCartLinesUpdateArgs['lines'],
    currentCart?: ShopifyResolvedCart | null,
  ) => {
    const orderQuantity = currentCart?.lines?.reduce(
      (acc, { quantity }) => acc + quantity,
      0,
    )

    lines.forEach((line) => {
      const prevLine = currentCart?.lines?.find(
        (currentLine) => currentLine.id === line.id,
      )
      if (!prevLine) {
        return
      }

      if (!line.quantity) {
        return
      }

      // Removing from cart
      if (prevLine.quantity - line.quantity >= 0) {
        const newQuantity = prevLine.quantity - line.quantity

        addBreadcrumb({
          category: 'cart',
          message: 'Remove from cart',
          level: 'info',
          data: {
            variantId: line.merchandiseId,
            quantity: newQuantity,
          },
        })
        GTMRemoveFromCart(prevLine, newQuantity)

        return
      }
      // Adding to cart
      addBreadcrumb({
        category: 'cart',
        message: 'Add to cart',
        level: 'info',
        data: {
          variantId: line.merchandiseId,
          quantity: line.quantity - prevLine.quantity,
        },
      })
      GTMAddToCart(
        currentCart?.cost.subtotalAmount.amount,
        currentCart?.cost.subtotalAmount.currencyCode,
        prevLine,
        orderQuantity,
        line.quantity - prevLine.quantity,
      )
    })
  },
  removeCartLine: (lines: ShopifyResolvedCartLine[]) => {
    lines.forEach((line) => {
      const { quantity } = line
      addBreadcrumb({
        category: 'cart',
        message: 'Remove from cart',
        level: 'info',
        data: {
          variantId: line.merchandise.id,
          quantity,
        },
      })
      GTMRemoveFromCart(line, quantity)
    })
  },
  addCartLine: (
    variants: ShopifyCartLineInput[],
    updatedCart?: ShopifyResolvedCart | null,
  ) => {
    const orderQuantity = updatedCart?.lines?.reduce(
      (acc, { quantity }) => acc + quantity,
      0,
    )

    variants.forEach(({ merchandiseId, quantity }) => {
      const line = updatedCart?.lines?.find(
        ({ merchandise }) => merchandise.id === merchandiseId,
      )

      if (!line) {
        return
      }
      addBreadcrumb({
        category: 'cart',
        message: 'Add to cart',
        level: 'info',
        data: {
          variantId: line.merchandise.id,
          quantity,
        },
      })
      GTMAddToCart(
        updatedCart?.cost.subtotalAmount.amount,
        updatedCart?.cost.subtotalAmount.currencyCode,
        line,
        orderQuantity,
        quantity ?? 0,
      )
    })
  },
}
