/**
 🚨🚨🚨
 If you update @shopify/hydrogen-react, then change the Shopify Storefront Version that
 hydrogen-react library refers to
 🚨🚨🚨
 **/

export const SHOPIFY_STOREFRONT_API_VERSION = '2023-10'

export const shopifyConfig = {
  accessToken: process.env['NEXT_PUBLIC_SHOPIFY_ACCESS_TOKEN'],
  domain: process.env['NEXT_PUBLIC_SHOPIFY_DOMAIN'],
  apiVersion: SHOPIFY_STOREFRONT_API_VERSION,
}
