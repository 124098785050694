import { SectionShopifyId } from '@aether/models'
import { getProducts } from './getShopifySingleProductsData'

export const getShopifyProductRelatedProductsData = async (
  locale: string,
  productId: string,
  isVip: boolean,
) => {
  const allProducts = await getProducts(locale, [productId], isVip)
  const parsedRelatedProductsIds: Array<string> = allProducts[0]
    ?.relatedProducts?.value
    ? JSON.parse(allProducts[0]?.relatedProducts?.value)
    : []

  return parsedRelatedProductsIds.map((relatedProduct) => ({
    type: 'single-product' as SectionShopifyId['type'],
    id: relatedProduct,
  }))
}
