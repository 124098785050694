import { DEFAULT_LOCALE_ID, LocaleId } from '@aether/configs'

export const coalescePortableToPlainText = (
  fieldName: string,
  localeId: LocaleId = DEFAULT_LOCALE_ID,
  targetFieldName?: string,
) =>
  `"${
    targetFieldName ?? fieldName
  }": pt::text(coalesce(${fieldName}.${localeId}, ${fieldName}.${localeId}))`
