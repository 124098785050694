import { ListItem } from './TextResult.styles'
import { AnchorTextResult } from './types'
import { Link } from '@aether/components'
import { useTranslation } from 'react-i18next'

interface AnchorResultProps {
  result: AnchorTextResult
}

export const AnchorResult = ({ result }: AnchorResultProps): JSX.Element => {
  const { t } = useTranslation('search')

  return (
    <ListItem>
      <Link
        href={result.href}
        ariaLabel={t('suggestedPageLabel', { value: result.label }) ?? ''}
      >
        {result.label}
      </Link>
    </ListItem>
  )
}
