import { ShopifyProduct } from '@aether/models'
import { getProductRootTitle } from './getProductRootTitle'

export const findRelatedProducts = (
  productTitle: string,
  products: Record<string, ShopifyProduct>,
): ShopifyProduct[] => {
  const allProductsIds: string[] = Object.keys(products)

  const rootTitle = getProductRootTitle(productTitle)
  return allProductsIds.reduce<ShopifyProduct[]>((acc, productId) => {
    const currentProduct = products[productId]
    const currentProductRootTitle = getProductRootTitle(currentProduct.title)

    if (currentProductRootTitle === rootTitle) {
      return [...acc, currentProduct]
    }

    return acc
  }, [])
}
