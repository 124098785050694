export const sizes = {
  0: '0px',
  4: '4px',
  6: '6px',
  8: '8px',
  12: '12px',
  14: '14px',
  16: '16px',
  18: '18px',
  20: '20px',
  24: '24px',
  28: '28px',
  32: '32px',
  36: '36px',
  40: '40px',
  48: '48px',
  56: '56px',
  64: '64px',
  72: '72px',
  96: '96px',
  120: '120px',
  144: '144px',
  168: '168px',
  192: '192px',
  240: '240px',
  288: '288px',
  416: '416px',
  headerHeightNormal: '80px',
  headerHeightLarge: '120px',
  notificationHeaderHeight: '43px',
  progressHeaderHeightNormal: '72px',
  progressHeaderHeightLarge: '80px',
  buttonHeightS: '22px',
  buttonHeightM: '28px',
  buttonHeightL: '48px',
  buttonHeightXL: '54px',
  containerSizeL: '1440px',
  containerSizeS: '700px',
  sectionSpaceMobile: '56px',
  sectionSpaceDesktop: '96px',
  collectionDesktopDescriptionWidth: '460px',
}
