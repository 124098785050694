import { createShopifySdk, ShopifyProductFilter } from '@aether/shopify-sdk'
import { createShopifyClient } from './createShopifyClient'
import { getLocaleRegionIdFromPath } from '@aether/utils'
import { creatVipProductFilters } from './helpers/formatCollectionFiltersQuery'

export const COLLECTION_FILTERS_INITIAL_PRODUCTS_COUNT = 250

export const getSDKShopifyCollectionFilters = async ({
  locale,
  handle,
  filters: collectionFilters = [],
  isVip = false,
}: {
  locale: string
  filters?: ShopifyProductFilter[]
  handle: string
  isVip?: boolean
}) => {
  const [, localeId] = getLocaleRegionIdFromPath(locale)

  const vipProductsFilters = creatVipProductFilters(isVip)
  const filters = [...collectionFilters, ...vipProductsFilters]

  const shopifyClient = createShopifyClient(localeId)

  return await createShopifySdk(shopifyClient)
    .getCollectionFilters({
      filters,
      handle,
    })
    .then((res) =>
      res.data.collection
        ? { filters: res.data.collection.products.filters }
        : null,
    )
}
