import { ContainerSpaceType } from '@aether/models'
import { lin } from './lin'

export const getContainerSpace = (containerSpaceType: ContainerSpaceType) => {
  switch (containerSpaceType) {
    case 'none':
      return {
        paddingLeft: 0,
        paddingRight: 0,
      }

    case 'small':
      return lin({
        paddingRight: 'CONTAINER_SPACE_S',
        paddingLeft: 'CONTAINER_SPACE_S',
      })

    case 'medium':
      return lin({
        paddingRight: 'CONTAINER_SPACE_M',
        paddingLeft: 'CONTAINER_SPACE_M',
      })

    case 'large':
      return lin({
        paddingRight: 'CONTAINER_SPACE_L',
        paddingLeft: 'CONTAINER_SPACE_L',
      })

    case 'xlarge':
      return lin({
        paddingRight: 'CONTAINER_SPACE_XL',
        paddingLeft: 'CONTAINER_SPACE_XL',
      })

    default:
      return {}
  }
}
