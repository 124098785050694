import { algoliaUtilityPageMock } from './algoliaUtilityPageMock'
import { SanityUtilityPageType } from './types'
import { extractTextContentFromPageBuilder } from '../pageBuilder/extractTextContentFromPageBuilder'

export function mapSanityUtilityPageToAlgoliaUtilityPage(
  sanityUtilityPage: SanityUtilityPageType,
): typeof algoliaUtilityPageMock {
  return {
    pageBuilder: extractTextContentFromPageBuilder(
      sanityUtilityPage.pageBuilder,
    ),
    seo: {
      description: sanityUtilityPage.seo?.metaDescription?.en,
      title: sanityUtilityPage.seo?.metaTitle?.en,
    },
    title: sanityUtilityPage.title?.en,
    type: 'utilityPage',
    path: sanityUtilityPage?.path.current,
  }
}
