import React, { FC } from 'react'
import { FitGuideItemType } from '@aether/models'
import { styled } from '@aether/styles'
import { Media } from '../Media/Media'

export type FitGuideProps = {
  fitGuide: FitGuideItemType[] | undefined
}

const StyledFitGuide = styled('ul', {
  display: 'grid',
  gap: '$64',
  marginBottom: '$24',

  '@md': {
    gridTemplateColumns: '1fr 1fr 1fr',
    gap: '$48',
    marginBottom: 'unset',
  },
})

const StyledTitle = styled('h2', {
  $aetherFont: 'heading07',
  marginTop: '$16',
})

const StyledDescription = styled('p', {
  $aetherFont: 'body03',
  marginTop: '$16',
})

export const FitGuide: FC<FitGuideProps> = ({ fitGuide }) => {
  return (
    <StyledFitGuide>
      {fitGuide &&
        fitGuide.map((item, index) => (
          <li key={`${index}-${item.description}`} tabIndex={0}>
            {item.image && (
              <Media
                {...item.image}
                sizes={{ default: '100vw', md: '300px' }}
              />
            )}
            {item.title && <StyledTitle>{item.title}</StyledTitle>}
            {item.description && (
              <StyledDescription>{item.description}</StyledDescription>
            )}
          </li>
        ))}
    </StyledFitGuide>
  )
}
