import { FC, MouseEventHandler } from 'react'

import { styled } from '@aether/styles'
import { ArrowRight, ArrowLeft } from '@aether/icons'

type SlideButtonProps = {
  isVisible?: boolean
  handleClick?: MouseEventHandler<HTMLButtonElement>
  className?: string
  arrowDirection: 'left' | 'right'
  ariaLabel?: string
}

// animation config
const ANIMATION_DURATION_MS = 350

const StyledSlideButton = styled('button', {
  padding: '9px 12px',
  display: 'flex',
  margin: 0,
  backgroundColor: '$white',
  borderRadius: '$rMax',

  zIndex: '10',
  transform: 'translateY(-50%)',
  transition: `opacity ease-in ${ANIMATION_DURATION_MS}ms`,
  '&:focus-visible': {
    outline: '$blue solid 3px',
    outlineOffset: '2px',
    opacity: '0.7',
    visibility: 'visible',
  },
  '&:hover': {
    opacity: '1',
    transition: `opacity ease-in ${ANIMATION_DURATION_MS / 2}ms`,
  },
  variants: {
    isVisible: {
      true: {
        opacity: '0.7',
        visibility: 'visible',
      },
      false: {
        opacity: '0.0',
        visibility: 'none',
      },
    },
  },
})

export const SlideButton: FC<SlideButtonProps> = ({
  handleClick,
  isVisible = true,
  className,
  arrowDirection,
  ariaLabel,
}) => {
  return (
    <StyledSlideButton
      onClick={handleClick}
      isVisible={isVisible}
      className={className}
      aria-label={ariaLabel}
    >
      {arrowDirection === 'right' ? <ArrowRight /> : <ArrowLeft />}
    </StyledSlideButton>
  )
}
