import { ShopifyResolvedCartLine } from '@aether/shopify-sdk'
import { createItem } from './createItem'
import { GTMResetEcommerce } from './gtmResetEcommerce'

export const GTMBeginCheckout = (
  amount: string,
  currency: string,
  cartLines?: ShopifyResolvedCartLine[],
) => {
  GTMResetEcommerce()

  const w = window as any

  w.dataLayer = w.dataLayer || []
  w.dataLayer.push({
    event: 'begin_checkout',
    ecommerce: {
      currency,
      value: parseFloat(amount),
      items: cartLines?.map((cartLine, index) => {
        return createItem({
          productTitle: cartLine.merchandise.product.title,
          currency: cartLine.merchandise.price.currencyCode,
          index,
          variantTitle: cartLine.merchandise.title,
          priceAmount: cartLine.merchandise.price.amount,
          quantity: cartLine.quantity,
          sku: cartLine.merchandise.sku ?? undefined,
        })
      }),
    },
  })
}
