import React, { FC, MouseEvent } from 'react'
import { ProductColorType, ShopifyProduct } from '@aether/models'
import { styled } from '@aether/styles'
import { Media } from '../Media/Media'
import { findRelatedProducts } from '@aether/services/shopify-service'
import { useTranslation } from 'react-i18next'
import { ROUTE_BASE_PRODUCTS } from '@aether/configs'
import { Link } from '../Link/Link'

const Root = styled('div', {
  display: 'grid',
  gap: '$6',
  variants: {
    titlePosition: {
      top: {
        marginBottom: '-6px',
        gridTemplateAreas: `
        "title"
        "colors"
        `,
      },
      right: {
        gridTemplateColumns: 'max-content 1fr',
        alignItems: 'center',
        justifyContent: 'flex-end',
        gridTemplateAreas: `"colors title"`,
        marginTop: '-6px',
      },
    },
  },
})

const ColorTitleLabel = styled('span', {
  gridArea: 'title',
  $aetherFont: 'ui01',
})

const ColorPicker = styled('div', {
  gridArea: 'colors',
  display: 'flex',
  variants: {
    size: {
      M: {
        marginLeft: '-6px',
      },
      L: {
        marginLeft: '-6px',
      },
    },
  },
})

const Color = styled('button', {
  overflow: 'hidden',
  background: 'none',
  $focusStyle: 'default',
  height: 36,
  width: 40,
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '&::after': {
    content: '',
    position: 'absolute',
    border: 'solid 1px black',
    borderRadius: '$rMax',
    display: 'none',
    boxSizing: 'border-box',
  },
  variants: {
    selected: {
      true: {
        '&::after': { display: 'block' },
      },
    },
    size: {
      M: {
        '&::after': {
          height: 24,
          width: 24,
        },
      },
      L: {
        height: 40,
        width: 40,
        '&::after': {
          height: 28,
          width: 28,
        },
      },
    },
  },
})

const ColorMediaWrap = styled('div', {
  padding: 0,
  borderRadius: '$rMax',
  overflow: 'hidden',
  border: '1px solid transparent',
  variants: {
    stroke: {
      true: {
        border: '1px solid $gray_light_second',
      },
      false: {
        border: '1px solid transparent',
      },
    },
    size: {
      M: {
        height: 18,
        width: 18,
      },
      L: {
        height: 22,
        width: 22,
      },
    },
  },
})

export type SectionProductConversionAreaProps = {
  productId: string
  products: Record<string, ShopifyProduct>
  colorsMap: ProductColorType[]
  titlePosition?: 'top' | 'right'
  size?: 'M' | 'L'
  className?: string
  asLinks?: boolean
  handleColorChange?: (
    product: ShopifyProduct,
    event: MouseEvent<HTMLButtonElement>,
  ) => void
}

export const ProductColorPicker: FC<SectionProductConversionAreaProps> = ({
  productId,
  products,
  colorsMap,
  className,
  asLinks = false,
  handleColorChange,
  titlePosition = 'top',
  size = 'M',
}) => {
  const productData: ShopifyProduct | undefined = products[productId]
  const relatedColorProducts = findRelatedProducts(
    productData?.title,
    products,
  ).filter((product) => product.id === productId || !product.hideSwatch)
  const { t } = useTranslation('product')

  const currentColor = colorsMap.find(
    (color) => color.shopifyId === productData?.colors?.value,
  )
  return (
    <Root titlePosition={titlePosition} className={className}>
      <ColorTitleLabel>
        {currentColor?.label
          ? t('selectedColorLabel', { value: currentColor?.label })
          : ''}
      </ColorTitleLabel>
      <ColorPicker
        role="radiogroup"
        aria-label="Select product's color"
        size={size}
      >
        {relatedColorProducts.map((productColor) => {
          const definedColor = colorsMap.find(
            (color) => color.shopifyId === productColor.colors?.value,
          )

          if (definedColor && asLinks) {
            return (
              <Color
                as={Link as React.ElementType}
                href={`${ROUTE_BASE_PRODUCTS}/${productColor.handle}`}
                appearance="flex"
                key={productColor.id}
                selected={productData.colors?.value === definedColor.shopifyId}
                aria-label={t('colorLabel', { value: definedColor.label })}
                size={size}
                role="radio"
              >
                <ColorMediaWrap size={size} stroke={definedColor.withStroke}>
                  <Media
                    {...definedColor.image}
                    hardcropRatio="square"
                    sizes="24px"
                    alt={t('colorLabel', { value: definedColor.label }) || ''}
                  />
                </ColorMediaWrap>
              </Color>
            )
          }

          if (definedColor) {
            return (
              <Color
                role="radio"
                key={productColor.id}
                onClick={(e) => {
                  handleColorChange && handleColorChange(productColor, e)
                }}
                selected={productData.colors?.value === definedColor.shopifyId}
                aria-selected={
                  productData.colors?.value === definedColor.shopifyId
                }
                aria-label={
                  t('colorLabel', { value: definedColor.label }) ?? ''
                }
                size={size}
                aria-checked={
                  productData.colors?.value === definedColor.shopifyId
                }
              >
                <ColorMediaWrap size={size} stroke={definedColor.withStroke}>
                  <Media
                    {...definedColor.image}
                    hardcropRatio="square"
                    sizes="18px"
                    layout="fill"
                    alt={t('colorLabel', { value: definedColor.label }) || ''}
                  />
                </ColorMediaWrap>
              </Color>
            )
          }

          return null
        })}
      </ColorPicker>
    </Root>
  )
}
