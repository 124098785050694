import { ShopifyResolvedCartLine } from '@aether/shopify-sdk'
import { createItem } from './createItem'
import { GTMResetEcommerce } from './gtmResetEcommerce'

export const GTMRemoveFromCart = (
  cartLine: ShopifyResolvedCartLine | null,
  quantity: number,
) => {
  if (!cartLine) {
    return
  }

  GTMResetEcommerce()

  const w = window as any

  w.dataLayer = w.dataLayer || []
  w.dataLayer.push({
    event: 'remove_from_cart',
    ecommerce: {
      items: [
        createItem({
          productTitle: cartLine.merchandise.product.title,
          currency: cartLine.merchandise.price.currencyCode,
          index: 0,
          variantTitle: cartLine.merchandise.title,
          priceAmount: cartLine.merchandise.price.amount,
          quantity,
          sku: cartLine.merchandise.sku ?? undefined,
        }),
      ],
    },
  })
}
