import { PageBuilderSection, PageResponse } from '@aether/models'

/**
 * For Journal Category and Landing Pages we add alternating "left" / "light" sectionPostPreview alignment.
 * TODO: handle with GROQ when collection item "index" is supported
 */
export const withJournalAlignedPosts = (
  pageData?: PageResponse<PageBuilderSection[]>,
): PageResponse<PageBuilderSection[]> | undefined => {
  if (!pageData) {
    return pageData
  }
  if (
    pageData?.type === 'journalLandingPage' ||
    pageData?.type === 'journalCategory'
  ) {
    return {
      ...pageData,
      pageBuilder: pageData?.pageBuilder?.map((section, index) => {
        if (section._type === 'sectionPostPreview') {
          return {
            ...section,
            imagePosition: index % 2 ? 'right' : 'left',
          }
        }
        return section
      }),
    }
  }

  return pageData
}
