import React, { FC } from 'react'
import { keyframes, styled } from '@aether/styles'

const rotate = keyframes({
  '0%': { transform: 'rotate(0)' },
  '100%': { transform: 'rotate(360deg)' },
})

const Spinner = styled('span', {
  display: 'block',
  alignSelf: 'center',
  justifySelf: 'center',
  content: '',
  width: '20px',
  height: '20px',
  margin: 'auto',
  border: '2px solid transparent',
  borderTopColor: 'currentColor',
  borderRightColor: 'currentColor',
  borderBottomColor: 'currentColor',
  borderRadius: '50%',
  animation: `${rotate} 0.7s linear infinite`,
})

export const Loader: FC<{ className?: string }> = ({ className }) => {
  return <Spinner className={className} />
}
