import { styled } from '@aether/styles'
import { MediaFieldType, PageLink } from '@aether/models'
import { MediaLink } from '../MediaLink/MediaLink'
import { PortableTextTypeComponent } from '@portabletext/react'

const Wrap = styled('div', {
  $containerSpace: 'small',
})
const ImageContainer = styled('figure', {
  variants: {
    size: {
      fullWidth: {
        width: '100%',
      },
      small: {
        maxWidth: '160px',
      },
    },
    position: {
      left: {
        marginRight: 'auto',
      },
      right: {
        marginLeft: 'auto',
      },
      center: {
        margin: '0 auto',
      },
    },
  },
})

type Props = {
  _type: string
  img?: MediaFieldType
  link?: PageLink
  size?: 'fullWidth' | 'small'
  position?: 'left' | 'right' | 'center'
}

export const PortableTextImage: PortableTextTypeComponent<Props> = ({
  value,
}) => {
  const { img, link, size = 'small', position = 'left' } = value || {}

  const linkProps: PageLink | undefined = link
    ? {
        href: link?.href,
        download: link?.download,
        action: link?.action,
      }
    : undefined

  const imgSizes = (() => {
    if (size === 'small') {
      return '160px'
    }
    return '100vw'
  })()

  return img ? (
    <Wrap>
      <ImageContainer size={size} position={position}>
        <MediaLink
          media={{ ...img, sizes: { default: imgSizes } }}
          link={linkProps}
        />
      </ImageContainer>
    </Wrap>
  ) : null
}
