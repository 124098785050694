import { CallbackTextResult, TextResults } from '@aether/search/ui-text-results'
import { useHits } from 'react-instantsearch-hooks-web'
import { useEffect } from 'react'

interface SuggestionsHitsProps {
  onHitClick: (value: string) => void
  amountChangeHandler?: (value: number) => void
}

export const SuggestionsHits = ({
  onHitClick,
  amountChangeHandler,
}: SuggestionsHitsProps) => {
  const { hits, results: hitsResults } = useHits()

  const results: CallbackTextResult[] = hits
    // we want to filter out suggestions that are the same as query
    .filter(
      (hit) => hit.objectID.toLowerCase() !== hitsResults?.query.toLowerCase(),
    )
    .map((hit) => ({
      type: 'callback',
      label: hit.objectID,
      onClick: () => onHitClick(hit.objectID),
    }))

  useEffect(() => {
    if (amountChangeHandler) {
      amountChangeHandler(results.length)
    }
  }, [amountChangeHandler, results.length])

  return <TextResults title="Suggestions" results={results} />
}
