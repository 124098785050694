import { createRichTextProjection } from './projections/createRichTextProjection'
import { getLocaleRegionIdFromPath } from '@aether/utils'
import { groq } from 'next-sanity'
import { getClient } from './getClient'
import { SiteConfiguration } from '@aether/models'
import { coalesceLocaleField } from './helpers/coalesceLocaleField'
import { DEFAULT_REGION_ID } from '@aether/configs'
import { createLinkProjection } from './projections/createLinkProjection'
import { createMediaProjection } from './projections/createMediaProjection'
import { createColorsMapProjection } from './projections/createColorsMapProjection'
import { createIconImageProjection } from './projections/createIconImageProjection'
import { createSEOProjection } from './projections/createSEOProjection'
import { createSizeGuideProjection } from './projections/createSizeGuideProjection'
import { createFreeShippingWidgetProjection } from './projections/createFreeShippingWidgetProjection'
import { createBasePerformanceFeatureProjection } from './projections/createBasePerformanceFeatureProjection'
import { createTemperaturePerformanceFeatureProjection } from './projections/createTemperaturePerformanceFeatureProjection'

export const getSiteConfiguration = async (
  localeRegionString: string,
  preview = false,
): Promise<SiteConfiguration> => {
  const [regionId, localeId] = getLocaleRegionIdFromPath(localeRegionString)

  const LINK_PROJECTION = createLinkProjection(localeId)
  const RICH_TEXT_PROJECTION = createRichTextProjection(localeId)
  const MEDIA_PROJECTION = createMediaProjection(localeId)
  const COLORS_MAP_PROJECTION = createColorsMapProjection(localeId)
  const SIZE_GUIDE_MAP_PROJECTION = createSizeGuideProjection(localeId)
  const FREE_SHIPPING_WIDGET_PROJECTION =
    createFreeShippingWidgetProjection(localeId)
  const BASE_PERFORMANCE_FEATURE_PROJECTION =
    createBasePerformanceFeatureProjection(localeId)
  const TEMPERATURE_PERFORMANCE_FEATURE_PROJECTION =
    createTemperaturePerformanceFeatureProjection(localeId)

  const cartQuery = groq`
  *[_type == "cart"] {
    emptyCartLinks[] {
      link${LINK_PROJECTION},
      ${coalesceLocaleField('label', localeId)},
    },
    ${coalesceLocaleField('discountLabel', localeId)},
    enablePromotion,
    discounts[]{
      tierThreshold,
      ${coalesceLocaleField('awayFromLabel', localeId)},
      ${coalesceLocaleField('unlockedDiscountLabel', localeId)},
    },
    nonDiscountedProductTypes[],
    nonDiscountedVendors[],
    ${coalesceLocaleField('saleLabel', localeId)},
    saleLabelColor
  }[0]
`

  const searchQuery = groq`
  *[_type == "search"] {
    ${coalesceLocaleField('emptySearchLinksTitle', localeId)},
    emptySearchLinks[] {
      link${LINK_PROJECTION},
      ${coalesceLocaleField('label', localeId)},
    }
  }[0]
`

  const headerQuery = groq`
  *[_type == "header"] {
    "headerNavigation": coalesce(headerNavigation_${regionId}, headerNavigation_${DEFAULT_REGION_ID}) -> {
      tabs[] {
        ${coalesceLocaleField('title', localeId)},
        link${LINK_PROJECTION},
        image{
          image${MEDIA_PROJECTION},
          ${coalesceLocaleField('label', localeId)},
          link${LINK_PROJECTION},
        },
        linkGroups[] {
          ${coalesceLocaleField('title', localeId)},
          groupLink${LINK_PROJECTION},
          links[] {
            "type": itemType,
            itemType == 'link' => {
              "payload": {
                "link": link${LINK_PROJECTION},
                ${coalesceLocaleField('label', localeId)},
                isHighlighted
              },
            },
            itemType == 'divider' => {
              "payload": null,
            }
          }
        }
      },
      notificationHeader{
        ${coalesceLocaleField('leftText', localeId)},
        ${coalesceLocaleField('centerText', localeId)}[]${RICH_TEXT_PROJECTION},

      }
    }
  }[0].headerNavigation
`
  const footerQuery = groq`
    *[_type == "footer"] {
        ...coalesce(footerNavigation_${regionId}, footerNavigation_${DEFAULT_REGION_ID}) -> {
          ${coalesceLocaleField('bottomText', localeId)},
          linkGroups[] {
          ${coalesceLocaleField('title', localeId)},
          links[] {
            "type": itemType,
            itemType == 'link' => {
              "payload": {
                "link": link${LINK_PROJECTION},
                ${coalesceLocaleField('label', localeId)}
              },
            },
            itemType == 'divider' => {
              "payload": null,
            }
          }
        },
        footerLinks[] {
          link${LINK_PROJECTION},
          ${coalesceLocaleField('label', localeId)}
      },
      },
    }[0]
`

  const newsletterQuery = groq`
    *[_type == "newsletter"] {
      ${coalesceLocaleField('newsletterHeading', localeId)},
      ${coalesceLocaleField('newsletterLegalText', localeId)},
      klaviyoEmbedCode,
      ${coalesceLocaleField('storesHeading', localeId)},
      stores[] {
        link {
          link${LINK_PROJECTION},
          ${coalesceLocaleField('label', localeId)}
        },
        image${MEDIA_PROJECTION}
      }
    }[0]
    `

  const technicalFeaturesMapQuery = groq`
    *[_type == "techFeature"] {
      shopifyId,
      ${coalesceLocaleField('label', localeId)},
      ${coalesceLocaleField('description', localeId)},
      "image":Image${createIconImageProjection(localeId)}
    }
  `

  const performanceFeaturesMapQuery = groq`{
    "performanceWaterproof": *[ !(_id in path('drafts.**')) && _type == "performanceWaterproof"][0]${BASE_PERFORMANCE_FEATURE_PROJECTION},
    "performanceWindproof": *[ !(_id in path('drafts.**')) && _type == "performanceWindproof"][0]${BASE_PERFORMANCE_FEATURE_PROJECTION},
    "performanceInsulation": *[ !(_id in path('drafts.**')) && _type == "performanceInsulation"][0]${BASE_PERFORMANCE_FEATURE_PROJECTION},
    "performancePowderRating": *[ !(_id in path('drafts.**')) && _type == "performancePowderRating"][0]${BASE_PERFORMANCE_FEATURE_PROJECTION},
    "performanceMotoRating": *[ !(_id in path('drafts.**')) && _type == "performanceMotoRating"][0]${BASE_PERFORMANCE_FEATURE_PROJECTION},
    "performanceMovementRating": *[ !(_id in path('drafts.**')) && _type == "performanceMovementRating"][0]${BASE_PERFORMANCE_FEATURE_PROJECTION},
    "performanceTemperature": *[ !(_id in path('drafts.**')) && _type == "performanceTemperature"][0]${TEMPERATURE_PERFORMANCE_FEATURE_PROJECTION},
    }`

  const temperaturesMapQuery = groq`
    *[_type == "temperature"] {
      shopifyId,
      ${coalesceLocaleField('label', localeId)},
      "image":Image${createIconImageProjection(localeId)}
    }
  `
  const colorGroupsMapQuery = groq`
    *[_type == "colorGroup"] {
      withStroke,
      shopifyId,
      ${coalesceLocaleField('label', localeId)},
      "image":Image${createIconImageProjection(localeId)}
    }
  `

  const seoQuery = groq`
    *[_type == "seo"]${createSEOProjection(localeId)}[0]
  `

  const vipQuery = groq`
    *[_type == "vip"] {
       vipLinks[] {
         link${LINK_PROJECTION},
         ${coalesceLocaleField('label', localeId)}
      }
      }[0]
    `

  const siteConfigurationQuery = groq`{
    "header": ${headerQuery},
    "footer": ${footerQuery},
    "newsletter": ${newsletterQuery},
    "cart": ${cartQuery},
    "search":${searchQuery},
    "colorsMap": ${COLORS_MAP_PROJECTION},
    "sizeGuides": ${SIZE_GUIDE_MAP_PROJECTION},
    "freeShippingWidget": ${FREE_SHIPPING_WIDGET_PROJECTION},
    "technicalFeaturesMap": ${technicalFeaturesMapQuery},
    "performanceFeaturesMap": ${performanceFeaturesMapQuery},
    "temperaturesMap": ${temperaturesMapQuery},
    "colorGroupsMap": ${colorGroupsMapQuery},
    "seo": ${seoQuery},
    "vip": ${vipQuery},
  }

  `

  const siteConfigurationData = await getClient(preview).fetch(
    siteConfigurationQuery,
  )

  return {
    ...siteConfigurationData,
  }
}
