import { FC, PropsWithChildren } from 'react'
import { styled } from '@aether/styles'
import { InView } from 'react-intersection-observer'

const List = styled('ul', {
  display: 'grid',
  gridRowGap: '$sectionSpaceMobile',
  $lin: {
    gridColumnGap: ['$20', '$144'],
  },
  gridAutoFlow: 'row',
  '@md': {
    gridRowGap: '$sectionSpaceDesktop',
    height: '100%',
    gridTemplateColumns: 'repeat(2, minmax(290px, 1fr))',
  },
})

const StyledContainer = styled('div', {
  position: 'relative',
  overflow: 'hidden',
  width: '100%',
  $containerSpace: 'none',
  '@md': {
    overflow: 'unset',
    $containerSpace: 'medium',
  },
  '@xl': {
    $containerSpace: 'medium',
  },
  '@xxl': {
    $containerSpace: 'xlarge',
  },
})

const CardPlaceholderImage = styled('div', {
  overflow: 'hidden',
  height: 0,
  paddingTop: '129.99110056363097%',
  background: '$gray_light',
})

const CardPlaceholderData = styled('div', {
  height: '99.2px',
  background: '$gray_light',
  '@md': {
    height: '87.2px',
  },
})
const CardPlaceholderContainer = styled('div', {
  display: 'grid',
  gridAutoFlow: 'row',
  gridRowGap: '$20',
  $containerSpace: 'medium',
  '@md': {
    $containerSpace: 'none',
  },
})

const InViewWrapper = styled('div', {
  height: '15px',
})

type Props = {
  getMore?: () => void
  isLoading?: boolean
}

export const InfiniteProductsListItem: FC<PropsWithChildren> = ({
  children,
}) => {
  return <li>{children}</li>
}

export const InfiniteProductsList: FC<PropsWithChildren<Props>> = ({
  getMore,
  isLoading,
  children,
}) => {
  return (
    <StyledContainer>
      <List>
        {children}
        {isLoading &&
          new Array(4).fill(0).map((_, index) => (
            <InfiniteProductsListItem>
              <CardPlaceholderContainer>
                <CardPlaceholderImage key={`list-item-placeholder-${index}`} />
                <CardPlaceholderData />
              </CardPlaceholderContainer>
            </InfiniteProductsListItem>
          ))}
      </List>
      <InView
        as="div"
        rootMargin={'400px 0px 0px 0px'}
        onChange={(inView) => {
          inView && getMore && getMore()
        }}
      >
        <InViewWrapper />
      </InView>
    </StyledContainer>
  )
}
