import { ShopifyResolvedCartLine } from '@aether/shopify-sdk'
import { createItem } from './createItem'

export const GtmSendP2RCartData = (
  amount: string,
  cartLines?: ShopifyResolvedCartLine[],
) => {
  const w = window as any

  w.dataLayer = w.dataLayer || []
  w.dataLayer.push({ p2r_cart: null })

  w.dataLayer.push({
    event: 'send_p2r_cart_data',
    p2r_cart: {
      value: parseFloat(amount),
      items: cartLines?.map((cartLine, index) => {
        return createItem({
          productTitle: cartLine.merchandise.product.title,
          currency: cartLine.merchandise.price.currencyCode,
          index,
          variantTitle: cartLine.merchandise.title,
          priceAmount: cartLine.merchandise.price.amount,
          quantity: cartLine.quantity,
          sku: cartLine.merchandise.sku ?? undefined,
        })
      }),
    },
  })
}
