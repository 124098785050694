import React, { FC } from 'react'

type Props = {
  className?: string
  size?: 'default' | 'small'
}

export const ArrowLeft: FC<Props> = ({ className, size = 'default' }) => {
  const [width, height] = (() => {
    switch (size) {
      default:
        return [20, 14]
    }
  })()

  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 20 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.37 12.97C6.57 13.17 6.88 13.17 7.08 12.97C7.28 12.77 7.28 12.46 7.08 12.26L1.87 7.06H19.27C19.55 7.06 19.77 6.84 19.77 6.56C19.77 6.28 19.55 6.06 19.27 6.06H1.95L7.16 0.85C7.36 0.65 7.36 0.34 7.16 0.14C7.06 0.05 6.93 0 6.81 0C6.69 0 6.55 0.05 6.46 0.15L0 6.6L6.37 12.98V12.97Z"
        fill="black"
      />
    </svg>
  )
}
