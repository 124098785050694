import { coalesceLocaleField } from '../helpers/coalesceLocaleField'
import { groq } from 'next-sanity'
import { LocaleId } from '@aether/configs'

export const createFreeShippingWidgetProjection = (localeId: LocaleId) => {
  return groq`
        *[_type == "freeShippingWidget"] {
            ${coalesceLocaleField('freeShippingLabel', localeId)},
        }[0]
  `
}
