import { createUtilityPageProjection } from '../projections/createUtilityPageProjection'
import { createDefaultPageProjection } from '../projections/createDefaultPageProjection'
import { getLocaleRegionIdFromPath } from '@aether/utils'
import { groq } from 'next-sanity'
import { getClient } from '../getClient'
import { PageResponse, PageBuilderSection } from '@aether/models'
import { withJournalAlignedPosts } from '../helpers/withJournalAliignedPosts'
import { createStorePageProjection } from '../projections/createStorePageProjection'
import { createStoresLandingPageProjection } from '../projections/createStoresLandingPageProjection'
import { createJournalCategoryPageProjection } from '../projections/createJournalCategoryPageProjection'
import { createJournalPostPageProjection } from '../projections/createJournalPostPageProjection'
import { createJournalLandingPageProjection } from '../projections/createJournalLandingPageProjection'

export const getRouteByPath = async (
  path: string,
  localeRegionString: string,
  pageType: string,
  preview = false,
): Promise<PageResponse<PageBuilderSection[]> | undefined> => {
  const [regionId, localeId] = getLocaleRegionIdFromPath(localeRegionString)
  const JOURNAL_CATEGORY_PAGE_PROJECTION =
    createJournalCategoryPageProjection(localeId)
  const JOURNAL_POST_PAGE_PROJECTION = createJournalPostPageProjection(localeId)
  const JOURNAL_LANDING_PAGE_PROJECTION =
    createJournalLandingPageProjection(localeId)
  const STORE_PAGE_PROJECTION = createStorePageProjection(localeId)
  const STORES_LANDING_PAGE_PROJECTION =
    createStoresLandingPageProjection(localeId)
  const UTILITY_PAGE_PROJECTION = createUtilityPageProjection(localeId)
  const DEFAULT_PAGE_PROJECTION = createDefaultPageProjection(
    regionId,
    localeId,
  )

  const PAGE_PROJECTION = (() => {
    switch (pageType) {
      case 'route':
        return DEFAULT_PAGE_PROJECTION
      case 'journalCategory':
        return JOURNAL_CATEGORY_PAGE_PROJECTION
      case 'journalPost':
        return JOURNAL_POST_PAGE_PROJECTION
      case 'journalLandingPage':
        return JOURNAL_LANDING_PAGE_PROJECTION
      case 'store':
        return STORE_PAGE_PROJECTION
      case 'storesLandingPage':
        return STORES_LANDING_PAGE_PROJECTION
      case 'utilityPage':
        return UTILITY_PAGE_PROJECTION
    }
  })()

  const query = groq`*[!(_id in path('drafts.**')) && defined(path.current) && path.current == "${path}"][0]{
    "page": ${PAGE_PROJECTION}
  }.page`

  const pageData: PageResponse<PageBuilderSection[]> = await getClient(
    preview,
  ).fetch(query)

  return withJournalAlignedPosts(pageData)
}
