import { styled } from '@aether/styles'
import { MouseEventHandler, FC } from 'react'
import { Mute, UnMute } from '@aether/icons'

type VideoAudioButtonProps = {
  isMuted: boolean
  onRequestMute: MouseEventHandler<HTMLButtonElement>
  onRequestUnmute: MouseEventHandler<HTMLButtonElement>
  isDisabled: boolean
}
const ControlButton = styled('button', {
  padding: 0,
  margin: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '$32',
  height: '$32',
  background: 'transparent',
  zIndex: '$videoControls',
  borderRadius: '$rMax',
  filter: 'drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.8))',
  '&:hover': {
    cursor: 'pointer',
  },
  '&:disabled': {
    opacity: '0.6',
  },

  $focusStyle: 'default',
})

export const VideoAudioButton: FC<VideoAudioButtonProps> = ({
  isMuted,
  onRequestMute,
  onRequestUnmute,
  isDisabled,
}) => {
  return isMuted ? (
    <ControlButton
      onClick={onRequestUnmute}
      aria-label="unmute"
      disabled={isDisabled}
    >
      <UnMute />
    </ControlButton>
  ) : (
    <ControlButton onClick={onRequestMute} aria-label="mute">
      <Mute />
    </ControlButton>
  )
}
