import { LocaleId } from '@aether/configs'
import { groq } from 'next-sanity'
import { coalesceLocaleField } from '../helpers/coalesceLocaleField'
import { createJournalCategoriesProjection } from './createJournalCategoriesProjection'
import { createJournalPictogramProjection } from './createJournalPictogramProjection'
import { createJournalPostProjection } from './createJournalPostProjection'
import { createSEOProjection } from './createSEOProjection'

export const createJournalCategoryPageProjection = (localeId: LocaleId) => {
  const POST_PROJECTION = createJournalPostProjection(localeId)
  const CATEGORIES_PROJECTION = createJournalCategoriesProjection(localeId)
  const PICTOGRAM_PROJECTION = createJournalPictogramProjection(localeId)
  const SEO_PROJECTION = createSEOProjection(localeId)

  return groq`{
        "type":_type,
        "hideHeader": false,
        "hideFooter": false,
        "headerAppearance": 'journal',
        "isHeaderStatic": true,
        "seo": seo${SEO_PROJECTION},
        "pageBuilder": [
           {
              "_type": "sectionJournalHeader",
              "_key": "sectionJournalHeader" + _id,
              "categories": ${CATEGORIES_PROJECTION},
              "postsCount": count(*[ !(_id in path('drafts.**')) && _type == "journalPost" && ^._id in categories[]._ref]),
              "pictogram": pictogram -> ${PICTOGRAM_PROJECTION},
              "caption": *[ !(_id in path('drafts.**')) && _type == "journalConfiguration" ][0] {
                 ${coalesceLocaleField('headerCaption', localeId)},
               }.headerCaption
           },
           ...*[ !(_id in path('drafts.**')) && _type == "journalPost" && ^._id in categories[]._ref ] | order(date desc) {
               "_type": "sectionPostPreview",
               "_key": "sectionPostPreview" + _id,
               "post": ${POST_PROJECTION},
               "pageContext":"journal",
           }
        ]
    }`
}
