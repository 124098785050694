import { groq } from 'next-sanity'
import { LocaleId } from '@aether/configs'
import { createRichTextProjection } from './createRichTextProjection'
import { createMediaProjection } from './createMediaProjection'
import { coalesceLocaleField } from '../helpers/coalesceLocaleField'

export const createJournalPostProjection = (localeId: LocaleId) => {
  const RICH_TEXT_PROJECTION = createRichTextProjection(localeId)
  const MEDIA_PROJECTION = createMediaProjection(localeId)

  return groq`{
      _id,
      _type,
      ${coalesceLocaleField('title', localeId)},
      ${coalesceLocaleField(
        'shortDescription',
        localeId,
      )}[]${RICH_TEXT_PROJECTION},
      background${MEDIA_PROJECTION},
      "path": path.current,
      date,
      imageOrientation,
      ${coalesceLocaleField('ctaLabel', localeId)},
      "categories": categories[] -> {
        "path": path.current,
        ${coalesceLocaleField('title', localeId)},
    },
  }`
}
