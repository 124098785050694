import { getLocaleRegionIdFromPath } from '@aether/utils'
import { gql } from 'graphql-request'
import { createShopifyClient } from '..'
import { captureException } from '@sentry/nextjs'

export type ProductOptionsNode = {
  id: string
  options: { name: string; values: string[] }[]
}

export type ProductOptionsRes = {
  products: {
    pageInfo: {
      hasNextPage: boolean
      endCursor: string
    }
    nodes: ProductOptionsNode[]
  }
}

type CollectionOptionsQueryRes = {
  collection: ProductOptionsRes
}

const COLLECTION_QUERY = (endCursor?: string) => gql`
  fragment ProductOptionsFragment on Product {
    id
    options {
      name
      values
    }
  }


  query ($slug: String!, $regionId: CountryCode!)
  @inContext(country: $regionId) {
    collection(handle: $slug) {
      products(first: 250, ${endCursor ? `after: "${endCursor}"` : ''}) {
        pageInfo {
          hasNextPage
          endCursor
        }
        nodes {
          ...ProductOptionsFragment
        }
      }
    }
  }
`

const getAllCollectionProductOptions = async (
  locale: string,
  slug: string,
  endCursor?: string,
  prevProducts?: ProductOptionsRes['products']['nodes'],
): Promise<ProductOptionsRes | null> => {
  try {
    const [regionId, localeId] = getLocaleRegionIdFromPath(locale)
    const shopifyClient = createShopifyClient(localeId)

    const collectionRes =
      await shopifyClient.request<CollectionOptionsQueryRes>(
        COLLECTION_QUERY(endCursor),
        {
          slug,
          regionId,
          endCursor,
        },
      )

    const hasNextPage =
      collectionRes?.collection?.products?.pageInfo?.hasNextPage
    const newEndCursor =
      collectionRes?.collection?.products?.pageInfo?.endCursor
    const currentProductsNodes =
      collectionRes?.collection?.products?.nodes || []
    const mergedProductsNodes: ProductOptionsRes['products']['nodes'] = [
      ...(prevProducts ? prevProducts : []),
      ...currentProductsNodes,
    ]

    if (hasNextPage) {
      return getAllCollectionProductOptions(
        locale,
        slug,
        newEndCursor,
        mergedProductsNodes,
      )
    }

    return {
      products: {
        ...collectionRes.collection.products,
        nodes: mergedProductsNodes,
      },
    }
  } catch (error) {
    captureException(error)
    return null
  }
}

export const getShopifyCollectionOptions = async (
  locale: string,
  slug: string,
): Promise<ProductOptionsNode[]> => {
  try {
    const collectionRes = await getAllCollectionProductOptions(locale, slug)
    const productsOptionsNodes = collectionRes?.products?.nodes || []
    return productsOptionsNodes
  } catch (e) {
    captureException(e)
    return []
  }
}
