import { FC } from 'react'
import {
  StyledClickable,
  StyledClickableProps,
} from '../StyledClickable/StyledClickable'

type ButtonProps = StyledClickableProps & {
  disabled?: boolean
  type?: 'button' | 'submit' | 'reset'
}

export const Button: FC<ButtonProps> = ({
  children,
  appearance = 'primary',
  ...restProps
}) => {
  return (
    <StyledClickable appearance={appearance} {...restProps}>
      {children}
    </StyledClickable>
  )
}
