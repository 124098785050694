import { coalesceLocaleField } from '../helpers/coalesceLocaleField'
import { groq } from 'next-sanity'
import { LocaleId } from '@aether/configs'
import { createMediaProjection } from '../projections/createMediaProjection'
import { createLinkProjection } from '../projections/createLinkProjection'

export const createSectionHalfImageProjection = (localeId: LocaleId) => {
  const LINK_PROJECTION = createLinkProjection(localeId)
  const MEDIA_PROJECTION = createMediaProjection(localeId)

  return groq`{
        _key,
        _type,
        ${coalesceLocaleField('imageTitle', localeId)},
        ${coalesceLocaleField('secondTitle', localeId)},
        ${coalesceLocaleField('ctaGroupTitle', localeId)},
        image${MEDIA_PROJECTION},
        links[]{
          ${coalesceLocaleField('ctaLabel', localeId)},
          ctaLink${LINK_PROJECTION},
        }
      }
  `
}
