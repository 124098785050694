import {
  ContainerSpaceType,
  SectionColorType,
  ContainerSizeType,
} from '@aether/models'
import { styled } from '@aether/styles'
import React, { forwardRef, PropsWithChildren } from 'react'

const Wrap = styled('div', {
  width: '100%',

  variants: {
    sectionColor: {
      gray: {
        backgroundColor: '$gray_light',
        paddingTop: '$sectionSpaceMobile',
        paddingBottom: '$sectionSpaceMobile',
        '@lg': {
          paddingTop: '$sectionSpaceDesktop',
          paddingBottom: '$sectionSpaceDesktop',
        },
      },
      beige: {
        backgroundColor: '$beige',
        paddingTop: '$sectionSpaceMobile',
        paddingBottom: '$sectionSpaceMobile',
        '@lg': {
          paddingTop: '$sectionSpaceDesktop',
          paddingBottom: '$sectionSpaceDesktop',
        },
      },
      default: {},
    },
    spaceSize: {
      none: {
        $containerSpace: 'none',
      },
      small: {
        $containerSpace: 'small',
      },
      medium: {
        $containerSpace: 'medium',
      },
      large: {
        $containerSpace: 'large',
      },
      xlarge: {
        $containerSpace: 'xlarge',
      },
    },
    size: {
      S: {
        $containerSize: 'S',
      },
      textReadability: {
        $containerSize: 'textReadability',
      },
      L: {
        $containerSize: 'L',
      },
      XL: {
        $containerSize: 'XL',
      },
      fullWidth: {
        $containerSize: 'fullWidth',
      },
    },
  },
})

type Props = PropsWithChildren<{
  id?: string
  className?: string
  spaceSize?: ContainerSpaceType
  size?: ContainerSizeType
  as?: keyof HTMLElementTagNameMap
  sectionColor?: SectionColorType
}>

export const Container = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const {
    children,
    className,
    spaceSize = 'small',
    size = 'fullWidth',
    as = 'div',
    id = '',
    sectionColor = 'default',
  } = props
  return (
    <Wrap
      ref={ref}
      className={className}
      spaceSize={spaceSize}
      size={size}
      as={as}
      id={id}
      sectionColor={sectionColor}
    >
      {children}
    </Wrap>
  )
})
