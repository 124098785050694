import { coalesceLocaleField } from '../helpers/coalesceLocaleField'
import { groq } from 'next-sanity'
import { LocaleId } from '@aether/configs'

export const createBasePerformanceFeatureProjection = (localeId: LocaleId) => {
  return groq`
      {
      ${coalesceLocaleField('title', localeId)},
      ${coalesceLocaleField('description', localeId)},
      values[]{
        shopifyId,
        ${coalesceLocaleField('title', localeId)},
        ${coalesceLocaleField('subtitle', localeId)},
      }
     }`
}
