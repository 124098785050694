import React, { FC, PropsWithChildren } from 'react'
import { styled } from '@aether/styles'

const commonStyles = {
  $aetherFont: 'body02',
  '*:last-child': {
    marginBottom: 0,
  },
  '*:first-child': {
    marginTop: 0,
  },
  strong: {
    $aetherFont: 'heading08',
  },
  em: {
    fontStyle: 'italic',
  },
  a: {
    textDecoration: 'underline',
  },
  blockquote: {
    $aetherFont: 'heading07',
    marginBottom: '$24',
    marginTop: '$24',
  },
  h2: {
    $aetherFont: 'heading04',
    marginBottom: '$24',
    marginTop: '$24',
  },
  h3: {
    $aetherFont: 'heading06',
    marginBottom: '$24',
    marginTop: '$24',
  },
  'p, ul, ol': {
    listStyle: 'inside',
    $aetherFont: 'body02',
    marginBottom: '$24',
    marginTop: '$24',
  },
  'ol > li': {
    listStyleType: 'decimal',
  },
  'ul > li': {
    listStyleType: 'disc',
  },
} as const

const Wrap = styled('div', {
  variants: {
    theme: {
      default: {
        ...commonStyles,
      },
      collection: {
        ...commonStyles,
        p: {
          $aetherFont: 'body02',

          '@md': {
            $aetherFont: 'body01',
          },
        },
      },
    },
  },
})

export const ShopifyDescriptionHTMLWrap: FC<
  PropsWithChildren<{ theme?: 'default' | 'collection' }>
> = ({ children, theme = 'default' }) => {
  return <Wrap theme={theme}>{children}</Wrap>
}
