import { FC } from 'react'
import { ProductHit } from '@aether/models'
import { DEFAULT_REGION_ID, normalizedRegions } from '@aether/configs'
import { PriceRange } from '../components/PriceRange/PriceRange'

export const ProductSearchResultPrice: FC<{ hit: ProductHit }> = ({ hit }) => {
  const currencyCode = normalizedRegions[DEFAULT_REGION_ID].currencyCode

  const maxVariantPrice = {
    amount: `${hit.variants_max_price}`,
    currencyCode: currencyCode,
  }
  const minVariantPrice = {
    amount: `${hit.variants_min_price}`,
    currencyCode: currencyCode,
  }

  const priceRange = {
    maxVariantPrice,
    minVariantPrice,
  }

  return <PriceRange range={priceRange} />
}
