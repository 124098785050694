import { styled } from '@aether/styles'
import { FC, PropsWithChildren } from 'react'

type SectionWrapperProps = {
  withBackground?: boolean
  className?: string
}

const Wrapper = styled('section', {
  margin: '$sectionSpaceMobile 0',
  '@lg': {
    margin: '$sectionSpaceDesktop 0',
  },
  variants: {
    withBackground: {
      true: {
        margin: 0,
      },
    },
  },
})

export const SectionWrapper: FC<PropsWithChildren<SectionWrapperProps>> = ({
  className,
  withBackground,
  children,
}) => {
  return (
    <Wrapper className={className} withBackground={withBackground}>
      {children}
    </Wrapper>
  )
}
