import { styled } from '@aether/styles'
import { FC, PropsWithChildren } from 'react'

const Root = styled('div', {
  display: 'grid',
})

// https://css-tricks.com/an-auto-filling-css-grid-with-max-columns/
export const MaxColsMinSizeGrid: FC<
  PropsWithChildren<{
    gridLayoutGap: string
    gridColumnCount: number
    gridItemMaxHeight?: string
    gridItemMinWidth: string
    className?: string
    as?: keyof JSX.IntrinsicElements
  }>
> = ({
  className,
  children,
  gridLayoutGap,
  gridColumnCount,
  gridItemMinWidth,
  gridItemMaxHeight,
  as = 'div',
}) => {
  const gridCount = `calc(${gridColumnCount} - 1)`
  const totalGapWidth = `calc(${gridCount} * ${gridLayoutGap})`
  const gridItemMaxWidth = `calc((100% - ${totalGapWidth}) / ${gridColumnCount})`

  return (
    <Root
      as={as}
      className={className}
      css={{
        gridGap: gridLayoutGap,
        gridTemplateColumns: `repeat(auto-fill, minmax(max(${gridItemMinWidth}, ${gridItemMaxWidth}), 1fr))`,
        gridAutoRows: gridItemMaxHeight,
      }}
    >
      {children}
    </Root>
  )
}
