import { coalesceLocaleField } from '../helpers/coalesceLocaleField'
import { groq } from 'next-sanity'
import { LocaleId } from '@aether/configs'

export const createSectionCollectionItemsProjection = (localeId: LocaleId) => {
  return groq`{
        _key,
        _type,
        ${coalesceLocaleField('title', localeId)},
        "collectionProductsAmount": products,
        "isLastCollectionSection": false,
      }
  `
}
