import { coalesceLocaleField } from '../helpers/coalesceLocaleField'
import { groq } from 'next-sanity'
import { LocaleId } from '@aether/configs'

export const createSectionShopTheProductsProjection = (localeId: LocaleId) => {
  return groq`{
        _key,
        _type,
        ${coalesceLocaleField('title', localeId)},
        pageContext,
        "shopifyIds": products[] -> {"id": store.gid, "type": "single-product"},
      }
  `
}
