import { FC } from 'react'
import { styled, useMediaQuery } from '@aether/styles'
import Div100vh from 'react-div-100vh'
import { Button } from '../../Button/Button'
import { Modal } from '../../Modal/Modal'
import { CloseCircle } from '@aether/icons'
import { useTranslation } from 'react-i18next'
import { ShopifyProduct } from '@aether/models'
import { ProductCard } from '../../ProductCard/ProductCard'

const LARGE_MEDIA_SIZE = '478px'

const ModalContent = styled('div', {
  padding: '$28 $24 0',
  '@md': {
    padding: '$24',
  },
})

const ModalHeader = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '$8 0 $16',
  borderTop: '1px solid $black',
  '@md': {
    padding: '$16 0',
  },
})

const StyledDiv100vh = styled(Div100vh, {
  overflow: 'auto',
  '@md': {
    width: LARGE_MEDIA_SIZE,
  },
})

const ModalTitle = styled('h2', {
  $aetherFont: 'heading03',
})

const ProductsListItem = styled('li', {
  listStyle: 'none',
  marginBottom: '$28',
})

type ProductsModalProps = {
  isModalOpen: boolean
  handleCloseModal: () => void
  products?: ShopifyProduct[]
}

export const ProductsModal: FC<ProductsModalProps> = ({
  isModalOpen,
  handleCloseModal,
  products = [],
}) => {
  const { t } = useTranslation('journal')
  const isMd = useMediaQuery('md')

  return (
    <Modal
      preventScroll={true}
      isOpen={isModalOpen}
      onRequestClose={handleCloseModal}
      size={isMd ? 'intrinsic' : 'stretch-x'}
      position={isMd ? 'right' : 'center'}
      transition={'slide-from-right'}
    >
      <StyledDiv100vh>
        <ModalContent>
          <ModalHeader>
            <ModalTitle>{t('modalTitle')}</ModalTitle>
            <Button appearance="flex" onClick={handleCloseModal}>
              <CloseCircle />
            </Button>
          </ModalHeader>
          <ul>
            {products?.map((product, index) => (
              <ProductsListItem key={`${product.id}-${index}`}>
                <ProductCard
                  product={product}
                  mediaSizes={{ lg: LARGE_MEDIA_SIZE, default: '100vw' }}
                />
              </ProductsListItem>
            ))}
          </ul>
        </ModalContent>
      </StyledDiv100vh>
    </Modal>
  )
}
