import { getSdk, SdkFunctionWrapper } from './dist/generatedSchemaSdk'
import { fetchWithRetry } from './fetchWithRetry'
import { GraphQLClient } from 'graphql-request'

const middleware: SdkFunctionWrapper = async (action) => {
  return await fetchWithRetry(() => action(), 5)
}

export const createShopifySdk = (shopifyClient: GraphQLClient) => {
  return getSdk(shopifyClient, middleware)
}
