import { coalesceLocaleField } from '../helpers/coalesceLocaleField'
import { groq } from 'next-sanity'
import { LocaleId } from '@aether/configs'
import { createMediaProjection } from '../projections/createMediaProjection'
import { createLinkProjection } from '../projections/createLinkProjection'

export const createSectionCollaborationsCardsProjection = (
  localeId: LocaleId,
) => {
  return groq`{
        _key,
        _type,
        ${coalesceLocaleField('title', localeId)},
        cards[]{
          _key,
          ${coalesceLocaleField('title', localeId)},
          media${createMediaProjection(localeId)},
          link${createLinkProjection(localeId)},
          ${coalesceLocaleField('linkText', localeId)},
        }
      }
  `
}
