import { coalesceLocaleField } from '../helpers/coalesceLocaleField'
import { groq } from 'next-sanity'
import { LocaleId } from '@aether/configs'
import { createMediaProjection } from '../projections/createMediaProjection'

export const createSectionDoublePromoProjection = (localeId: LocaleId) => {
  const MEDIA_PROJECTION = createMediaProjection(localeId)

  return groq`{
        _key,
        _type,
        sectionSpaceSize,
        cards[] {
          ${coalesceLocaleField('text', localeId)},
          image${MEDIA_PROJECTION},
          textPosition,
          textColor,
        }
      }
  `
}
