import { LocaleId } from '@aether/configs'
import { getLocaleRegionIdFromPath } from '@aether/utils'
import { groq } from 'next-sanity'
import { getClient } from '../getClient'
import { PageResponse, PageBuilderSection } from '@aether/models'
import { createJournalCategoryPageProjection } from '../projections/createJournalCategoryPageProjection'
import { createJournalPostPageProjection } from '../projections/createJournalPostPageProjection'
import { createJournalLandingPageProjection } from '../projections/createJournalLandingPageProjection'
import { createStorePageProjection } from '../projections/createStorePageProjection'
import { createStoresLandingPageProjection } from '../projections/createStoresLandingPageProjection'
import { createUtilityPageProjection } from '../projections/createUtilityPageProjection'
import { withJournalAlignedPosts } from '../helpers/withJournalAliignedPosts'
import { createDefaultPreviewPageProjection } from '../projections/createDefaultPreviewPageProjection'

export const createPageByDocumentIdQuery = (
  documentId: string,
  localeId: LocaleId,
) => {
  const JOURNAL_CATEGORY_PAGE_PROJECTION =
    createJournalCategoryPageProjection(localeId)
  const JOURNAL_POST_PAGE_PROJECTION = createJournalPostPageProjection(localeId)
  const JOURNAL_LANDING_PAGE_PROJECTION =
    createJournalLandingPageProjection(localeId)
  const STORE_PAGE_PROJECTION = createStorePageProjection(localeId)
  const STORES_LANDING_PAGE_PROJECTION =
    createStoresLandingPageProjection(localeId)
  const UTILITY_PAGE_PROJECTION = createUtilityPageProjection(localeId)
  const DEFAULT_PAGE_PROJECTION = createDefaultPreviewPageProjection(localeId)

  return groq`*[_id == "${documentId}"][0]{
    "page": select(
      _type == 'page' => ${DEFAULT_PAGE_PROJECTION},
      _type == "journalCategory" => ${JOURNAL_CATEGORY_PAGE_PROJECTION},
      _type == "journalPost" => ${JOURNAL_POST_PAGE_PROJECTION},
      _type == "journalLandingPage" => ${JOURNAL_LANDING_PAGE_PROJECTION},
      _type == "store" => ${STORE_PAGE_PROJECTION},
      _type == "storesLandingPage" => ${STORES_LANDING_PAGE_PROJECTION},
      _type == "utilityPage" => ${UTILITY_PAGE_PROJECTION},
    )
  }.page`
}

export const getPageByDocumentId = async (
  documentId: string,
  localeRegionString: string,
  preview = false,
): Promise<PageResponse<PageBuilderSection[]> | undefined> => {
  const [, localeId] = getLocaleRegionIdFromPath(localeRegionString)

  const query = createPageByDocumentIdQuery(documentId, localeId)

  const pageData: PageResponse<PageBuilderSection[]> | undefined =
    await getClient(preview).fetch(query)

  return withJournalAlignedPosts(pageData)
}
