import { createPageBuilderProjection } from './createPageBuilderProjection'
import { coalesceLocaleField } from '../helpers/coalesceLocaleField'
import { createLinkProjection } from './createLinkProjection'
import { createMediaProjection } from './createMediaProjection'
import { createRichTextProjection } from './createRichTextProjection'
import { groq } from 'next-sanity'
import { createIsHeaderStaticProjection } from './createIsHeaderStaticProjection'

export const createStorePageProjection = (localeId: string) => {
  const MEDIA_PROJECTION = createMediaProjection(localeId)
  const PAGE_BUILDER_PROJECTION = createPageBuilderProjection(localeId)
  const RICH_TEXT_PROJECTION = createRichTextProjection(localeId)
  const LINK_PROJECTION = createLinkProjection(localeId)
  const IS_HEADER_STATIC_PROJECTION = createIsHeaderStaticProjection()

  const SEO_PROJECTION = groq`{
    "ogImage": select(defined(heroMedia.image) => heroMedia${MEDIA_PROJECTION}, null),
    "ogAltImage": select(defined(heroMedia.image) && defined(heroMedia.alt) => {
      ${coalesceLocaleField('alt', localeId)},
    }.alt, null),
    "metaTitle": title,
    ${coalesceLocaleField('metaDescription', localeId)},
    }
 `
  return groq`{
      "type":_type,
      "hideHeader": false,
      "hideFooter": false,
      "seo": ${SEO_PROJECTION},
      ${IS_HEADER_STATIC_PROJECTION},
      "pageBuilder": [
        {
           "_type": "sectionHero",
           "_key": "sectionHero" + _id,
           "background": heroMedia${MEDIA_PROJECTION},
           "borderPosition": "top",
            heroTextTheme,
            title,
        },
        {
          "_type": "sectionStoreDetails",
          "_key": "sectionStoreDetails" + _id,
          "store":{
            title,
            heroMedia${MEDIA_PROJECTION},
            "path":path.current,
            ${coalesceLocaleField(
              'addressInfo',
              localeId,
            )}[]${RICH_TEXT_PROJECTION},
            ${coalesceLocaleField(
              'description',
              localeId,
            )}[]${RICH_TEXT_PROJECTION},
            ${coalesceLocaleField(
              'additionalNotes',
              localeId,
            )}[]${RICH_TEXT_PROJECTION},
            addressUrl${LINK_PROJECTION},
          }
       },
      ...pageBuilder[]${PAGE_BUILDER_PROJECTION}
      ],
      }`
}
