import { getShopifyCollectionDataById } from '@aether/services/shopify-service'
import { DEFAULT_REGION_ID, ROUTE_BASE_COLLECTIONS } from '@aether/configs'
import { algoliaCollectionMock } from './algoliaCollectionMock'
import { sanityCollectionMock } from './sanityCollectionMock'

type AlgoliaCollectionType = typeof algoliaCollectionMock & {
  description: string
}
export const dirtyHack = {
  title: '',
  description: '',
  products: '',
  type: 'dirtyHack',
  path: '',
}
export const mapSanityCollectionToAlgoliaCollection = async (
  sanityCollection: typeof sanityCollectionMock,
): Promise<AlgoliaCollectionType> => {
  // Every collection from Shopify is synced to Sanity.
  // We need to be sure if the collection is available for the storefront using the shopify-service.
  const shopifyCollection = await getShopifyCollectionDataById({
    locale: DEFAULT_REGION_ID,
    id: sanityCollection.store.gid,
    isVip: false, // TODO What do we do with all the vip logic?
  })

  const isShopifyCollectionVisible = !!(
    shopifyCollection &&
    shopifyCollection.title &&
    shopifyCollection.handle
  )

  if (!isShopifyCollectionVisible) return dirtyHack

  return {
    title: sanityCollection.store.title,
    description: shopifyCollection.description,
    type: 'collection',
    // as there is no straightforward way to filter out collections that are not visble in shopify
    // we use the fact that the sanity-aloglia sync depends on correct type returned
    // you can refer to the source of sanity-algolia webhookSync method
    path: `${ROUTE_BASE_COLLECTIONS}/${shopifyCollection.handle}`,
  }
}
