import React, { FC } from 'react'
import { ShopifyProduct } from '@aether/models'
import { styled } from '@aether/styles'
import { findRelatedProducts } from '@aether/services/shopify-service'
import { ROUTE_BASE_PRODUCTS } from '@aether/configs'
import { Link } from '../Link/Link'
import { useTranslation } from 'react-i18next'
import { useRouter } from 'next/router'

const Root = styled('div', {})

const LinkWrapper = styled('div', {
  display: 'flex',
  flexWrap: 'wrap',
})

const StyledLink = styled(Link, {
  display: 'inline-flex',
  alignItems: 'center',
  background: '$white',
  $aetherFont: 'ui02',
  lineHeight: 1,
  margin: '10px 10px 0 0',
  padding: '$4 $12',
  minHeight: '$32',
  minWidth: '$52',
  borderRadius: '$r1',
  height: '$buttonHeightS',
  justifyContent: 'center',
  transition: 'background .4s, color .4s',
  border: '1px solid $black',
  '&:hover': {
    background: '$black',
    color: '$white',
  },
  $focusStyle: 'default',

  variants: {
    active: {
      true: {
        background: '$black',
        color: '$white',
      },
    },
  },
})

const ProductAccommodationLabel = styled('p', {
  $aetherFont: 'ui01',
  color: '$black',
  marginBottom: '2px',
})

type ProductAccommodationPickerProps = {
  productId: string
  products: Record<string, ShopifyProduct>
  titlePosition?: 'top' | 'right'
  className?: string
}

export const ProductAccommodationPicker: FC<
  ProductAccommodationPickerProps
> = ({ productId, products }) => {
  const { t } = useTranslation('product')
  const { asPath: currentPath } = useRouter()

  const productData: ShopifyProduct | undefined = products[productId]
  const relatedAccommodationProducts = findRelatedProducts(
    productData?.title,
    products,
  )

  const isActiveLink = (href: string | undefined) => {
    return currentPath.split('?')[0] === href
  }

  return (
    <Root>
      <ProductAccommodationLabel id="accommodation-label">
        {t('selectAccommodation')}
      </ProductAccommodationLabel>

      <LinkWrapper aria-labelledby={'accommodation-label'}>
        {relatedAccommodationProducts.map((productAccommodation) => {
          const title = productAccommodation.title.split('-')?.[1]?.trim()
          const isActive = isActiveLink(
            '/products/' + productAccommodation.handle,
          )

          return (
            <StyledLink
              appearance={'grid'}
              href={`${ROUTE_BASE_PRODUCTS}/${productAccommodation.handle}`}
              key={productAccommodation.id}
              aria-current={isActive && 'page'}
              active={isActive}
            >
              {title}
            </StyledLink>
          )
        })}
      </LinkWrapper>
    </Root>
  )
}
