import {
  ShopifyProduct,
  ShopifyProductExtendedRes,
  ShopifyProductVariant,
  ShopifyProductVariantRes,
} from '@aether/models'
import { mapImage, mapImages } from './mapImage'
import { captureException } from '@sentry/nextjs'

export const formatProduct = (
  res: ShopifyProductExtendedRes,
): ShopifyProduct => {
  const { variants, vipProduct, ...restOfProductNode } = res
  return {
    ...restOfProductNode,
    featuredImage: mapImage(res.featuredImage),
    images: mapImages(res.images.edges),
    vipProduct: vipProduct?.value === 'true',
    ...(variants ? { variantsIds: variants?.nodes?.map(({ id }) => id) } : {}),
  }
}

export const formatVariant = (
  res: ShopifyProductVariantRes,
): ShopifyProductVariant => {
  const { product, ...restOfVariantNode } = res
  const { id, handle, title, colors, vipProduct } = product
  return {
    ...restOfVariantNode,
    productId: id,
    image: mapImage(res.image),
    product: {
      handle: handle,
      id,
      title,
      colors: colors || null,
      vipProduct: vipProduct?.value === 'true' || false,
    },
  }
}

export const structureProductExtendedData = (
  allProducts: (ShopifyProductExtendedRes | null)[],
): {
  products: Record<string, ShopifyProduct>
  variants: Record<string, ShopifyProductVariant>
} => {
  try {
    return allProducts.reduce(
      (productsAcc, productNode) => {
        const { variants } = productNode || {}

        if (!productNode) {
          return productsAcc
        }

        return {
          products: {
            ...productsAcc.products,
            [productNode.id]: {
              ...formatProduct(productNode),
            },
          },
          variants: {
            ...productsAcc.variants,
            ...variants?.nodes?.reduce((variantsAcc, variantNode) => {
              return {
                ...variantsAcc,
                [variantNode.id]: {
                  ...formatVariant(variantNode),
                },
              }
            }, {}),
          },
        }
      },
      {
        products: {},
        variants: {},
      },
    )
  } catch (e) {
    captureException(e)
    return {
      products: {},
      variants: {},
    }
  }
}
