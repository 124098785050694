import {
  ShopifyCartFieldsFragment,
  ShopifyCartLineFieldsFragment,
} from '../dist/generatedSchemaSdk'
import { formatShopifyCartLine } from './formatShopifyCartLine'

const calculateLinesQuantity = (
  lines: ShopifyCartFieldsFragment['lines']['nodes'],
): number => {
  return (
    lines?.reduce((acc, line) => {
      if (line.__typename !== 'CartLine') return acc
      return acc + line.quantity
    }, 0) ?? 0
  )
}

export const formatShopifyCart = (
  cart: ShopifyCartFieldsFragment | undefined | null,
) => {
  const cartLines = cart
    ? cart.lines.nodes.reduce<ShopifyCartLineFieldsFragment[]>((acc, line) => {
        if (line.__typename === 'CartLine') {
          return [...acc, line]
        }
        return acc
      }, [])
    : []
  return cart
    ? {
        ...cart,
        lines: cartLines.map((item) => formatShopifyCartLine(item)),
        totalQuantity: calculateLinesQuantity(cart.lines.nodes),
      }
    : null
}

export type ShopifyResolvedCart = ReturnType<typeof formatShopifyCart>
