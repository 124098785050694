import { LocaleId } from '@aether/configs'
import { groq } from 'next-sanity'
import { coalesceLocaleField } from '../helpers/coalesceLocaleField'
import { createLinkProjection } from './createLinkProjection'

export const createCollectionNavigationProjection = (localeId: LocaleId) => {
  const LINK_PROJECTION = createLinkProjection(localeId)

  return groq`{
    collectionLinks[]{
        link${LINK_PROJECTION},
        ${coalesceLocaleField('label', localeId)},
    }
 }.collectionLinks`
}
