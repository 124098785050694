import { FC, PropsWithChildren } from 'react'
import { styled } from '@aether/styles'
import { Link } from '../Link/Link'
import {
  PortableText as SanityPortableText,
  PortableTextComponents,
  PortableTextMarkComponentProps,
} from '@portabletext/react'
import { TypedObject } from '@portabletext/types'
import { PageLink, PortableTextBlock } from '@aether/models'
import { PortableTextImage } from './PortableTextImage'
import { PortableKlavyioEmbeddedForm } from './PortableKlavyioEmbeddedForm'

type MarkComponent<T extends TypedObject = any> = (
  props: PropsWithChildren<PortableTextMarkComponentProps<T>>,
) => JSX.Element

export type PortableTextProps = {
  value?: PortableTextBlock
  additionalComponents?: PortableTextComponents
  withStyles?: boolean
  fontSize?: 'M' | 'S'
  withSpacing?: boolean
  lineSpacing?: 'M' | 'S'
}

const highlighted: MarkComponent = (props) => {
  return <span className="highlighted">{props.children}</span>
}

const italic: MarkComponent = (props) => {
  return <em className="italic">{props.children}</em>
}

const superscript: MarkComponent = (props) => (
  <sup className="superscript">{props.children}</sup>
)

const ui07: MarkComponent = (props) => {
  return <small className="ui07">{props.children}</small>
}

const linkMark: MarkComponent<PageLink & { _type: string }> = ({
  value,
  children,
}) => {
  const linkProps = {
    href: value?.href,
    download: value?.download,
    action: value?.action,
  }
  return (
    <Link {...linkProps} appearance={'textSimple'}>
      {children}
    </Link>
  )
}

const PortableTextWrap = styled('div', {
  variants: {
    fontSize: {
      M: {},
      S: {
        'p, ul, ol': {
          $aetherFont: 'body02',
        },
      },
    },
    withSpacing: {
      true: {
        blockquote: {
          $containerSpace: 'small',
        },
        h2: {
          $containerSpace: 'small',
        },
        h3: {
          $containerSpace: 'small',
        },
        '& > p, & > ul,& > ol': {
          $containerSpace: 'small',
        },
      },
    },
    withStyles: {
      true: {
        '*:last-child': {
          marginBottom: 0,
        },
        '&>*:first-child': {
          marginTop: 0,
        },
        '.highlighted': {
          color: '$blue',
        },
        '.italic': {
          fontStyle: 'italic',
        },
        '.ui07': {
          display: 'inline-block',
          $aetherFont: 'ui07',
        },
        blockquote: {
          $aetherFont: 'heading07',
          marginBottom: '$24',
          marginTop: '$24',
        },
        h2: {
          $aetherFont: 'heading04',
          marginBottom: '$24',
          marginTop: '$24',
        },
        h3: {
          $aetherFont: 'heading06',
          marginBottom: '$24',
          marginTop: '$24',
        },

        'p, ul, ol': {
          listStyle: 'inside',
          $aetherFont: 'body01',
          marginBottom: '$24',
          marginTop: '$24',
          '@lg': {
            marginBottom: '$24',
            marginTop: '$24',
          },
        },
        'ol > li': {
          listStyleType: 'decimal',
        },
        'ul > li': {
          listStyleType: 'disc',
        },
      },
    },
    lineSpacing: {
      S: {},
      M: {},
    },
  },
  compoundVariants: [
    {
      withStyles: true,
      fontSize: 'S',
      css: {
        'p, ul, ol': {
          $aetherFont: 'body02',
        },
      },
    },
    {
      withStyles: true,
      lineSpacing: 'S',
      css: {
        blockquote: {
          marginBottom: '$20',
          marginTop: '$20',
        },
        h2: {
          marginBottom: '$20',
          marginTop: '$20',
        },
        h3: {
          marginBottom: '$20',
          marginTop: '$20',
        },

        'p, ul, ol': {
          marginBottom: '$20',
          marginTop: '$20',
          '@lg': {
            marginBottom: '$20',
            marginTop: '$20',
          },
        },
      },
    },
  ],
})

export const PortableText: FC<PortableTextProps> = ({
  value,
  additionalComponents,
  withStyles = true,
  fontSize = 'M',
  withSpacing = true,
  lineSpacing = 'M',
}) => {
  const components: PortableTextComponents = {
    ...additionalComponents,
    marks: {
      link: linkMark,
      highlighted,
      superscript,
      italic,
      ui07,
      ...(additionalComponents?.marks ? additionalComponents.marks : {}),
    },
    types: {
      richTextImage: PortableTextImage,
      klavyioEmbeddedForm: PortableKlavyioEmbeddedForm,
      ...additionalComponents?.types,
    },
  }

  if (!withStyles && value)
    return <SanityPortableText value={value} components={components} />

  return value ? (
    <PortableTextWrap
      withStyles={withStyles}
      fontSize={fontSize}
      withSpacing={withSpacing}
      lineSpacing={lineSpacing}
    >
      <SanityPortableText value={value} components={components} />
    </PortableTextWrap>
  ) : null
}
