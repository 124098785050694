import {
  ShopifyProduct,
  ShopifyProductExtendedRes,
  ShopifyProductVariant,
} from '@aether/models'
import { getLocaleRegionIdFromPath } from '@aether/utils'
import { gql } from 'graphql-request'
import { createShopifyClient, getProductRootTitle } from '..'
import { PRODUCT_EXTENDED_FRAGMENT } from './fragments/productExtendedFragment'
import { structureProductExtendedData } from './helpers/structureProductExtendedData'
import { captureException, captureMessage } from '@sentry/nextjs'
import { isVipProduct } from './helpers/isVipProduct'

type RelatedProductsQueryRes = {
  products: {
    nodes?: ShopifyProductExtendedRes[]
    pageInfo?: { hasNextPage: boolean; endCursor: string }
  }
}

const RELATED_PRODUCTS_QUERY = (endCursor?: string) => gql`
  ${PRODUCT_EXTENDED_FRAGMENT}
  query ($regionId: CountryCode!, $relatedProductsQuery: String!)
  @inContext(country: $regionId) {
    products(first: 250, query: $relatedProductsQuery, ${
      endCursor ? `after: "${endCursor}"` : ''
    }) {
      pageInfo {
        hasNextPage
        endCursor
      }
      nodes {
        ...ProductExtendedFragment
      }
    }
  }
`

/**
 * Why?
 * We use product titles convention to group products by colors.
 * example: product can be spit into 3 colors by title: "Shirt - Red" "Shirt - Blue" "Shirt - Green"
 *
 * How?
 * Based on a list of products Ids we fetch all products that have the same name prefix
 * example: based on a product "Shirt - Red" get all other products that start with "Shirt - *"
 *
 * Recursion
 * Since we are limited to 250 results we need to paginate through all products
 */
const fetchRelatedProducts = async (
  locale: string,
  productsNodes: { title: string }[] = [],
  isVip = false,
  endCursor: string | undefined = undefined,
  prevProducts: ShopifyProductExtendedRes[] = [],
): Promise<ShopifyProductExtendedRes[]> => {
  try {
    const [regionId, localeId] = getLocaleRegionIdFromPath(locale)
    const shopifyClient = createShopifyClient(localeId)

    const allProductsTitlePatterns = productsNodes.map(
      ({ title }) => `${getProductRootTitle(title)} - *`,
    )

    const uniqueProductTitlePatterns = [...new Set(allProductsTitlePatterns)]

    if (uniqueProductTitlePatterns.length === 0) {
      return []
    }

    const relatedProductsQuery = uniqueProductTitlePatterns
      .map((title) => `(title:${title})`)
      .join(' OR ')

    const allRelatedProducts =
      await shopifyClient.request<RelatedProductsQueryRes>(
        RELATED_PRODUCTS_QUERY(endCursor),
        {
          regionId,
          relatedProductsQuery,
        },
      )

    const products = [
      ...(allRelatedProducts?.products?.nodes || []).filter((product) =>
        isVip ? product : !isVipProduct(product),
      ),
      ...(prevProducts || []),
    ]

    const hasNextPage = allRelatedProducts.products.pageInfo?.hasNextPage
    const newEndCursor = allRelatedProducts.products.pageInfo?.endCursor

    if (hasNextPage) {
      return fetchRelatedProducts(
        locale,
        productsNodes,
        isVip,
        newEndCursor,
        products,
      )
    }

    return products
  } catch (e) {
    captureMessage('[getAllRelatedProducts] Related products cannot be loaded')
    captureException(e)
    return []
  }
}

export const getShopifyRelatedProductsData = async (
  locale: string,
  productsNodes: { title: string }[] = [],
  isVip = false,
): Promise<{
  products: Record<string, ShopifyProduct>
  variants: Record<string, ShopifyProductVariant>
}> => {
  const allProducts = await fetchRelatedProducts(locale, productsNodes, isVip)
  return structureProductExtendedData(allProducts)
}
