import { groq } from 'next-sanity'
import { LocaleId } from '@aether/configs'
import { createJournalPostProjection } from '../projections/createJournalPostProjection'

export const createSectionPostPreviewProjection = (localeId: LocaleId) => {
  const POST_PROJECTION = createJournalPostProjection(localeId)
  return groq`{
        _key,
        _type,
        "post": post -> ${POST_PROJECTION},
        pageContext,
        imagePosition
      }
  `
}
