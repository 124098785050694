import { PageLink } from '@aether/models'

export const createDoubleLinksArray = (
  firstCtaLink?: PageLink,
  firstCtaLabel?: string,
  secondCtaLink?: PageLink,
  secondCtaLabel?: string,
) => {
  const firstCtaLinkExist = !!(firstCtaLabel && firstCtaLink?.href)
  const secondCtaLinkExist = !!(secondCtaLabel && secondCtaLink?.href)

  return [
    ...(firstCtaLinkExist
      ? [
          {
            ctaLink: firstCtaLink,
            ctaLabel: firstCtaLabel,
          },
        ]
      : []),
    ...(secondCtaLinkExist
      ? [
          {
            ctaLink: secondCtaLink,
            ctaLabel: secondCtaLabel,
          },
        ]
      : []),
  ]
}
