import { ShopifyPriceRange, ShopifyProduct } from '@aether/models'
import { applyDiscountToValue } from '@aether/discounts/utils-products'
import { Discount } from '@aether/discounts/utils-context'

export const calculatePriceRange = (
  product: ShopifyProduct,
  discount?: Discount,
): ShopifyPriceRange => {
  if (discount?.percentage) {
    return {
      maxVariantPrice: {
        ...product.priceRange.maxVariantPrice,
        amount: applyDiscountToValue(
          discount.percentage,
          product.priceRange.maxVariantPrice.amount,
        ),
      },
      minVariantPrice: {
        ...product.priceRange.minVariantPrice,
        amount: applyDiscountToValue(
          discount.percentage,
          product.priceRange.minVariantPrice.amount,
        ),
      },
    }
  }

  return product?.priceRange
}
