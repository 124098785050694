import { groq } from 'next-sanity'
import { DEFAULT_REGION_ID, LocaleId, RegionId } from '@aether/configs'
import { createPageBuilderProjection } from './createPageBuilderProjection'
import { createIsHeaderStaticProjection } from './createIsHeaderStaticProjection'
import { createSEOProjection } from './createSEOProjection'
import { createModulesProjection } from './createModulesProjection'

export const createDefaultPageProjection = (
  regionId: RegionId,
  localeId: LocaleId,
) => {
  const PAGE_BUILDER_PROJECTION = createPageBuilderProjection(localeId)
  const IS_HEADER_STATIC_PROJECTION = createIsHeaderStaticProjection()
  const SEO_PROJECTION = createSEOProjection(localeId)
  const MODULES_PROJECTION = createModulesProjection(localeId)
  return groq`coalesce(page_${regionId}, page_${DEFAULT_REGION_ID})->{
    "type":_type,
    ${IS_HEADER_STATIC_PROJECTION},
    ...,
    "disallowRobots":^.disallowRobots,
    pageBuilder[]${PAGE_BUILDER_PROJECTION},
    modules[]${MODULES_PROJECTION},
    seo${SEO_PROJECTION}
  }`
}
