import { useEffect, useRef } from 'react'
import { styled } from '@aether/styles'

export const CookieDeclaration = () => {
  const cookieDeclarationContainer = useRef<HTMLDivElement>(null)

  useEffect(() => {
    /*
     * Cookiebot
     * This allows the cookie declaration to be loaded inside the page
     * Next Script does not allow script tags to load in the page's content
     * JSX loaded by regular script tag are not inserted into the DOM
     */
    const script = document.createElement('script')
    script.id = 'CookieDeclaration'
    script.type = 'text/javascript'
    script.async = true
    script.src =
      'https://consent.cookiebot.com/2002ff8d-3111-4d44-8b62-340dea3bfaef/cd.js'
    const targetElement = cookieDeclarationContainer.current

    if (targetElement) {
      targetElement.appendChild(script)
    }
  }, [])

  const Wrapper = styled('div', {
    $lin: {
      paddingRight: 'CONTAINER_SPACE_S',
      paddingLeft: 'CONTAINER_SPACE_S',
    },
    margin: '$sectionSpaceMobile 0',
    '@lg': {
      margin: '$sectionSpaceDesktop 0',
    },
  })

  const Heading = styled('h3', {
    $aetherFont: 'heading06',
    marginBottom: '$24',
  })

  return (
    <Wrapper>
      <Heading>Cookies</Heading>
      <div ref={cookieDeclarationContainer} id="cookiebot-declaration">
        {/*Cookiebot will insert declaration here*/}
      </div>
    </Wrapper>
  )
}
