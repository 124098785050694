import { FC, Fragment } from 'react'
import { styled } from '@aether/styles'
import { ShopifyPrice } from '@aether/models'
import { getI18nPrice } from '@aether/utils'
import { useRouter } from 'next/router'
import { CheckIcon } from '@aether/icons'
import { ShopifyResolvedCart } from '@aether/shopify-sdk'

type Props = {
  cart: ShopifyResolvedCart | null
  className?: string
  discountLabel?: string
  discounts?: {
    tierThreshold: number
    awayFromLabel: string
    unlockedDiscountLabel: string
  }[]
}

const DiscountProgressText = styled('h3', {
  $aetherFont: 'ui05',
  marginBottom: '$4',
})

const DiscountTextWrap = styled('div', {
  display: 'grid',
  gap: '$8',
  alignItems: 'center',
  gridTemplateColumns: 'min-content auto',
  color: '$blue',
  marginBottom: '$4',
  transition: 'color 200ms linear 0s',
  variants: {
    isDeprecated: {
      true: {
        color: '$gray_medium',
      },
    },
  },
})

const DiscountLabel = styled('h2', {
  $aetherFont: 'heading05',
  paddingBottom: '$12',
})

const DiscountText = styled('h3', {
  $aetherFont: 'ui05',
})

const MultiProgressBarWrap = styled('div', {
  display: 'grid',
  gridAutoFlow: 'column',
  marginTop: '$12',
  gap: '$4',
})

const ProgressBarWrap = styled('div', {
  boxSizing: 'content-box',
  height: '12px',
  position: 'relative',
  background: '$gray_light',
  borderRadius: '$r1',
})

const ProgressBar = styled('span', {
  display: 'block',
  height: '100%',
  borderRadius: '$r1',
  backgroundColor: '$blue',
  position: 'relative',
  overflow: 'hidden',
  width: '0%',
})

const getDiscountsStatus = (
  totalAmountPrice?: ShopifyPrice,
  discounts?: {
    tierThreshold: number
    awayFromLabel: string
    unlockedDiscountLabel: string
  }[],
):
  | {
      awayFromLabel: string
      unlockedDiscountLabel: string
      awayFromPrice: ShopifyPrice | null
      progress: number
      isDiscount: boolean
    }[]
  | null => {
  if (!totalAmountPrice || !discounts) return null

  discounts.sort(({ tierThreshold: a }, { tierThreshold: b }) => a - b)

  const getPercentage = (
    partialValue: number,
    totalValue: number,
    price: number,
    index: number,
  ): number => {
    const prevThreshold = discounts[index - 1]

    const isNextActiveDiscount =
      price < totalValue && prevThreshold && price > prevThreshold.tierThreshold
    const isFirstActiveDiscount = index === 0 && price < totalValue

    if (isNextActiveDiscount || isFirstActiveDiscount) {
      return (100 * partialValue) / totalValue
    }

    if (price < totalValue) {
      return 0
    }

    return 100
  }

  const ceil = (value: number, min: number, max: number): number =>
    Math.min(Math.max(value, min), max)

  const { amount, currencyCode } = totalAmountPrice

  return discounts.map((discount, index) => {
    const { awayFromLabel, unlockedDiscountLabel } = discount

    const awayFromSubtract = discount.tierThreshold - Number(amount)
    const awayFromAmount = ceil(awayFromSubtract, 0, discount.tierThreshold)
    const isDiscount = awayFromAmount === 0

    return {
      awayFromLabel,
      unlockedDiscountLabel,
      awayFromPrice: {
        amount: String(awayFromAmount),
        currencyCode,
      },
      progress: getPercentage(
        discount.tierThreshold - awayFromAmount,
        discount.tierThreshold,
        Number(amount),
        index,
      ),
      isDiscount,
    }
  })
}

const getAwayFromAmount = (
  awayFromPrice: ShopifyPrice | null,
  locale?: string,
) => {
  const awayFromAmount = awayFromPrice
    ? getI18nPrice(awayFromPrice, locale, {
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
      })
    : ''

  return awayFromAmount
}

export const DiscountIndicator: FC<Props> = ({
  cart,
  className,
  discounts,
  discountLabel,
}) => {
  const { locale } = useRouter()

  const amount = cart?.lines?.reduce((totalPrice, line) => {
    return Number(line.cost.subtotalAmount.amount) + totalPrice
  }, 0)

  const price = {
    amount: String(amount),
    currencyCode: cart?.cost.subtotalAmount.currencyCode || 'USD',
  }

  const discountsStatus = getDiscountsStatus(price, discounts)

  return (
    <div className={className}>
      {discountLabel && <DiscountLabel>{discountLabel}</DiscountLabel>}

      {discountsStatus &&
        discountsStatus.map(
          (
            { isDiscount, awayFromPrice, awayFromLabel, unlockedDiscountLabel },
            index,
          ) => (
            <Fragment key={`${index}-${awayFromPrice}`}>
              {isDiscount ? (
                <DiscountTextWrap
                  isDeprecated={discountsStatus[index + 1]?.isDiscount}
                >
                  <CheckIcon
                    color={
                      discountsStatus[index + 1]?.isDiscount ? 'grey' : 'blue'
                    }
                  />
                  <DiscountText>{unlockedDiscountLabel}</DiscountText>
                </DiscountTextWrap>
              ) : (
                <DiscountProgressText>
                  {getAwayFromAmount(awayFromPrice, locale) +
                    ' ' +
                    awayFromLabel}
                </DiscountProgressText>
              )}
            </Fragment>
          ),
        )}
      <MultiProgressBarWrap>
        {discountsStatus &&
          discountsStatus.map(({ progress }, index) => (
            <ProgressBarWrap key={`${index}`}>
              <ProgressBar css={{ width: `${progress}%` }} />
            </ProgressBarWrap>
          ))}
      </MultiProgressBarWrap>
    </div>
  )
}
