import { ShopifyImageMediaPayload, ShopifyResImage } from '@aether/models'

export const mapImage = (
  input?: ShopifyResImage,
): ShopifyImageMediaPayload | null => {
  if (!input?.url) return null
  return {
    type: 'shopify-image',
    image: {
      height: input.height,
      width: input.width,
      src: input.url,
      alt: input.altText || null,
    },
  }
}

export const mapImages = (
  input?: { node: ShopifyResImage }[],
): ShopifyImageMediaPayload[] => {
  return (
    input
      ?.map(({ node }) => mapImage(node))
      .filter((img): img is ShopifyImageMediaPayload => !!img) || []
  )
}
