import { ShopifyPrice, ShopifyProductVariant } from '@aether/models'
import { applyDiscountToValue } from '@aether/discounts/utils-products'

export function calculatePrice(
  productVariant: ShopifyProductVariant,
  discount?: number,
): ShopifyPrice {
  const { price } = productVariant

  if (discount) {
    return {
      ...price,
      amount: applyDiscountToValue(discount, price.amount),
    }
  }
  return price
}
