import {
  useState,
  createContext,
  FC,
  useContext,
  useEffect,
  PropsWithChildren,
} from 'react'
import Cookies from 'js-cookie'
import Script from 'next/script'

export type ChatAppContextType = { isEnabled: boolean; openChatApp: () => void }

type ChatraSetup = {
  deferredLoading: boolean
  startHidden: boolean
  zIndex: number
}

declare global {
  interface Window {
    ChatraSetup: ChatraSetup
    Chatra: any
    ChatraID?: string
  }
}

const COOKIES_CHAT_APP_ENABLED = `AETHER_CHAT_APP_ENABLED`
const COOKIE_ENABLED_VALUE = 'true'
const COOKIE_EXPIRE_DAYS = 1

const CHATRA_EVENT_EXPAND_WIDGET = 'expandWidget'

const chatSnippet = function (
  d: Document,
  w: Window,
  c: 'Chatra',
  shoulOpenOnLoad: boolean,
) {
  // chatra settings
  w.ChatraSetup = {
    deferredLoading: true,
    startHidden: false,
    zIndex: 10,
  }

  // on script loaded
  const onLoad = () => {
    if (shoulOpenOnLoad) {
      w.Chatra('expandWidget')
    }
  }

  w.ChatraID = process.env.NEXT_PUBLIC_CHATRA_ID
  const s = d.createElement('script')
  w[c] =
    w[c] ||
    function () {
      // eslint-disable-next-line prefer-rest-params
      ;(w[c].q = w[c].q || []).push(arguments)
    }
  s.async = true
  s.src = 'https://call.chatra.io/chatra.js'
  s.onload = onLoad
  if (d.head) d.head.appendChild(s)
}

export const ChatAppContext = createContext<ChatAppContextType>({
  isEnabled: false,
  openChatApp: () => null,
})

const createChatAppCookieService = () => {
  return {
    get: () => Cookies.get(COOKIES_CHAT_APP_ENABLED),
    set: () =>
      Cookies.set(COOKIES_CHAT_APP_ENABLED, COOKIE_ENABLED_VALUE, {
        expires: COOKIE_EXPIRE_DAYS,
      }),
    remove: () => Cookies.remove(COOKIES_CHAT_APP_ENABLED),
  }
}

export const ChatAppProvider: FC<PropsWithChildren<{ debug?: boolean }>> = ({
  children,
}) => {
  const cookesService = createChatAppCookieService()
  const [isEnabled, setIsEnabled] = useState(false)
  const [shouldOpenOnLoad, setShouldOpenOnLoad] = useState(false)

  const isCookieEnabled = cookesService.get() === COOKIE_ENABLED_VALUE

  const openChatApp = () => {
    if (!isEnabled) {
      cookesService.set()
      setShouldOpenOnLoad(true)
      setIsEnabled(true)
      return
    }

    if (typeof window !== 'undefined') {
      window.Chatra(CHATRA_EVENT_EXPAND_WIDGET)
    }
  }

  useEffect(() => {
    if (isCookieEnabled) {
      setShouldOpenOnLoad(false)
      setIsEnabled(true)
    }
  }, [isCookieEnabled])

  return (
    <ChatAppContext.Provider
      value={{
        isEnabled,
        openChatApp,
      }}
    >
      {children}
      {isEnabled && (
        <Script
          id="chatra"
          strategy="afterInteractive"
          data-categories="essential"
          dangerouslySetInnerHTML={{
            __html: `(${chatSnippet})(document, window, 'Chatra', ${shouldOpenOnLoad})`,
          }}
        ></Script>
      )}
    </ChatAppContext.Provider>
  )
}

export const useChatAppContext = (): ChatAppContextType => {
  return useContext(ChatAppContext)
}
