import { ShopifyProductFilter } from '@aether/shopify-sdk'

export const creatVipProductFilters = (
  isVip: boolean,
): ShopifyProductFilter[] => {
  const nonVipProducts = {
    productMetafield: {
      namespace: 'product',
      key: 'vip_product',
      value: 'false',
    },
  }

  const vipProducts = {
    productMetafield: {
      namespace: 'product',
      key: 'vip_product',
      value: 'true',
    },
  }

  if (isVip) {
    return [nonVipProducts, vipProducts]
  }

  return [nonVipProducts]
}
