import { useCustomerContext } from '@aether/account/utils-customer-context'

const useFilters = (): { filters: string } => {
  const { userRole } = useCustomerContext()

  const filters =
    userRole === 'vip'
      ? 'inventory_available:true OR tags:algolia-force-include'
      : `(inventory_available:true OR tags:algolia-force-include ) AND meta.product.vip_product:false `

  return { filters }
}

export default useFilters
