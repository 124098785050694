import algoliasearch from 'algoliasearch'
import indexer from 'sanity-algolia'
import { mapSanityStoreToAlgoliaStore } from './store/mapSanityStoreToAlgoliaStore'
import { mapSanityUtilityPageToAlgoliaUtilityPage } from './utilityPage/mapSanityUtilityPageToAlgoliaUtilityPage'
import { mapSanityPageToAlgoliaPage } from './page/mapSanityPageToAlgoliaPage'
import { SanityUtilityPageType } from './utilityPage/types'
import { SanityStoreType } from './store/types'
import { SanityDocumentStub } from '@sanity/client'
import { getIndexName } from './getIndexName'
import { SanityPageType } from './page/types'
import { mapSanityCollectionToAlgoliaCollection } from './collection/mapSanityCollectionToAlgoliaCollection'
import { sanityCollectionMock } from './collection/sanityCollectionMock'

// I am not using the searchClient from @aether/search
// as it is using lite version of alogliasearch, that doesn't have
// the methods required by sanity-algolia package
const algoliaClient = algoliasearch(
  process.env.NEXT_PUBLIC_ALGOLIA_APP_ID || '',
  process.env.ALGOLIA_WRITE_API_KEY || '',
)

const pagesIndexName = getIndexName('pages')
const pagesIndex = algoliaClient.initIndex(pagesIndexName)

export const sanityAlgolia = indexer(
  {
    store: {
      index: pagesIndex,
    },
    utilityPage: {
      index: pagesIndex,
    },
    page: {
      index: pagesIndex,
      projection: `{
        ...,
        "path": *[_type == 'route' && references(^._id)][0].path,
        "includeInSitemap": *[_type == 'route' && references(^._id)][0].includeInSitemap
      }`,
    },
    collection: {
      index: pagesIndex,
      projection: `{
        store {
          title,
          slug,
          isDeleted,
          gid
        }
      }`,
    },
  },
  async (document: SanityDocumentStub) => {
    switch (document._type) {
      case 'store':
        return mapSanityStoreToAlgoliaStore(document as SanityStoreType)
      case 'utilityPage':
        return mapSanityUtilityPageToAlgoliaUtilityPage(
          document as SanityUtilityPageType,
        )
      case 'page':
        return mapSanityPageToAlgoliaPage(document as SanityPageType)
      case 'collection': {
        return await mapSanityCollectionToAlgoliaCollection(
          document as typeof sanityCollectionMock,
        )
      }

      default:
        return document
    }
  },
  (document: SanityDocumentStub) => {
    switch (document._type) {
      case 'page':
        if (document.path) {
          return true
        }

        if (document.includeInSitemap) {
          return true
        }

        return false

      case 'collection':
      case 'utilityPage':
      case 'store':
      default:
        return true
    }
  },
)
