import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'

export const useUrlFragment = (): [string, (fragment: string) => void] => {
  const [fragment, setFragment] = useState('')
  const router = useRouter()
  useEffect(() => {
    const onHashChange = () => {
      setFragment(window.location.hash.split('?')[0].replace('#', ''))
    }
    window.addEventListener('hashchange', onHashChange)
    setFragment(window.location.hash.split('?')[0].replace('#', ''))
    return () => {
      window.removeEventListener('hashchange', onHashChange)
    }
  }, [])

  const changeFragment = (fragment: string) => {
    const [base, query] = router.asPath.split('?')
    const [basePath] = base.split('#')
    router.replace(
      `${basePath}${fragment ? `#${fragment}` : ''}${query ? `?${query}` : ''}`,
      undefined,
      {
        shallow: true,
        scroll: false,
      },
    )
    setFragment(fragment)
  }
  return [fragment, changeFragment]
}
