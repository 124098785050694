import { styled } from '@aether/styles'

export const ListItem = styled('li', {
  $aetherFont: 'ui03',
  cursor: 'pointer',
  letterSpacing: '0.1em',
  lineHeight: '1.33em',

  '& + &': {
    marginTop: '1.36em',
  },
})
