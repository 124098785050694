import { coalesceLocaleField } from '../helpers/coalesceLocaleField'
import { groq } from 'next-sanity'
import { LocaleId } from '@aether/configs'
import { createLinkProjection } from '../projections/createLinkProjection'
import { createMediaProjection } from '../projections/createMediaProjection'

export const createSectionHeroLandingProjection = (localeId: LocaleId) => {
  const LINK_PROJECTION = createLinkProjection(localeId)
  const MEDIA_PROJECTION = createMediaProjection(localeId)

  return groq`{
        _key,
        _type,
        ${coalesceLocaleField('title', localeId)},
        background${MEDIA_PROJECTION},
        ctaLink${LINK_PROJECTION},
        ${coalesceLocaleField('ctaLabel', localeId)},
        heroTextTheme,
      }
  `
}
