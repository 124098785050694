import {
  ShopifyProduct,
  ShopifyProductExtendedRes,
  ShopifyProductVariant,
} from '@aether/models'
import { getLocaleRegionIdFromPath } from '@aether/utils'
import { gql } from 'graphql-request'
import { createShopifyClient } from '..'
import { PRODUCT_EXTENDED_FRAGMENT } from './fragments/productExtendedFragment'
import { structureProductExtendedData } from './helpers/structureProductExtendedData'
import { isVipProduct } from './helpers/isVipProduct'

const QUERY = gql`
  ${PRODUCT_EXTENDED_FRAGMENT}
  query ($regionId: CountryCode!, $ids: [ID!]!) @inContext(country: $regionId) {
    nodes(ids: $ids) {
      ...ProductExtendedFragment
    }
  }
`

export const getProducts = async (
  locale: string,
  ids: string[],
  isVip: boolean,
): Promise<ShopifyProductExtendedRes[]> => {
  const [regionId, localeId] = getLocaleRegionIdFromPath(locale)
  const shopifyClient = createShopifyClient(localeId)

  if (ids.length === 0) {
    return []
  }

  return await shopifyClient
    .request<{
      nodes: ShopifyProductExtendedRes[]
    }>(QUERY, {
      ids,
      regionId,
    })
    .then((res) => {
      return res.nodes.filter((product) =>
        isVip ? product : !isVipProduct(product),
      )
    })
}

export const getShopifySingleProductsData = async (
  locale: string,
  productsIds: string[],
  isVip: boolean,
): Promise<{
  products: Record<string, ShopifyProduct>
  variants: Record<string, ShopifyProductVariant>
}> => {
  const allProducts = await getProducts(locale, productsIds, isVip)
  return structureProductExtendedData(allProducts)
}
