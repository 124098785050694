import { HeaderAppearanceType, HeaderTab } from '@aether/models'
import { FC, useEffect, useId } from 'react'
import { Link } from '@aether/components'
import { SmallArrowRight } from '@aether/icons'
import { styled } from '@aether/styles'
import { CommonTab } from './HeaderTab'
import { useRouter } from 'next/router'

const StyledLink = styled(Link, {
  display: 'inline-flex',
  $aetherFont: 'ui02',
  lineHeight: 1,
  borderRadius: '$r1',
  height: '$buttonHeightS',
  padding: '0 $20',
  alignItems: 'center',
  justifyContent: 'center',
  transition: 'background .4s, color .4s',
  '&:hover': {
    background: '$black',
    color: '$white',
  },
  '&:focus-visible': {
    background: '$black',
    color: '$white',
    outline: '$blue solid 3px',
    outlineOffset: '2px',
  },
  variants: {
    isOpen: {
      true: {
        background: '$black',
        color: '$white',
      },
    },
  },
})

const MenuItemGroupTrigger = styled('button', {
  opacity: 0,
  position: 'absolute',
  top: '50%',
  right: 10,
  bottom: 0,
  width: 14,
  height: 14,
  padding: 0,
  background: 'transparent',
  pointerEvents: 'none',

  svg: {
    width: '100%',
    height: '100%',
  },

  '&:focus-visible': {
    outline: '$blue solid 3px',
    outlineOffset: '2px',
    borderRadius: '50%',
    opacity: 1,
  },

  '&[aria-expanded="true"]': {
    transform: `translateY(-50%) rotate(-90deg)`,
    path: {
      fill: '$white',
    },
  },

  '&[aria-expanded="false"]': {
    transform: `translateY(-50%) rotate(90deg)`,
  },
})

const DrawerWrap = styled('div', {
  '@lg': {
    display: 'grid',
  },
  position: 'absolute',
  top: '100%',
  left: 0,
  width: '100%',
  height: 'auto',
  variants: {
    appearance: {
      journal: {
        backgroundColor: '$beige',
      },
      default: {
        backgroundColor: '$white',
      },
    },
    open: {
      true: {
        opacity: 1,
        pointerEvents: 'auto',
      },
      false: {
        opacity: 0,
        pointerEvents: 'none',
      },
    },
  },
})

const TabWrap = styled('div', {
  gridColumn: 1,
  gridRow: 1,
  transition: 'opacity 200ms',
  display: 'grid',
})

const StyledLi = styled('li', {
  display: 'grid',
  gridAutoFlow: 'column',
})

const ItemHoverableArea = styled('div', {
  position: 'relative',
  padding: '0 $8',
})

export const DesktopMenuItem: FC<{
  tab: HeaderTab
  appearance: HeaderAppearanceType
  isOpen: boolean
  onOpen: (tab: HeaderTab) => void
  onClose: () => void
}> = ({ tab, appearance, isOpen, onOpen, onClose }) => {
  const router = useRouter()
  const flyoutButtonTrigger = useId()
  const flyoutId = useId()

  useEffect(() => {
    router.events.on('routeChangeComplete', onClose)
    return () => {
      router.events.off('routeChangeComplete', onClose)
    }
  }, [onClose, router.events])

  return (
    <StyledLi>
      <ItemHoverableArea>
        <StyledLink
          isOpen={isOpen}
          key={tab.title}
          appearance={'grid'}
          onMouseEnter={() => onOpen(tab)}
          {...tab.link}
        >
          {tab.title}
        </StyledLink>
        <MenuItemGroupTrigger
          id={flyoutButtonTrigger}
          onClick={isOpen ? onClose : () => onOpen(tab)}
          aria-expanded={isOpen}
          aria-label={tab.title}
          aria-controls={flyoutId}
        >
          <SmallArrowRight />
        </MenuItemGroupTrigger>
      </ItemHoverableArea>

      <DrawerWrap
        open={isOpen}
        appearance={appearance}
        id={flyoutId}
        aria-labelledby={flyoutButtonTrigger}
        aria-hidden={!isOpen}
      >
        <TabWrap>
          <CommonTab tab={tab} isOpen={isOpen} />
        </TabWrap>
      </DrawerWrap>
    </StyledLi>
  )
}
