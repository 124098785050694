import { FC } from 'react'
import { ShopifyPrice, ShopifyProductVariant } from '@aether/models'
import { PriceCompare } from '../components/PriceCompare/PriceCompare'

// TODO: move to models
type OrderLineItemResponseType = {
  quantity: number
  originalTotalPrice: ShopifyPrice
  discountedTotalPrice: ShopifyPrice
  variant: ShopifyProductVariant
  customAttributes: { key: string; value: string }[]
}

export const OrderLinePrice: FC<{
  order: OrderLineItemResponseType
}> = ({ order }) => {
  const { originalTotalPrice, discountedTotalPrice } = order

  return (
    <PriceCompare
      price={discountedTotalPrice}
      compareAtPrice={originalTotalPrice}
    />
  )
}
