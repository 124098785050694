export type MultipassInput = {
  locale: string
  returnTo: string
}

export type MultipassResponse = {
  url: string
}

export const getMultipass = async (
  data: MultipassInput,
): Promise<MultipassResponse> => {
  const { returnTo, locale } = data
  const response = await fetch(
    `/api/shopify/get-multipass?locale=${locale}&return_to=${returnTo}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    },
  )

  if (!response.ok) {
    throw new Error('[getMultipass]response failed')
  }

  return response.json()
}
