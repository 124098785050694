import { ContainerSizeType } from '@aether/models'

export const getContainerSize = (containerSizeType: ContainerSizeType) => {
  switch (containerSizeType) {
    case 'S': {
      return {
        maxWidth: '$containerSizeS',
        margin: '0 auto',
      }
    }
    case 'textReadability': {
      return {
        maxWidth: '58em',
        margin: '0 auto',
      }
    }
    case 'L': {
      return {
        maxWidth: '$containerSizeL',
        margin: '0 auto',
      }
    }
    case 'XL': {
      return {
        maxWidth: '$containerSizeXL',
        margin: '0 auto',
      }
    }
    case 'fullWidth': {
      return {
        margin: '0 auto',
      }
    }
    default:
      return {}
  }
}
