import { getLocaleRegionIdFromPath } from '@aether/utils'
import { groq } from 'next-sanity'
import { getClient } from '../getClient'
import { ErrorPageData } from '@aether/models'
import { coalesceLocaleField } from '../helpers/coalesceLocaleField'
import { createLinkProjection } from '../projections/createLinkProjection'
import { createRichTextProjection } from '../projections/createRichTextProjection'

type ErrorPageType = 'notFound' | 'default'

export const getErrorPage = async (
  localeRegionString: string,
  preview = false,
  type: ErrorPageType = 'default',
): Promise<ErrorPageData> => {
  const [, localeId] = getLocaleRegionIdFromPath(localeRegionString)
  const BLOCK_CONTENT_PROJECTION = createRichTextProjection(localeId)
  const LINK_PROJECTION = createLinkProjection(localeId)

  const error404Query = groq`
  *[_type == "error404"]{
    ${coalesceLocaleField('title', localeId)},
    ${coalesceLocaleField(
      'description',
      localeId,
    )}[]${BLOCK_CONTENT_PROJECTION},
    ${coalesceLocaleField('ctaLabel', localeId)},
    ctaLink${LINK_PROJECTION},
  }[0]`

  const error500Query = groq`
  *[_type == "error500"]{
    ${coalesceLocaleField('title', localeId)},
    ${coalesceLocaleField(
      'description',
      localeId,
    )}[]${BLOCK_CONTENT_PROJECTION},
    ${coalesceLocaleField('ctaLabel', localeId)},
  }[0]`

  const errorPageData = await getClient(preview).fetch(
    type === 'notFound' ? error404Query : error500Query,
  )
  return errorPageData
}
