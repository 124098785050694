import { groq } from 'next-sanity'
import { getClient } from '../getClient'

export const getRouteType = async (
  path: string,
  preview = false,
): Promise<string | undefined> => {
  const query = groq`*[!(_id in path('drafts.**')) && defined(path.current) && path.current == "${path}"][0]{
    "type": _type
  }.type`

  const routeType: string = await getClient(preview).fetch(query)

  return routeType
}
