import React, { ChangeEvent, Dispatch, FC, SetStateAction } from 'react'
import { ROUTE_BASE_PRODUCTS } from '@aether/configs'
import { styled } from '@aether/styles'
import { ProductColorType } from '@aether/models'
import { Link } from '../Link/Link'
import { Media } from '../Media/Media'
import { Button } from '../Button/Button'

import { Close } from '@aether/icons'
import { ProductCartLinePrice } from '../Price/ProductCartLinePrice/ProductCartLinePrice'
import { useTranslation } from 'react-i18next'
import { formatProductName } from '@aether/utils'
import {
  ShopifyMutationCartLinesUpdateArgs,
  ShopifyResolvedCartLine,
} from '@aether/shopify-sdk'
import {
  useDiscounts,
  useIsExcludedFromDiscount,
} from '@aether/discounts/utils-context'
import { useCustomerContext } from '@aether/account/utils-customer-context'

type CartLineProps = {
  className?: string
  line: ShopifyResolvedCartLine
  colorsMap?: ProductColorType[]
  readOnly?: boolean
  removeCartLines: (lines: ShopifyResolvedCartLine[]) => void
  updateCartLines: (lines: ShopifyMutationCartLinesUpdateArgs['lines']) => void
  setCartModalOpen?: Dispatch<SetStateAction<boolean>>
  isCartDiscountApplied?: boolean
  nonDiscountedProductTypes?: string[]
  nonDiscountedVendors?: string[]
}

const CloseButton = styled(Button, {
  height: 44,
  width: 44,
  margin: '-17px -17px -17px auto',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  $focusStyle: 'default',
})

const Wrap = styled('article', {
  display: 'grid',
  gap: '$12',
  gridTemplateColumns: '100px 1fr',
  gridTemplateRows: '133px',
})

const Title = styled('span', {
  $aetherFont: 'ui05',
  borderBottom: '1px solid currentColor',
  lineHeight: 1.5,
})

const PreOrderText = styled('p', {
  $aetherFont: 'ui05',
  color: '$gray_medium',
})
const FinalSaleText = styled('p', {
  $aetherFont: 'ui05',
  color: '$red',
})

const QuantityText = styled('h3', {
  $aetherFont: 'ui05',
  gridColumn: '1/2',
  alignSelf: 'end',
})

const PriceWrap = styled('div', {
  $aetherFont: 'ui01',
  alignSelf: 'end',
  justifySelf: 'end',
  gridRow: '4/5',
  gridColumn: '2/3',
})

const SelectedOptionsWrap = styled('div', {
  alignItems: 'center',
  display: 'grid',
  gap: '$12',
  gridRow: '2/3',
  gridColumn: '1/3',
  gridTemplateColumns: 'min-content 1fr',
})

const SelectedOptionsLabel = styled('span', {
  $aetherFont: 'ui05',
  alignItems: 'center',
  whiteSpace: 'break-spaces',
})

const Color = styled('div', {
  height: '18px',
  width: '18px',
  border: '$gray_medium solid 1px;',
  borderRadius: '$rMax',
  overflow: 'hidden',
})

const MediaWrap = styled('div', {
  height: '133px',
  width: '100px',

  '&:focus-within': {
    outline: '$blue solid 3px',
    outlineOffset: '2px',
  },
})

const ProductInfoWrap = styled('div', {
  display: 'grid',
  gap: '$8',
  gridTemplateRows: 'min-content min-content min-content 1fr',
})

export const SelectContainer = styled('div', {
  gridColumn: '1/2',
  alignSelf: 'end',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  position: 'relative',
  $aetherFont: 'ui05',
  width: 'max-content',
  gridRow: '4/5',
  '&::before': {
    pointerEvents: 'none',
    width: '5px',
    height: '5px',
    borderRight: '1px solid $black',
    borderTop: '1px solid $black',
    position: 'absolute',
    content: '',
    right: '0',
    transform: 'rotate(135deg) translate(7px, 9px)',
  },
})

const Select = styled('select', {
  margin: 0,
  appearance: 'none',
  $aetherFont: 'ui05',
  display: 'block',
  padding: '4px 28px 4px 10px',
  cursor: 'pointer',
  borderRadius: '$r1',
  border: '1px solid black',
  borderColor: '$black',
  backgroundColor: '$white',
  color: '$black',
  $focusStyle: 'default',
})

const ExcludedFromPromo = styled(Link, {
  gridColumn: '2/3',
  textAlign: 'right',
  $aetherFont: 'ui05',
  color: '$gray_medium',
  textDecoration: 'underline',
})
const AdditionalBadges = styled('div', {
  display: 'grid',
  gap: '$8',
})
const getQuantityOptions = ({
  merchandise: { quantityAvailable, currentlyNotInStock, availableForSale },
  quantity,
}: ShopifyResolvedCartLine) => {
  const MAX_QUANTITY_CAP = 10
  const MIN_QUANTITY_CAP = 1

  const _quantityAvailable = quantityAvailable ?? 0

  // if product can be sold despite it is out of stock, we show MIN_QUANTITY_CAP in quantity select options
  const allowSellOutOfStock = currentlyNotInStock && availableForSale
  const productsQuantity =
    quantity > _quantityAvailable ? quantity : _quantityAvailable
  const maxProductsQuantity = allowSellOutOfStock
    ? MAX_QUANTITY_CAP
    : productsQuantity

  const ceil = (value: number, min: number, max: number): number =>
    Math.min(Math.max(value, min), max)

  const maxQuantity = ceil(
    maxProductsQuantity,
    MIN_QUANTITY_CAP,
    MAX_QUANTITY_CAP,
  )

  return [...Array(maxQuantity)].map((_, i) => i + 1)
}

export const CartLine: FC<CartLineProps> = ({
  line,
  colorsMap = [],
  readOnly = false,
  removeCartLines,
  updateCartLines,
  setCartModalOpen,
  isCartDiscountApplied,
  nonDiscountedProductTypes,
  nonDiscountedVendors,
}) => {
  const {
    merchandise: {
      product: {
        title: productTitle,
        colors,
        handle,
        preOrderMessage,
        finalSale,
        vendor,
        productType,
        collections,
      },
      image,
      title: variantTitle,
    },
    quantity,
  } = line

  const title = formatProductName(productTitle)
  const color = colorsMap.find(({ shopifyId }) => shopifyId === colors?.value)
  const quantityOptions = getQuantityOptions(line)
  const preOrderMessageValue = preOrderMessage?.value
  const isFinalSale = finalSale && JSON.parse(finalSale.value)
  const { t } = useTranslation('product')
  const { userRole } = useCustomerContext()
  const { tieredDiscounts } = useDiscounts({
    collections: [],
    userRole: userRole,
  })
  const isExcludedFromTieredDiscount = useIsExcludedFromDiscount({
    collections: collections.edges.map((edge) => edge.node.id),
    discounts: tieredDiscounts,
  })

  const handleQuantityChange = (e: ChangeEvent<HTMLSelectElement>) => {
    e.preventDefault()

    if (updateCartLines) {
      updateCartLines([{ id: line.id, quantity: Number(e.target.value) }])
    }
  }

  const handleLineItemClick = () => {
    if (setCartModalOpen) {
      setCartModalOpen(false)
    }
  }

  const showExcludedFromPromo = () => {
    if (isExcludedFromTieredDiscount) return true
    if (isCartDiscountApplied) return false

    if (vendor && nonDiscountedVendors?.includes(vendor)) return true

    if (productType && nonDiscountedProductTypes?.includes(productType))
      return true

    return false
  }

  return (
    <Wrap key={line.id}>
      <MediaWrap>
        <Link
          href={`${ROUTE_BASE_PRODUCTS}/${handle}`}
          onClick={handleLineItemClick}
        >
          {image && (
            <Media
              mediaPayload={image}
              sizes={'220px'} // TODO: set correct sizes
              layout={'fill'}
              hardcropRatio="portrait1"
              objectFit={'contain'}
            />
          )}
        </Link>
      </MediaWrap>
      <ProductInfoWrap>
        <Link
          href={`${ROUTE_BASE_PRODUCTS}/${handle}`}
          onClick={() => (setCartModalOpen ? setCartModalOpen(false) : null)}
        >
          <Title>{title}</Title>
        </Link>
        {!readOnly && removeCartLines ? (
          <CloseButton
            appearance="block"
            onClick={() => removeCartLines([line])}
            aria-label={t('removeButtonLabel', { productName: title })}
          >
            <Close size="small" />
          </CloseButton>
        ) : null}
        {color && (
          <SelectedOptionsWrap>
            <Color>
              <Media {...color.image} hardcropRatio="square" sizes="24px" />
            </Color>
            <SelectedOptionsLabel>{`${color.label} / ${variantTitle}`}</SelectedOptionsLabel>
          </SelectedOptionsWrap>
        )}
        <AdditionalBadges>
          {preOrderMessageValue && (
            <PreOrderText>{preOrderMessageValue}</PreOrderText>
          )}

          {isFinalSale && <FinalSaleText>{t('finalSale')}</FinalSaleText>}
        </AdditionalBadges>
        {readOnly ? (
          <QuantityText>{`QTY: ${quantity}`}</QuantityText>
        ) : (
          <SelectContainer>
            <Select
              value={quantity}
              onChange={handleQuantityChange}
              aria-label={t('quantitySelectLabel') as string}
            >
              {quantityOptions.map((amount) => (
                <option key={amount} value={amount}>
                  {amount}
                </option>
              ))}
            </Select>
          </SelectContainer>
        )}
        <PriceWrap>
          <ProductCartLinePrice line={line} />
        </PriceWrap>
      </ProductInfoWrap>
      {showExcludedFromPromo() && (
        <ExcludedFromPromo
          href="/faq"
          onClick={() => setCartModalOpen && setCartModalOpen(false)}
        >
          {t('excludedFromPromotion')}
        </ExcludedFromPromo>
      )}
    </Wrap>
  )
}
