import { ShopifyPrice } from '@aether/models'
import { FC } from 'react'
import { PriceCompare } from '../components/PriceCompare/PriceCompare'
import { DEFAULT_REGION_ID, normalizedRegions } from '@aether/configs'
import { ShopifyResolvedCart } from '@aether/shopify-sdk'

/**
 * If the discount was applied on LineItem level (not Cart level)
 * we need to calculate a sum of all cart lines before discount to show a proper compare price.
 * The sum of line items prices before discount is also used to display discount indicator in order to reflect
 * the Discounts logic in Shopify.
 *
 * @param cart
 */

export const CartTotalPrice: FC<{
  cart: ShopifyResolvedCart | null
}> = ({ cart }) => {
  const cartSubtotal = cart?.cost?.subtotalAmount
  const cartTotal = cart?.cost?.totalAmount
  const currencyCode =
    cart?.cost.subtotalAmount.currencyCode ||
    normalizedRegions[DEFAULT_REGION_ID].currencyCode

  const cartSubtotalAmount = Number(cartSubtotal?.amount)
  const cartTotalAmount = Number(cartTotal?.amount)

  const taxAmount = cart?.cost.totalTaxAmount?.amount
    ? Number(cart?.cost.totalTaxAmount?.amount)
    : 0
  const dutyAmount = cart?.cost.totalDutyAmount?.amount
    ? Number(cart?.cost.totalDutyAmount?.amount)
    : 0

  const cartLinesSubtotal =
    cart?.lines?.reduce((totalPrice, line) => {
      return Number(line.cost.subtotalAmount.amount) + totalPrice
    }, 0) || 0

  const cartLinesTotalAmount =
    cart?.lines?.reduce((totalPrice, line) => {
      return Number(line.cost.totalAmount.amount) + totalPrice
    }, 0) || 0

  const cartLinesDiscounts = cartLinesSubtotal - cartLinesTotalAmount

  const price: ShopifyPrice = {
    amount: `${cartTotalAmount - taxAmount - dutyAmount}`,
    currencyCode: currencyCode,
  }

  const compareAtPrice: ShopifyPrice = {
    amount: `${cartSubtotalAmount + cartLinesDiscounts}`,
    currencyCode: currencyCode,
  }

  return <PriceCompare price={price} compareAtPrice={compareAtPrice} />
}
