import { addBreadcrumb, setTag } from '@sentry/nextjs'

export const accountAnalytics = {
  logIn: (role: string) => {
    setTag('logged_in', true)
    setTag('user_role', role)

    addBreadcrumb({
      category: 'auth',
      message: 'User logged in',
      level: 'info',
    })
  },
  logOut: () => {
    setTag('logged_in', false)
    setTag('user_role', undefined)

    addBreadcrumb({
      category: 'auth',
      message: 'User logged off',
      level: 'info',
    })
  },
}
