import { coalesceLocaleField } from '../helpers/coalesceLocaleField'
import { groq } from 'next-sanity'
import { LocaleId } from '@aether/configs'
import { createMediaProjection } from '../projections/createMediaProjection'
import { createLinkProjection } from '../projections/createLinkProjection'

export const createSectionTripleCardsProjection = (localeId: LocaleId) => {
  const LINK_PROJECTION = createLinkProjection(localeId)
  const MEDIA_PROJECTION = createMediaProjection(localeId)
  return groq`{
        _key,
        _type,
        ${coalesceLocaleField('title', localeId)},
        sectionSpaceSize,
        borderPosition,
        sectionColor,
        cards[] {
          ${coalesceLocaleField('label', localeId)},
          ${coalesceLocaleField('title', localeId)},
          ${coalesceLocaleField('text', localeId)},
          link${LINK_PROJECTION},
          image${MEDIA_PROJECTION},
      }
  }
  `
}
