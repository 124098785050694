import { LocaleId } from '@aether/configs'
import {
  createShopifySdk,
  formatShopifyProduct,
  ShopifyPageInfo,
  ShopifyResolvedListProduct,
} from '@aether/shopify-sdk'
import { createShopifyClient } from './createShopifyClient'
import { getProductRootTitle } from './helpers/getProductRootTitle'

export const RELATED_PRODUCTS_DEFAULT_PAGE_SIZE = 100

type PropsType = {
  titles: string[]
  locale: LocaleId
  count?: number
  afterCursor?: string
}

type ReTurnType = Promise<{
  products: ShopifyResolvedListProduct[]
  pageInfo: ShopifyPageInfo
} | null>

export const getRelatedProductsQuery = (titles: string[]): string => {
  const allProductsTitlePatterns = titles.map(
    (title) => `${getProductRootTitle(title)} - *`,
  )

  const uniqueProductTitlePatterns = [
    ...new Set(allProductsTitlePatterns),
  ].sort()

  if (uniqueProductTitlePatterns.length === 0) {
    return ''
  }

  return uniqueProductTitlePatterns
    .map((title) => `(title:${title})`)
    .join(' OR ')
}

export const getSDKShopifyRelatedProducts = async ({
  titles,
  locale,
  count = RELATED_PRODUCTS_DEFAULT_PAGE_SIZE,
  afterCursor,
}: PropsType): ReTurnType => {
  const shopifyClient = createShopifyClient(locale)
  const relatedProductsQuery = getRelatedProductsQuery(titles)

  if (!titles || titles.length === 0) {
    return null
  }

  return await createShopifySdk(shopifyClient)
    .getSearchResults({
      count: count,
      query: relatedProductsQuery,
      afterCursor,
    })
    .then((res) => {
      return res.data
        ? {
            products: res.data.products.nodes.map(formatShopifyProduct),
            pageInfo: res.data.products.pageInfo,
          }
        : null
    })
}
