import { ModuleData } from '@aether/models'
import React from 'react'
import { StickyCtaModule } from './components/StickyCtaModule'

type Props = {
  moduleData: ModuleData
}

export const Module = ({ moduleData }: Props) => {
  switch (moduleData._type) {
    case 'stickyCtaModule':
      return <StickyCtaModule {...moduleData} />
    default:
      return null
  }
}
