import { PageBuilderSection, PageResponse } from '@aether/models'

/**
 * Collection page can contain multiple sections. Each section can have "collectionProductsAmount" field that specifies the amount of products to display,
 * In order to prevent duplicate products on the page, we specify the START and END position of the product in the products list, so sections know which products they should include,
 * If the END position is not specified - the special "Last section" will display all remaining products from the START position.
 */
export const withCollectionDistributedProducts = (
  pageData: PageResponse<PageBuilderSection[]> | undefined,
): PageResponse<PageBuilderSection[]> | undefined => {
  if (!pageData) {
    return undefined
  }
  const getPreviousProductsAmount = (index: number): number =>
    pageData?.pageBuilder
      .slice(0, index)
      .reduce<number>((productsAmount, section) => {
        if ('collectionProductsAmount' in section) {
          return productsAmount + Number(section?.collectionProductsAmount)
        }

        return productsAmount
      }, 0)

  const pageBuilder = pageData?.pageBuilder.map<PageBuilderSection>(
    (section, index) => {
      const isCollectionTypeSection =
        'collectionProductsAmount' in section ||
        'isLastCollectionSection' in section

      if (isCollectionTypeSection) {
        const isLastSection = section?.isLastCollectionSection
        const startItem = getPreviousProductsAmount(index)
        const endItem = startItem + Number(section.collectionProductsAmount)

        return {
          ...section,
          ...(isLastSection ? {} : { endItem }),
          startItem,
        }
      }

      return section
    },
    [],
  )

  return { ...pageData, pageBuilder }
}
