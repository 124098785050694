import { coalesceLocaleField } from '../helpers/coalesceLocaleField'
import { groq } from 'next-sanity'
import { LocaleId } from '@aether/configs'
import { createLinkProjection } from '../projections/createLinkProjection'
import { sanityImageProjection } from '../projections/createMediaProjection'

export const createSectionStackImagesProjection = (localeId: LocaleId) => {
  const LINK_PROJECTION = createLinkProjection(localeId)

  return groq`{
    _key,
    _type,
    ${coalesceLocaleField('title', localeId)},
    ${coalesceLocaleField('description', localeId)},
    "images": {stackImages[] {
        "type": 'sanity-image',
        "image": {
          ...${sanityImageProjection('image')},
          ${coalesceLocaleField('alt', localeId)}
        },
    }}.stackImages,
    ctaLink${LINK_PROJECTION},
    ${coalesceLocaleField('ctaLabel', localeId)},
    secondaryCtaLink${LINK_PROJECTION},
    ${coalesceLocaleField('secondaryCtaLabel', localeId)},
    sectionSpaceSize,
    imagePosition,
  }`
}
