import React, { FC } from 'react'

import { styled } from '@aether/styles'
import { StyledClickable } from '@aether/components'
import { useTranslation } from 'react-i18next'
import { getI18nPrice } from '@aether/utils'
import { useRouter } from 'next/router'
import { useCartContext } from '../useCartContext'

type AfterpayWidgetProps = {
  className?: string
}

const Root = styled('div', {
  position: 'fixed',
  bottom: 0,
  width: '100vw',
  backgroundColor: '$black',
  zIndex: '$returnsBanner',
  padding: '$32',
  display: 'grid',
  gridTemplateColumns: '1fr auto',
  gap: '$20',
  alignItems: 'center',
})

const Text = styled('span', {
  gridColumn: '1/2',
  $aetherFont: 'heading06',
  color: '$white',
  '@md': {
    $aetherFont: 'heading05',
  },
})

const BackButton = styled(StyledClickable, {
  gridColumn: '2/3',
})

export const ReturnsBanner: FC<AfterpayWidgetProps> = ({ className }) => {
  const { isReturnsMode, returnsState } = useCartContext()
  const { locale } = useRouter()
  const { t } = useTranslation('returns')
  if (!isReturnsMode) {
    return null
  }
  const currency = returnsState?.currency
  const total = returnsState?.total
  const backUrl = returnsState?.redirectUrl

  const price =
    total &&
    getI18nPrice(
      {
        amount: total ? String(total) : '',
        // TODO: replace with default lang price?
        currencyCode: currency || 'usd',
      },
      locale,
    )

  return (
    <Root className={className}>
      {price && (
        <Text>
          {t('totalCreditIndicator', {
            value: price,
          })}
        </Text>
      )}
      {backUrl && (
        <a href={backUrl}>
          <BackButton appearance={'badgeWhite'}>
            {t('backButtonLabel')}
          </BackButton>
        </a>
      )}
    </Root>
  )
}
