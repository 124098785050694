import { coalesceLocaleField } from '../helpers/coalesceLocaleField'
import { groq } from 'next-sanity'
import { LocaleId } from '@aether/configs'
import { createMediaProjection } from '../projections/createMediaProjection'
import { createRichTextProjection } from '../projections/createRichTextProjection'
import { createLinkProjection } from '../projections/createLinkProjection'

export const createSectionCatalogProjection = (localeId: LocaleId) => {
  const MEDIA_PROJECTION = createMediaProjection(localeId)
  const RICH_TEXT_PROJECTION = createRichTextProjection(localeId)
  const LINK_PROJECTION = createLinkProjection(localeId)

  return groq`{
        _key,
        _type,
        catalogCover${MEDIA_PROJECTION},
        ${coalesceLocaleField('catalogTitle', localeId)},
        ${coalesceLocaleField('catalogDescription', localeId)},
        ${coalesceLocaleField('ctaLabel', localeId)},
        ctaLink${LINK_PROJECTION},
        ${coalesceLocaleField('content', localeId)}[]${RICH_TEXT_PROJECTION},
      }
  `
}
