import { gql } from 'graphql-request'

export const PRODUCT_EXTENDED_FRAGMENT = gql`
  fragment MoneyProductFragment on MoneyV2 {
    amount
    currencyCode
  }
  fragment ProductPriceRangeFragment on ProductPriceRange {
    maxVariantPrice {
      amount
      currencyCode
    }
    minVariantPrice {
      amount
      currencyCode
    }
  }
  fragment ProductImageFragment on Image {
    url
    width
    height
    altText
    id
  }

  fragment ProductVariantFragment on ProductVariant {
    id
    title
    sku
    quantityAvailable
    availableForSale
    currentlyNotInStock
    image {
      ...ProductImageFragment
    }
    price {
      ...MoneyProductFragment
    }
    compareAtPrice {
      ...MoneyProductFragment
    }
    product {
      id
      title
      handle
    }
    selectedOptions {
      name
      value
    }
  }

  fragment ProductExtendedFragment on Product {
    id
    handle
    title
    description
    descriptionHtml
    availableForSale
    seo {
      title
      description
    }
    tags
    handle
    createdAt
    featuredImage {
      ...ProductImageFragment
    }
    images(first: 20) {
      edges {
        node {
          ...ProductImageFragment
        }
      }
    }
    options {
      name
      values
    }
    variants(first: 100) {
      nodes {
        ...ProductVariantFragment
      }
    }
    compareAtPriceRange {
      ...ProductPriceRangeFragment
    }
    priceRange {
      ...ProductPriceRangeFragment
    }
    colors: metafield(key: "color", namespace: "product") {
      key
      value
    }
    colorGroup: metafield(key: "color_group", namespace: "product") {
      key
      value
    }
    vipProduct: metafield(key: "vip_product", namespace: "product") {
      key
      value
    }
    sizeGuide: metafield(key: "size_guide", namespace: "product") {
      key
      value
    }
    typeOfProduct: metafield(key: "type_of_product", namespace: "product") {
      key
      value
    }
    activity: metafield(key: "activity", namespace: "product") {
      key
      value
    }
    temperature: metafield(key: "temperature", namespace: "product") {
      key
      value
    }
    techFeatures: metafield(key: "tech_features", namespace: "product") {
      key
      value
    }
    plpMainFeature: metafield(key: "plp_main_feature", namespace: "product") {
      key
      value
    }
    badge: metafield(key: "badge", namespace: "product") {
      key
      value
    }
    greyBadge: metafield(key: "grey_badge", namespace: "product") {
      key
      value
    }
    vipProduct: metafield(key: "vip_product", namespace: "product") {
      key
      value
    }
    hideSwatch: metafield(key: "hideswatch", namespace: "product") {
      key
      value
    }
    performanceWaterproof: metafield(
      key: "performance-waterproof"
      namespace: "product"
    ) {
      key
      value
    }
    performanceWindproof: metafield(
      key: "performance-windproof"
      namespace: "product"
    ) {
      key
      value
    }
    performanceInsulation: metafield(
      key: "performance-insulation"
      namespace: "product"
    ) {
      key
      value
    }
    performancePowderRating: metafield(
      key: "performance-powder-rating"
      namespace: "product"
    ) {
      key
      value
    }
    performanceMotoRating: metafield(
      key: "performance-moto-rating"
      namespace: "product"
    ) {
      key
      value
    }
    performanceMovementRating: metafield(
      key: "performance-movement-rating"
      namespace: "product"
    ) {
      key
      value
    }
    performanceTemperatureRange: metafield(
      key: "performance-temperature-range"
      namespace: "product"
    ) {
      key
      value
    }
    secondaryDescription: metafield(
      key: "secondary_description"
      namespace: "product"
    ) {
      key
      value
    }
    modelDescription: metafield(
      key: "model_description"
      namespace: "product"
    ) {
      key
      value
    }
    pdpType: metafield(key: "pdp_type", namespace: "product") {
      key
      value
    }
    preOrderIndicator: metafield(
      key: "pre_order_indicator"
      namespace: "product"
    ) {
      key
      value
    }
    preOrderMessage: metafield(key: "pre_order_message", namespace: "product") {
      key
      value
    }
    finalSale: metafield(key: "final_sale", namespace: "product") {
      key
      value
    }
    mainCollectionHandle: metafield(
      key: "main_collection_handle"
      namespace: "product"
    ) {
      key
      value
    }
    breadcrumbs: metafield(
      key: "collection_breadcrumbs"
      namespace: "product"
    ) {
      key
      value
    }
    relatedProducts: metafield(
      key: "related_products"
      namespace: "shopify--discovery--product_recommendation"
    ) {
      key
      value
    }
    complementaryProducts: metafield(
      key: "complementary_products"
      namespace: "shopify--discovery--product_recommendation"
    ) {
      key
      value
    }
    soldOut: metafield(key: "sold_out", namespace: "product") {
      key
      value
    }
    collections(first: 250) {
      edges {
        node {
          id
          title
          handle
        }
      }
    }
  }
`
