import { StickyWrapper, useShowOnScroll } from '../../Sticky'
import { StickyCtaModuleProps } from '@aether/models'
import { styled } from '@aether/styles'
import { Link } from '../../Link/Link'
const ButtonContent = styled('div', {
  $aetherFont: 'ui01',
  lineHeight: 1,
  height: '$buttonHeightL',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  background: 'transparent',
  gap: '$48',
  color: '$white',
  '&:hover': {
    background: '$black',
    color: '$white',
  },
})

export const StickyCtaModule = (props: StickyCtaModuleProps) => {
  const isVisible = useShowOnScroll({
    showAfter: 'section:nth-child(2)',
    showAfterOffsetType: 'topInView',
    hideAfter: 'footer',
    hideAfterOffsetType: 'topInView',
  })
  return (
    <StickyWrapper visible={isVisible}>
      <Link appearance="badgeBlack" {...props.ctaLink}>
        <ButtonContent>
          <span>{props.title}</span>
          <span>{props.ctaText}</span>
        </ButtonContent>
      </Link>
    </StickyWrapper>
  )
}
