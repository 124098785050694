import { groq } from 'next-sanity'
import { LocaleId } from '@aether/configs'
import { createProductGuaranteesProjection } from '../projections/createProductGuaranteesProjection'

export const createSectionProductGuaranteesProjection = (
  localeId: LocaleId,
) => {
  const PRODUCT_GUARANTEES = createProductGuaranteesProjection(localeId)
  return groq`{
        _key,
        _type,
        ...*[_type == 'productGuarantees'][0]${PRODUCT_GUARANTEES}
      }
  `
}
