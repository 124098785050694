import { coalesceLocaleField } from '../helpers/coalesceLocaleField'
import { groq } from 'next-sanity'
import { LocaleId } from '@aether/configs'

export const createSectionQuoteProjection = (localeId: LocaleId) => {
  return groq`{
        _key,
        _type,
        ${coalesceLocaleField('quote', localeId)},
        "spaceSize":sectionSpaceSize,
        showLine,
        author
      }
  `
}
