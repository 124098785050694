import React from 'react'
import NextImage, { ImageProps } from 'next/image'
import { MediaSizesProps, buildSizes } from '../helpers/buildSizes'
import {
  MediaLayoutType,
  MediaObjectFitType,
  MediaObjectPositionType,
} from '../models/MediaProps'

export const Image: React.FunctionComponent<
  Omit<ImageProps, 'sizes' | 'layout' | 'objectPosition' | 'objectFit'> & {
    sizes?: MediaSizesProps
    layout?: MediaLayoutType
    objectFit?: MediaObjectFitType
    objectPosition?: MediaObjectPositionType
  }
> = ({
  alt,
  width,
  src,
  height,
  sizes,
  priority,
  placeholder,
  loader,
  blurDataURL,
  layout,
  objectFit,
  objectPosition,
}) => {
  const isFillLayout = layout === 'fill'
  const isResponsiveLayout = layout === 'responsive'
  const isResponsiveCoverLayout = layout === 'responsive-cover'

  const imgSizes = buildSizes(sizes)
  return (
    <NextImage
      src={src}
      width={isFillLayout ? undefined : width}
      height={isFillLayout ? undefined : height}
      alt={alt}
      fill={isFillLayout}
      loader={loader}
      sizes={imgSizes}
      placeholder={placeholder}
      blurDataURL={blurDataURL}
      priority={priority}
      className={'Image'}
      style={{
        display: 'block',
        ...(isFillLayout ? { objectFit: 'cover' } : {}),
        ...(objectFit ? { objectFit } : {}),
        ...(objectPosition ? { objectPosition } : {}),
        ...(isResponsiveLayout ? { width: '100%', height: 'auto' } : {}),
        ...(isResponsiveCoverLayout ? { width: '100%', height: '100%' } : {}),
      }}
    />
  )
}
