import { useCallback, useEffect, useRef, useState } from 'react'

type Offset = 'topInView' | 'bottomInView' | 'topOutOfView' | 'bottomOutOfView'

export interface UseShowStickyProps {
  /**
   * Query selector or element to track for the top position (or undefined for '0')
   */
  showAfter?: HTMLElement | string | null
  /**
   * Query selector or element to track for the bottom position (or undefined for 'Infinity')
   */
  hideAfter?: HTMLElement | string | null
  /**
   * Extra offset for the top element in px (positive for lower down the page)
   */
  showAfterOffset?: number
  /**
   * Extra offset for the bottom element in px (positive for lower down the page)
   */
  hideAfterOffset?: number
  /**
   * Type of offset to use for 'showAfter'
   *
   * `topInView` - top of the element is visible (at the bottom of the viewport)
   *
   * `bottomInView` - bottom of the element is visible (at the bottom of the viewport)
   *
   * `topOutOfView` - top of the element is no longer visible (at the top of the viewport)
   *
   * `bottomOutOfView` - bottom of the element is no longer visible (at the top of the viewport)
   */
  showAfterOffsetType?: Offset
  /**
   * Type of offset to use for 'hideAfter'
   *
   * `topInView` - top of the element is visible (at the bottom of the viewport)
   *
   * `bottomInView` - bottom of the element is visible (at the bottom of the viewport)
   *
   * `topOutOfView` - top of the element is no longer visible (at the top of the viewport)
   *
   * `bottomOutOfView` - bottom of the element is no longer visible (at the top of the viewport)
   */
  hideAfterOffsetType?: Offset
  /**
   * Initial value, will also be used for server side render
   */
  defaultValue?: boolean
}

const getScrollAmount = (element: HTMLElement, type: Offset, offsetPx = 0) => {
  const pos = element.offsetTop
  if (typeof window === 'undefined') return 0
  switch (type) {
    case 'topInView':
      return pos + offsetPx - window.innerHeight
    case 'bottomInView':
      return pos + offsetPx + element.clientHeight - window.innerHeight
    case 'topOutOfView':
      return pos + offsetPx
    case 'bottomOutOfView':
      return pos + offsetPx + element.clientHeight
    default:
      return pos + offsetPx
  }
}
/**
 * Used to toggle an element or do other logic, based on the scroll position
 *
 * Uses a scroll listener
 */
export const useShowOnScroll = ({
  hideAfter: hideAfterQuery,
  showAfter: showAfterQuery,
  hideAfterOffset,
  showAfterOffset,
  showAfterOffsetType = 'topInView',
  hideAfterOffsetType = 'topInView',
  defaultValue = false,
}: UseShowStickyProps) => {
  const showAfterRef = useRef<HTMLElement>()
  const hideAfterRef = useRef<HTMLElement>()
  useEffect(() => {
    if (typeof window === 'undefined') return
    if (typeof showAfterQuery === 'string') {
      showAfterRef.current = document.querySelector(
        showAfterQuery,
      ) as HTMLElement
    } else if (showAfterQuery) {
      showAfterRef.current = showAfterQuery
    }
    if (typeof hideAfterQuery === 'string') {
      hideAfterRef.current = document.querySelector(
        hideAfterQuery,
      ) as HTMLElement
    } else if (hideAfterQuery) {
      hideAfterRef.current = hideAfterQuery
    }
  }, [hideAfterQuery, showAfterQuery])
  const [isVisible, setIsVisible] = useState(defaultValue)

  const scrollListener = useCallback(() => {
    const showAfter = showAfterRef.current
    const hideAfter = hideAfterRef.current
    const position = window.pageYOffset
    const topPosition = showAfter
      ? getScrollAmount(showAfter, showAfterOffsetType, showAfterOffset)
      : 0
    const bottomPosition = hideAfter
      ? getScrollAmount(hideAfter, hideAfterOffsetType, hideAfterOffset)
      : Infinity
    const show = position >= topPosition
    const hide = position >= bottomPosition
    setIsVisible(show && !hide)
  }, [
    hideAfterOffset,
    hideAfterOffsetType,
    showAfterOffset,
    showAfterOffsetType,
  ])

  useEffect(() => {
    window.addEventListener('scroll', scrollListener)
    scrollListener()
    return () => {
      window.removeEventListener('scroll', scrollListener)
    }
  }, [scrollListener])

  return isVisible
}
