import { toPlainText } from '../toPlainText'

import { algoliaStoreMock } from './algoliaStoreMock'
import { SanityStoreType } from './types'
import { extractTextContentFromPageBuilder } from '../pageBuilder/extractTextContentFromPageBuilder'

export function mapSanityStoreToAlgoliaStore(
  store: SanityStoreType,
): Partial<typeof algoliaStoreMock> {
  const additionalNotes = store.additionalNotes
    ? toPlainText(store.additionalNotes.en)
    : undefined
  const addressInfo = store.addressInfo
    ? toPlainText(store.addressInfo.en)
    : undefined
  const description = store.description
    ? toPlainText(store.description.en)
    : undefined
  const metaDescription = store.metaDescription
    ? store.metaDescription.en
    : undefined
  const pageBuilder = store.pageBuilder
    ? extractTextContentFromPageBuilder(store.pageBuilder)
    : undefined
  const title = store.title
  const path = store.path.current

  return {
    type: store._type,
    additionalNotes,
    addressInfo,
    description,
    metaDescription,
    pageBuilder,
    title,
    path,
  }
}
