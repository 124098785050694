import {
  FC,
  useRef,
  useEffect,
  useState,
  ForwardedRef,
  ReactElement,
  MouseEventHandler,
} from 'react'
import { MuxVideoMediaPayload, MuxVideoType } from '@aether/models'
import { MediaProps } from '../models/MediaProps'
import { ResponsiveMediaComponent } from './ResponsiveMediaComponent'
import { Video } from './Video'

type MuxVideoProps = Omit<MediaProps, 'mediaPayload'> & {
  mediaPayload: MuxVideoMediaPayload
  videoRef?: ForwardedRef<HTMLVideoElement>
  videoOverride?: (video: MuxVideoType) => ReactElement
  videoOverrideMobile?: (video: MuxVideoType) => ReactElement
}

type VideoAdapterProps = Omit<MediaProps, 'mediaPayload'> & {
  video: MuxVideoType
  ref?: ForwardedRef<HTMLVideoElement>
}

const VideoAdapter: FC<VideoAdapterProps> = ({
  layout,
  priority,
  video,
  alt,
  loop,
  muted = true,
  controlsPosition = 'bottom-right',
  autoplay = true,
  hardcropRatio,
  sizes,
}) => {
  const { firstFrame, mp4, width, height } = video || {}
  const videoRef = useRef<HTMLVideoElement>(null)
  const [play, setPlay] = useState(true)
  const [isPlaying, setIsPlaying] = useState(true)
  const [src, setSrc] = useState<string | undefined>(priority ? mp4 : undefined)

  const containerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (mp4 !== src && priority) {
      setSrc(mp4)
    }
  }, [priority, mp4, src])

  useEffect(() => {
    const container = containerRef.current

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setSrc(mp4)
          }

          if (!entry.isIntersecting) {
            videoRef.current?.pause()
          }
        })
      },
      {
        rootMargin: '200px',
      },
    )

    if (container && !priority) {
      observer.observe(container)
    }

    return () => {
      if (container && !priority) {
        observer.unobserve(container)
        observer.disconnect()
      }
    }
  }, [mp4, priority])

  const handleIsPaused: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.preventDefault()
    setPlay(false)
    setIsPlaying(false)
  }

  const handleIsPlaying: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.preventDefault()
    setPlay(true)
    setIsPlaying(true)
  }

  const handleEnded = () => {
    setIsPlaying(false)
  }

  if (!video.mp4) return null

  return (
    <Video
      src={src}
      width={width}
      height={height}
      layout={layout}
      sizes={sizes}
      play={play}
      isPlaying={isPlaying}
      autoplay={autoplay}
      ref={containerRef}
      onRequestPause={handleIsPaused}
      onRequestPlay={handleIsPlaying}
      onEnded={handleEnded}
      controlsPosition={controlsPosition}
      firstFrame={firstFrame}
      alt={alt}
      loop={loop}
      muted={muted}
      hardcropRatio={hardcropRatio}
      priority={priority}
    />
  )
}
export const MuxVideo = ({
  mediaPayload,
  videoOverride,
  videoOverrideMobile,
  ...restOfProps
}: MuxVideoProps) => {
  const { video, mobileVideo } = mediaPayload

  if (!video) {
    return null
  }

  const MobileComponent = (() => {
    if (!mobileVideo) {
      return undefined
    }

    if (videoOverrideMobile) {
      return videoOverrideMobile(mobileVideo)
    }

    return <VideoAdapter video={mobileVideo} {...restOfProps} />
  })()

  const DesktopComponent = (() => {
    if (videoOverride) {
      return videoOverride(video)
    }

    return <VideoAdapter video={video} {...restOfProps} />
  })()

  return (
    <ResponsiveMediaComponent
      mobileComponent={MobileComponent}
      component={DesktopComponent}
    />
  )
}
