import {
  ShopifyProductsData,
  SectionShopifyId,
  PageBuilderSection,
} from '@aether/models'

import { getShopifyRelatedProductsData } from './getShopifyRelatedProductsData'
import { getShopifySingleProductsData } from './getShopifySingleProductsData'
import { getShopifyBaseProductsAndVariants } from './getShopifyBaseProductsAndVariants'
import { validateShopifyIds } from './helpers/validateShopifyIds'

export const getShopifyProductsData = async ({
  locale,
  isVip = false,
  pageBuilderSections = [],
}: {
  locale: string
  isVip?: boolean
  pageBuilderSections?: PageBuilderSection[]
}): Promise<ShopifyProductsData> => {
  if (!pageBuilderSections) {
    return {
      products: {},
      variants: {},
    }
  }

  const allSectionsShopifyIds: SectionShopifyId[] = pageBuilderSections.reduce<
    Array<SectionShopifyId>
  >((acc, section) => {
    if ('shopifyIds' in section) {
      const { shopifyIds } = section
      return [...acc, ...validateShopifyIds(shopifyIds)]
    }
    return acc
  }, [])

  // return without fetching data if no ids were found in any section
  if (allSectionsShopifyIds.length === 0) {
    return {
      products: {},
      variants: {},
    }
  }

  // 1. get basic products nodes and variants nodes requested in sections
  // - "basic products" nodes will be further used for fetch all related product colors - we need product titles to find the relations
  // - directly requested variants will be added to final response
  const relatedProductsIds = allSectionsShopifyIds
    .filter(
      ({ type }) => type === 'product-related' || type === 'product-variant',
    )
    .map(({ id }) => id)
    .filter((id) => Boolean(id))
  const { products: baseProductsNodes, variants } =
    await getShopifyBaseProductsAndVariants(locale, relatedProductsIds, isVip)

  // 2. use basic product nodes  to fetch all colors and their variants for all shopifyIds of "product-and-colors" type
  const { products: relatedProducts, variants: relatedProductsVariants } =
    await getShopifyRelatedProductsData(locale, baseProductsNodes, isVip)

  // 3. fetch any other products (without related colors) if they were not already fetched in previous step
  const fetchedProductIds = Object.keys(relatedProducts)
  const singleProductsIds = allSectionsShopifyIds
    .filter(({ type }) => type === 'single-product')
    .map(({ id }) => id)
  const remainingProductsIds = singleProductsIds.filter(
    (id) => !fetchedProductIds.includes(id),
  )

  const { products: singleProducts, variants: singleProductsVariants } =
    await getShopifySingleProductsData(locale, remainingProductsIds, isVip)

  return {
    products: { ...relatedProducts, ...singleProducts },
    variants: {
      ...relatedProductsVariants,
      ...singleProductsVariants,
      ...variants,
    },
  }
}
