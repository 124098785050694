import { FC } from 'react'
import { useRouter } from 'next/router'
import { getI18nPrice } from '@aether/utils'
import { ShopifyPrice } from '@aether/models'

export const PricePrimitive: FC<{
  price?: ShopifyPrice
  testId?: string
}> = ({ price, testId }) => {
  const { locale } = useRouter()
  const internationalizedPrice = price && getI18nPrice(price, locale)

  return <span data-testid={testId}>{price && internationalizedPrice}</span>
}
