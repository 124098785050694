import { groq } from 'next-sanity'
import { createMediaProjection } from './createMediaProjection'
import { createSEOProjection } from './createSEOProjection'

export const createStoresLandingPageProjection = (localeId: string) => {
  const MEDIA_PROJECTION = createMediaProjection(localeId)
  const SEO_PROJECTION = createSEOProjection(localeId)

  return groq`{
      "type":_type,
      "hideHeader": false,
      "hideFooter": false,
      "seo": seo${SEO_PROJECTION},
      "pageBuilder": [{
         "_type": "sectionStoresLandingPage",
         "_key": "sectionStoresLandingPage" + _id,
         stores[]->{
          heroMedia${MEDIA_PROJECTION},
          title,
          "path":path.current
         }
      }]
  }`
}
