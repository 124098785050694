import { ShopifyPriceRange, ShopifyProduct } from '@aether/models'
import { Discount } from '@aether/discounts/utils-context'

export function calculateCompareAtPriceRange(
  product: ShopifyProduct,
  discount?: Discount,
): ShopifyPriceRange {
  if (discount) {
    if (
      product?.compareAtPriceRange &&
      parseFloat(product.compareAtPriceRange.minVariantPrice.amount) !== 0
    ) {
      return product.compareAtPriceRange
    } else {
      return product?.priceRange
    }
  }

  return product?.compareAtPriceRange
}
