import { FC } from 'react'

export const BrandNameIcon: FC<{ className?: string }> = ({ className }) => (
  <svg
    className={className}
    viewBox="0 0 1300 162"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    aria-describedby="logoTitleID"
  >
    <title id="logoTitleID">Aether logo</title>
    <path
      d="M0.5 161.255L77.3556 0H79.7131L153.268 161.255H124.506L111.776 133.436H40.578L27.8474 161.255H0.5ZM102.345 111.276L76.884 52.8087L50.9512 110.804H102.345V111.276Z"
      fill="black"
    />
    <path
      d="M261.244 5.1875H352.244V29.2343H288.12V67.4263H343.286V91.0017H288.12V136.738H353.187V161.256H261.244V5.1875Z"
      fill="black"
    />
    <path
      d="M538.96 161.256H511.613V28.7628H458.804V5.1875H591.298V28.7628H538.96V161.256Z"
      fill="black"
    />
    <path
      d="M703.988 5.1875H731.336V67.4263H805.834V5.1875H832.709V161.256H805.834V91.4732H731.336V161.256H703.988V5.1875Z"
      fill="black"
    />
    <path
      d="M963.788 5.1875H1054.79V29.2343H990.664V67.4263H1045.83V91.0017H990.664V136.738H1055.73V161.256H963.788V5.1875Z"
      fill="black"
    />
    <path
      d="M1179.74 5.1875H1216.04C1235.85 5.1875 1251.41 6.60202 1264.61 16.0322C1276.4 24.0478 1283 36.3069 1283 49.5091C1283 69.7839 1270.74 85.3436 1249.05 92.8877L1299.5 161.256H1267.44L1219.82 96.6597H1206.61V161.256H1179.74V5.1875ZM1219.34 76.385C1243.39 76.385 1255.65 67.4263 1255.65 51.3951C1255.65 34.4209 1242.45 27.3483 1216.99 27.3483H1206.61V76.385H1219.34Z"
      fill="black"
    />
  </svg>
)
