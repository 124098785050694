import { createLinkProjection } from './../projections/createLinkProjection'
import { createMediaProjection } from './../projections/createMediaProjection'
import { coalesceLocaleField } from '../helpers/coalesceLocaleField'
import { groq } from 'next-sanity'
import { LocaleId } from '@aether/configs'

export const createSectionSinglePromoProjection = (localeId: LocaleId) => {
  const MEDIA_PROJECTION = createMediaProjection(localeId)
  const LINK_PROJECTION = createLinkProjection(localeId)
  return groq`{
        _key,
        _type,
        ${coalesceLocaleField('title', localeId)},
        ${coalesceLocaleField('description', localeId)},
        ${coalesceLocaleField('ctaLabel', localeId)},
        ctaAppearance,
        sectionSpaceSize,
        borderPosition,
        textColor,
        image${MEDIA_PROJECTION},
        ctaLink${LINK_PROJECTION},
        textPosition,
        isTextSticky,
        secondaryCtaLink${LINK_PROJECTION},
        ${coalesceLocaleField('secondaryCtaLabel', localeId)},
        secondaryCtaAppearance,
      }
  `
}
