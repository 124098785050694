import { FC } from 'react'

type Props = {
  width?: number
  height?: number
  color?: 'white' | 'black'
}

export const FilterIcon: FC<Props> = ({
  width = 21,
  height = 21,
  color = 'white',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.2558 3.62953V0.132812H17.1311V3.62953C16.047 3.88218 15.2365 4.85237 15.2365 6.00446C15.2365 7.15656 16.047 8.12675 17.1311 8.3794V20.1328H18.2558V8.3794C19.3399 8.12675 20.1504 7.15656 20.1504 6.00446C20.1504 4.85237 19.3399 3.88218 18.2558 3.62953ZM17.6884 7.32837C16.9589 7.32837 16.3611 6.7321 16.3611 6.00446C16.3611 5.27682 16.9589 4.68056 17.6884 4.68056C18.4179 4.68056 19.0156 5.27682 19.0156 6.00446C19.0156 6.7321 18.4179 7.32837 17.6884 7.32837Z"
        fill={color}
      />
      <path
        d="M3.16964 3.62953V0.132812H2.04502V3.62953C0.960928 3.88218 0.150391 4.84226 0.150391 6.00446C0.150391 7.16667 0.960928 8.12675 2.04502 8.3794V20.1328H3.16964V8.3794C4.25373 8.12675 5.06427 7.15656 5.06427 6.00446C5.06427 4.85237 4.25373 3.88218 3.16964 3.62953ZM2.6124 7.32837C1.88291 7.32837 1.28514 6.7321 1.28514 6.00446C1.28514 5.27682 1.88291 4.68056 2.6124 4.68056C3.34188 4.68056 3.93965 5.27682 3.93965 6.00446C3.93965 6.7321 3.34188 7.32837 2.6124 7.32837Z"
        fill={color}
      />
      <path
        d="M10.7076 12.2096V0.132812H9.58301V12.2096C8.49892 12.4623 7.68838 13.4325 7.68838 14.5846C7.68838 15.7367 8.49892 16.7068 9.58301 16.9595V20.1227H10.7076V16.9595C11.7917 16.7068 12.6023 15.7367 12.6023 14.5846C12.6023 13.4325 11.7917 12.4623 10.7076 12.2096ZM10.1301 15.9287C9.40064 15.9287 8.80287 15.3324 8.80287 14.6048C8.80287 13.8771 9.40064 13.2809 10.1301 13.2809C10.8596 13.2809 11.4574 13.8771 11.4574 14.6048C11.4574 15.3324 10.8596 15.9287 10.1301 15.9287Z"
        fill={color}
      />
    </svg>
  )
}
