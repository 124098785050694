export type ReturnsCart = number[]
export type ReturnsCreateCartResponse = { token: string; cart: ReturnsCart }
export type ReturnsUpdateCartResponse = { cart: ReturnsCart | null }
export type ReturnsGetCartResponse = { cart: ReturnsCart | null }

export const createReturnsCart = async (
  cart: ReturnsCart,
): Promise<ReturnsCreateCartResponse> => {
  const response = await fetch('/api/returns/create-cart', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(cart),
  })

  if (!response.ok) {
    throw new Error('[createReturnsCart] response failed')
  }

  return response.json()
}

export const updateReturnsCart = async (
  cart: ReturnsCart,
  cartToken: string,
): Promise<ReturnsUpdateCartResponse> => {
  const response = await fetch(`/api/returns/create-cart?token=${cartToken}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(cart),
  })

  if (!response.ok) {
    throw new Error('[updateReturnsCart] response failed')
  }

  return response.json()
}

export const getReturnsCart = async (
  cartToken: string,
): Promise<ReturnsGetCartResponse> => {
  const response = await fetch(`/api/returns/get-cart?token=${cartToken}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  })

  if (!response.ok) {
    throw new Error('[getReturnsCart] response failed')
  }

  return response.json()
}
