import { LocaleId } from '@aether/configs'
import { groq } from 'next-sanity'
import { coalesceLocaleField } from '../helpers/coalesceLocaleField'
import { createSectionJournalHeroProjection } from '../sections/createSectionJournalHeroProjection'
import { createCategoriesPostsPreviewsProjection } from './createCategoriesPostsPreviewsProjections'
import { createEditorialRichTextProjection } from './createEditorialRichTextProjection'
import { createJournalPictogramProjection } from './createJournalPictogramProjection'
import { createMediaProjection } from './createMediaProjection'
import { coalescePortableToPlainText } from '../helpers/coalescePortableToPlain'

export const createJournalPostPageProjection = (localeId: LocaleId) => {
  const EDITORIAL_RICH_TEXT_PROJECTION =
    createEditorialRichTextProjection(localeId)
  const JOURNAL_HERO_PROJECTION = createSectionJournalHeroProjection(
    localeId,
    false,
  )
  const CATEGORY_POSTS_PREVIEW_PROJECTION =
    createCategoriesPostsPreviewsProjection(localeId)
  const PICTOGRAM_PROJECTION = createJournalPictogramProjection(localeId)
  const MEDIA_PROJECTION = createMediaProjection(localeId)

  const SEO_PROJECTION = groq`{
    "metaTitle": {${coalesceLocaleField('title', localeId)}}.title,
    ${coalescePortableToPlainText(
      'shortDescription',
      localeId,
      'metaDescription',
    )},
    "ogImage": select(defined(background.image) => background${MEDIA_PROJECTION}, null),
    "ogAltImage": select(defined(background.image) && defined(background.alt) => {
      ${coalesceLocaleField('alt', localeId)},
    }.alt, null)
  }
`

  return groq`{
        "type":_type,
        "hideHeader": false,
        "hideFooter": false,
        "headerAppearance": 'journal',
        "isHeaderStatic": true,
        "seo": ${SEO_PROJECTION},
        "pageBuilder": [
          {
             "_type": "sectionJournalHero",
             "_key": "sectionJournalHero" + _id,
             ${JOURNAL_HERO_PROJECTION}
          },
          {
             "_type": "sectionProgressIndicator",
             "_key": "sectionProgressIndicator" + _id,
             ${coalesceLocaleField('title', localeId)},
             "pictogram": pictogram -> ${PICTOGRAM_PROJECTION},
             "hideShopTheProducts": select(count(products) > 0 => false, true),
             "appearance": "journalPost",
             "shopifyIds": select(
                count(products) > 0 => products[] -> {"id": store.gid, "type": "single-product"},
                []
              ),
          },
          {
             "_type": "sectionEditorialRichText",
             "_key": "sectionEditorialRichText" + _id,
             ${coalesceLocaleField(
               'content',
               localeId,
             )}[]${EDITORIAL_RICH_TEXT_PROJECTION},
             "pageContext":"journal"
          },
          select(
            count(products) > 0 => {
               "_type": "sectionShopTheProducts",
               "_key": "sectionShopTheProducts" + _id,
               "shopifyIds": products[] -> {"id": store.gid, "type": "single-product"},
               "pageContext": "journal",
               "title": *[ !(_id in path('drafts.**')) && _type == "journalConfiguration" ] {
               ${coalesceLocaleField('shopStorySectionTitle', localeId)},
               }.shopStorySectionTitle,
             },
             {}
          ),
          {
             "_type": "sectionCategoryPostsPreview",
             "_key": "sectionCategoryPostsPreview" + _id,
             "categories": ${CATEGORY_POSTS_PREVIEW_PROJECTION},
             "pageContext": "journal"
          }
        ]
    }`
}
