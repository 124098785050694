import { coalesceLocaleField } from '../helpers/coalesceLocaleField'
import { groq } from 'next-sanity'
import { LocaleId } from '@aether/configs'
import { createRichTextProjection } from '../projections/createRichTextProjection'
import { createSanityColorProjection } from '../projections/createSanityColorProjecton'

const createTechnnincalSectionsProjection = (localeId: LocaleId) => {
  const RICH_TEXT_PROJECTION = createRichTextProjection(localeId)

  return groq`{
    ...select(
      _type == 'featuresAndFitTechSection' => {
        "type": _type,
        "id": _key
      },
      _type == 'performanceFeaturesTechSection' => {
        "type": _type,
        "id": _key
      },
      _type == 'temperatureRangeTechSection' => {
        "type": _type,
        "id": _key,
      },
      _type == 'accordionTechSection' => {
        "type": _type,
        "id": _key,
        "items": accordionItems[]{
            ${coalesceLocaleField('accordionHeader', localeId)},
            ${coalesceLocaleField(
              'accordionContent',
              localeId,
            )}[]${RICH_TEXT_PROJECTION},
        }
      },
      null
    )
  }`
}

export const createSectionTechnicalSpecificationsProjection = (
  localeId: LocaleId,
) => {
  const TECHNNICAL_SECTIONS_PROJECTION =
    createTechnnincalSectionsProjection(localeId)
  const SANITY_COLOR_PROJECTION = createSanityColorProjection()
  return groq`{
        _key,
        _type,
        ...product -> {
          "shopifyIds":[{"id": store.gid, "type": "single-product"}],
          "productId": store.gid
        },
        backgroundColor${SANITY_COLOR_PROJECTION},
        sizeGuide,
        sections[]${TECHNNICAL_SECTIONS_PROJECTION}
      }
  `
}
