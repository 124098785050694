export const getYouTubeIdFromUrl = (url?: string): string => {
  if (!url) {
    return ''
  }

  const match = url.match(
    /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/,
  )

  if (match && match[2]) {
    return match[2]
  } else {
    return url
  }
}
