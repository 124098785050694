import { ShopifyResolvedCartLine } from '@aether/shopify-sdk'
import { createItem } from './createItem'
import { GTMResetEcommerce } from './gtmResetEcommerce'

export const GTMAddToCart = (
  amount: string | undefined,
  currency: string | undefined,
  cartLine: ShopifyResolvedCartLine | null,
  orderQuantity: number | undefined,
  quantity: number,
) => {
  if (!cartLine) {
    return
  }

  GTMResetEcommerce()

  const w = window as any

  w.dataLayer = w.dataLayer || []
  w.dataLayer.push({
    event: 'add_to_cart',
    ecommerce: {
      currency,
      orderQuantity,
      value: amount && parseFloat(amount),
      items: [
        createItem({
          productTitle: cartLine.merchandise.product.title,
          currency: cartLine.merchandise.price.currencyCode,
          index: 0,
          variantTitle: cartLine.merchandise.title,
          priceAmount: cartLine.merchandise.price.amount,
          quantity,
          sku: cartLine.merchandise.sku ?? undefined,
          image: cartLine.merchandise.image?.image.src,
        }),
      ],
    },
  })
}
