import { DEFAULT_LOCALE_ID, LocaleId } from '@aether/configs'
import { groq } from 'next-sanity'
import { createRichTextProjection } from './createRichTextProjection'
import { createSectionSingleImageProjection } from '../sections/createSectionSingleImageProjection'
import { createSectionDoubleImageProjection } from '../sections/createSectionDoubleImageProjection'
import { createSectionScatteredGalleryProjection } from '../sections/createSectionScatteredGalleryProjection'
import { createSectionVideoPlayerProjection } from '../sections/createSectionVideoPlayerProjection'

export const createEditorialRichTextProjection = (
  localeId: LocaleId,
  defaultLocaleId = DEFAULT_LOCALE_ID,
) => {
  const RICH_TEXT_PROJECTION = createRichTextProjection(
    localeId,
    defaultLocaleId,
  )
  const SECTION_SINGLE_IMAGE_PROJECTION =
    createSectionSingleImageProjection(localeId)
  const SECTION_POST_DOUBLE_IMAGE_PROJECTION =
    createSectionDoubleImageProjection(localeId)
  const SECTION_SCATTERED_GALLERY_PROJECTION =
    createSectionScatteredGalleryProjection(localeId)
  const SECTION_VIDEO_PLAYER_PROJECTION =
    createSectionVideoPlayerProjection(localeId)

  return groq`{
    ...${RICH_TEXT_PROJECTION},
     _type == "sectionSingleImage" => ${SECTION_SINGLE_IMAGE_PROJECTION},
     _type == "sectionDoubleImage" => ${SECTION_POST_DOUBLE_IMAGE_PROJECTION},
     _type == "sectionScatteredGallery" => ${SECTION_SCATTERED_GALLERY_PROJECTION},
     _type == "sectionVideoPlayer" => ${SECTION_VIDEO_PLAYER_PROJECTION},
  }`
}
