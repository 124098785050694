import { formatShopifyProduct } from './formatShopifyProduct'
import { ShopifyCollectionFieldsFragment } from '../dist/generatedSchemaSdk'
import { formatShopifyImage } from './formatShopifyImage'

export const formatShopifyCollection = (
  collection: ShopifyCollectionFieldsFragment,
) => ({
  ...collection,
  image: collection.image ? formatShopifyImage(collection.image) : null,
  products: {
    ...collection.products,
    nodes: collection.products.nodes.map(formatShopifyProduct),
  },
  vipCollection: collection.vipCollection?.value === 'true',
})

export type ShopifyResolvedCollection = ReturnType<
  typeof formatShopifyCollection
>
